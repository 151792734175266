import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axiosInstance, { fetchData } from '../services/utils/axiosInstance';
import { getLocationId } from '../services/utils/getLocation';

// Función para obtener el location_id del localStorage

export const getTrialReserves = async (location_id, date) => {
  // const location_id = getLocationId();
  return fetchData(
    `/day_reserves_trial?location_id=${location_id}&date=${date}`
  );
};

export const useGetTrialReserves = (date) => {
  const location_id = getLocationId();
  return useQuery({
    queryKey: ['day_reserves_trial', date],
    queryFn: async () => getTrialReserves(location_id, date),
  });
};

export const useGetClassSlots = () => {
  const location_id = getLocationId();
  return useQuery({
    queryKey: ['classes'],
    queryFn: async () => getClassSlots(location_id),
  });
};

export const add_member_trial_class = async (data) => {
  const location_id = getLocationId();
  return fetchData(`/trial_classes?location_id=${location_id}`, 'POST', data);
};
export const delete_member_gym_class = async (id, location_id) => {
  return fetchData(`/reserves/${id}/?location_id=${location_id}`, 'DELETE');
};
export const update_member_gym_class = async (id, location_id, fields) => {
  return fetchData(
    `/reserves/${id}/?location_id=${location_id}`,
    'PATCH',
    fields
  );
};
export default axiosInstance;
