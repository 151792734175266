import React, { useEffect, useState } from 'react';
import { BiTrash } from 'react-icons/bi';
import { Tooltip, Popconfirm, Skeleton } from 'antd';
import CardRounded from '../CardRounded';
import { MdCheck, MdOutlineFrontHand } from 'react-icons/md';
import dayjs from 'dayjs';
import { useAuth } from '../../context/AuthProvider';
import { t } from 'i18next';
import { HiOutlineChatBubbleBottomCenterText } from 'react-icons/hi2';
import { SlUserFollowing, SlUserUnfollow } from 'react-icons/sl';

export default function ListCdpMembers({
  setModalMemberOpen,
  useDelete_member_gym_class,
  usePatch_member_gym_class,
  isLoadingTrials,
  reserves_trials,
}) {
  const { user } = useAuth();

  const [mappedReserve, setMappedReserves] = useState({});
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!isLoadingTrials && reserves_trials?.length > 0) {
      const compareScheduledAt = (a, b) => {
        const timeA = new Date(a.scheduled_at).getTime();
        const timeB = new Date(b.scheduled_at).getTime();
        return timeA - timeB;
      };

      // Ordenar el array por hora de manera ascendente
      reserves_trials.sort(compareScheduledAt);
      // console.log('reserves_trials',reserves_trials,user.country);
      // Agrupar las reservas por hora en un objeto
      const result = reserves_trials.reduce((acc, item) => {
        const scheduledAt = dayjs(item.scheduled_at)
          .tz(user.country.time_zone)
          .format('HH:mm');
        if (!acc[scheduledAt]) {
          acc[scheduledAt] = [];
        }
        acc[scheduledAt].push(item);
        return acc;
      }, {});

      setMappedReserves(result);
    } else {
      setMappedReserves({});
    }
  }, [isLoadingTrials, reserves_trials]);

  const confirm = async (id) => {
    // console.log(e);
    setConfirmLoading(true);
    try {
      const result = await useDelete_member_gym_class.mutate(id);
    } catch (error) {
      console.log('error', error);
    }
    setConfirmLoading(false);
    setOpen(false);
    // setConfirmLoading(true);
  };
  const cancel = (e) => {
    setOpen(false);
  };

  return (
    <CardRounded styles='max-h-[35rem] pt-2'>
      {Object.entries(mappedReserve).length == 0 ? (
        <div className='h-full m-auto flex jic p-20'>
          <h2 className='text-center m-auto'>
            {t(
              'reserve_cdp.no_reserves_fordate',
              'No hay clases de prueba para la fecha seleccionada'
            )}
          </h2>
        </div>
      ) : (
        <div className='flex flex-row mx-3 sm:mx-6 items-center mt-4 mb-3 column-mobile'>
          {/* <button className='rounded-md flex-grow bg-primaryGrey py-1 px-3 cursor-pointer text-start flex flex-row items-center gap-2 text-secondaryGrey'>
            <BsFilterLeft size={19} />
            <span>Filtrar por coach</span>
          </button> */}
          {!isLoadingTrials && (
            <div>
              <span>
                {
                  reserves_trials.filter((reserve) => !reserve.has_attended)
                    .length
                }{' '}
                {t('reserves.absent', 'Ausentes')} |{' '}
              </span>
              <span>
                {
                  reserves_trials.filter((reserve) => reserve.has_attended)
                    .length
                }{' '}
                {t('reserves.present', 'Presentes')} |{' '}
              </span>
              <span>
                {t('total_singular', 'Total')} {reserves_trials.length}
              </span>
            </div>
          )}
        </div>
      )}
      {isLoadingTrials ? (
        <Skeleton paragraph={{ rows: 15 }} active />
      ) : (
        Object.entries(mappedReserve).map(([classHour, members]) => (
          <div key={classHour} className='py-2 mx-3 sm:mx-6'>
            <div className='flex flex-row items-center gap-2'>
              <Tooltip title=''>
                {/* Modificar horario para esta clase */}
                <button>
                  <p className='text-2xl'>{classHour}</p>
                </button>
              </Tooltip>
              <p>
                {members.length}{' '}
                {members.length == 1
                  ? t('prospect', 'Prospecto')
                  : t('prospects', 'Prospectos')}
              </p>
            </div>
            {members.map((member, i) => (
              <div
                key={i}
                className=' py-4 border-b-[1px]   border-b-primaryGrey flex flex-row items-center justify-between hover:cursor-pointer last:border-none'
                onClick={() =>
                  setModalMemberOpen({
                    id: member.subscription.owner_id,
                    isProspect:
                      member.subscription.owner_type == 'Prospect'
                        ? true
                        : false,
                    // customer_type: member.subscription.owner_type  == 'Prospect' ? 'Prospect' : 'Member',
                  })
                }
              >
                <div className='sm:ml-3 flex flex-row items-center gap-3'>
                  <div>
              
                    <div className={`flex flex-row gap-1 items-center`}>
                      <Tooltip
                        title={
                          member.previous_coach_contact
                            ? t(
                                'previous_contact_coach',
                                'Contacto previo del coach realizado'
                              )
                            : t(
                                'no_previous_contact_coach',
                                'No se realizó contacto previo del coach'
                              )
                        }
                      >
                        <HiOutlineChatBubbleBottomCenterText
                          size={20}
                          color={
                            member.previous_coach_contact
                              ? '#CEF5CE'
                              : '#ED4C5C'
                          }
                        />
                      </Tooltip>
                      <h2
                        className={`text-lg ${
                          member.previous_coach_contact
                            ? 'text-lightGreen'
                            : 'text-primaryRed'
                        }`}
                      >
                        {member.subscription.owner.first_name +
                          ' ' +
                          member.subscription.owner.last_name}
                      </h2>
                    </div>
                    <p className=' text-xs text-secondaryGrey desktop'>
                      N° {member.subscription.owner_id} | {member.location_name}
                    </p>
                  </div>
                </div>
                <div>
                  <Tooltip title={t('assign_coach', 'Coach asignado')}>
                    <p className=' text-xs text-secondaryGrey opacity-60 cursor-pointer'>
                      {member.subscription.owner?.coach
                        ? member.subscription.owner.coach.first_name +
                          ' ' +
                          member.subscription.owner.coach.last_name
                        : t('reserve_cdp.no_coach', 'Sin asignar')}
                    </p>
                  </Tooltip>
                </div>
                <div className='flex flex-row gap-3'>
                  {dayjs().isAfter(dayjs(member.scheduled_at)) && (
                    <div className={`flex flex-row gap-1 items-center `}>
                      <Tooltip
                        title={
                          member.post_coach_contact
                            ? t(
                                'haspost_contact_coach',
                                'Tuvo contacto del coach después de la Clase de Prueba'
                              )
                            : 
                            dayjs().diff(dayjs(member.scheduled_at),'days') < 3 ?
                              t(
                                'no_yet_post_contact_coach',
                              'Aún no se realizó el contacto del coach después de la Clase de Prueba'
                              ): 
                            t(
                                'not_post_contact_coach',
                                'No tuvo contacto del coach después de la Clase de Prueba'
                              )
                        }
                      >
                        {member.post_coach_contact ? (
                          <SlUserFollowing
                            size={23}
                            color={
                              '#CEF5CE' 
                            }
                          />
                        ) : (
                          <SlUserUnfollow
                            size={23}
                            color={
                              dayjs().diff(dayjs(member.scheduled_at),'days') < 3 ? '#FFD500' : '#ED4C5C'
                            }
                          />
                        )}
                      </Tooltip>
                    </div>
                  )}
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      usePatch_member_gym_class.mutate(member);
                    }}
                    className={`rounded-full  ${
                      member.has_attended
                        ? 'border-darkGreen'
                        : 'border-secondaryGrey'
                    } border-[1px] p-2 cursor-pointer hover:opacity-60 ease-in-out duration-200`}
                  >
                    <Tooltip
                      title={`${
                        !member.has_attended
                          ? t('mark_present', 'Marcar presente')
                          : t('mark_absent', 'Marcar ausente')
                      }`}
                    >
                      {member.has_attended ? (
                        <MdCheck size={19} color='#1EA05A' />
                      ) : (
                        <MdOutlineFrontHand size={19} color='#CCCCCC' />
                      )}
                    </Tooltip>
                  </div>
                  <div
                    onClick={(e) => e.stopPropagation()}
                    className=' rounded-full  border-primaryRed border-[1px] p-2 cursor-pointer hover:opacity-60 ease-in-out duration-200 '
                  >
                    <Popconfirm
                      title={t(
                        'remove_test_class',
                        'Estás seguro que querés borrar esta reserva?'
                      )}
                      description={t(
                        'remove_test_class_descriptionn',
                        'Le removerás la clase de prueba a este prospecto.'
                      )}
                      onConfirm={() => confirm(member)}
                      onCancel={cancel}
                      okText={t('accept', 'Aceptar')}
                      open={open == member.id}
                      cancelText={t('cancel', 'Cancelar')}
                      okButtonProps={{
                        loading: confirmLoading,
                        className:
                          'bg-primaryRed text-white rounded-md px-3 py-1 hover:bg-primaryRed ',
                      }}
                    >
                      <BiTrash
                        onClick={() => setOpen(member.id)}
                        size={19}
                        color='#ED4C5C'
                      />
                    </Popconfirm>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))
      )}
    </CardRounded>
  );
}
