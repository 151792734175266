import { t } from 'i18next';
import React, { useState } from 'react';
import { Tooltip, Tag, Popconfirm, Popover } from 'antd';
import {
  getLocationCountry,
  getLocationId,
  getTimeZone,
} from '../../services/utils/getLocation';
import { formatPrice } from '../../services/utils/formatPrice';
import { canReturnProduct, isUserAdmin } from '../../services/can_user';
import DetailOperations from '../Desktop/DetailOperations';
import { BiTrash } from 'react-icons/bi';
import { FiCornerDownLeft } from 'react-icons/fi';
import { MdOutlineDiscount } from 'react-icons/md';
import { RiRefund2Fill } from 'react-icons/ri';
import { FaAngleDown } from 'react-icons/fa6';
import { useGlobalContext } from '../../context/GlobalState';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { formatError } from '../../services/utils/formatError';
import { deleteSale } from '../../api/sales';
import dayjs from 'dayjs';
import { useAuth } from '../../context/AuthProvider';
import { CiWarning } from 'react-icons/ci';

export default function LineOperation({
  customer_id,
  customer_type,
  openNotification,
  setModalMemberOpen,
  handleOpenReturn,
  sale,
  paymentPlatform,
}) {
  const { state } = useGlobalContext();
  const { user } = useAuth();

  const queryClient = useQueryClient();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [sale_details, setsale_details] = useState({ id: null });
  const handleSaleClick = (sale) => {
    setsale_details(
      sale_details.id === sale.id ? { id: null } : { id: sale.id }
    );
  };

  const [open, setOpen] = useState(false);
  const confirm = async (id, sale_location) => {
    setConfirmLoading(true);
    try {
      await useDeleteSale.mutate({ id, sale_location });
    } catch (error) {
      console.log('error', error);
    }
    setConfirmLoading(false);
    setOpen(false);
  };
  const cancel = (e) => {
    setOpen(false);
  };
  const useDeleteSale = useMutation({
    mutationFn: (sale) => deleteSale(sale.id, sale.sale_location),
    onSuccess: () => {
      openNotification('success');
      const queryKey = customer_type === 'Member' ? 'profile' : 'prospect';
      queryClient.invalidateQueries({ queryKey: [queryKey, customer_id] });
      queryClient.invalidateQueries({
        queryKey: ['sales', customer_id, customer_type],
      });
      setConfirmLoading(false);
    },
    onError: (error) => {
      formatError(error, openNotification);
      setConfirmLoading(false);
    },
  });
  const hasDebt = (sale) => {
    let paymentsAmount = sale.payments.reduce((acc, payment) => {
      return acc + parseFloat(payment.amount);
    }, 0);
    if (sale.full_refunded) {
      return false;
    }
    // let refunds = sale.refunds.reduce((acc, refund) => {
    //   return acc + parseFloat(refund.amount);
    // })

    if (parseFloat(sale.total) > parseFloat(paymentsAmount)) {
      return true;
    }

    return false;
  };
  return (
    <div
      className='border-b-[1px] border-b-primaryGrey py-3  mb-3  last:border-b-transparent'
      key={sale.id}
    >
      <div
        className={`flex flex-row  justify-between items-center  column-mobile tc-m ${
          customer_id && ' mt-[-14px]'
        }`}
      >
        <Tooltip title={t('sale.titleID', 'Venta ID')}>
          <span className={`${customer_id ? 'w-[5%]' : 'w-[10%]'}`}>
            {sale.id}
          </span>
        </Tooltip>
        <div
          className={`${customer_id ? 'w-[36%]' : 'w-[46%]'} item-center flex`}
        >
          <div className='items-center justify-center'>
            <div className=''>
              <Tooltip
                zIndex={customer_id ? false : 1}
                title={`${t('sold_by', 'Vendido por')} ${sale.seller_name} ${t(
                  'on',
                  'el'
                )} ${dayjs(sale.created_at)
                  .tz(
                    getLocationCountry(state.locations, sale.location_id)
                      ?.time_zone ||
                      (sale.payments?.length > 0 &&
                        sale.payments?.find((p) => p.time_zone).time_zone) ||
                      user.country.time_zone
                  )
                  .format('DD/MM/YYYY HH:mm A')} ${t('in', 'en')} ${
                  sale.location_name
                }`}
              >
                <p className='font-MessinaSansSemiBold'>
                  {sale.product?.name}{' '}
                  {customer_id && sale.is_debit == true //fast_debit
                    ? ` - (${t('recurrent', 'Cobro recurrente')})`
                    : ''}
                </p>
              </Tooltip>
              <div className='flex flex-row items-center gap-2'>
                {sale.discount_amount > 0 && (
                  <Tooltip
                    title={`${t('discount_of', 'Descuento de')} ${formatPrice(
                      sale.discount_amount,
                      sale.currency
                    )}`}
                  >
                    <div className=' bg-primaryGrey self-center h-6 rounded-sm  w-8 flex items-center justify-center hover:cursor-pointer'>
                      <MdOutlineDiscount size={14} />
                    </div>
                  </Tooltip>
                )}
                {sale.full_refunded && (
                  <Tooltip title={t('total_refund', 'Devolución completa')}>
                    <div className=' bg-primaryRed self-center h-6 rounded-sm  w-8 flex items-center justify-center hover:cursor-pointer'>
                      <RiRefund2Fill size={18} />
                    </div>
                  </Tooltip>
                )}
                {/* {hasDebt(sale) && (
                  <Tooltip
                    title={t(
                      'sales.debt',
                      'Esta venta esta en cuenta corriente'
                    )}
                  >
                    <div className=' bg-primaryRed self-center h-6 rounded-sm  w-8 flex items-center justify-center hover:cursor-pointer'>
                      <CiWarning size={18} />
                       </div>
                  </Tooltip>
                )} */}
              </div>
            </div>
            <button
              onClick={() =>
                setModalMemberOpen({
                  id: sale.customer_id,
                  isProspect: sale.customer_type == 'Member' ? false : true,
                  customer_type: sale.customer_type,
                })
              }
              disabled={customer_id}
            >
              <p className=' text-lightGreen'>
                {customer_id ? sale.seller_name : sale.customer_name}
              </p>
            </button>
          </div>
        </div>
       
        <div
          className={`flex flex-col items-center w-[23%]  ${
            customer_id ? 'lg:w-[16%]' : 'lg:w-[20%]'
          }`}
        >
          {sale.payments.map((payment, i) => (
            <Tooltip
              // zIndex={1}
              title={`${formatPrice(payment.amount, sale.currency)} en ${
                payment.payment_method_name
              } el ${dayjs(payment.created_at)
                .tz(payment.time_zone)
                .format('DD/MM/YYYY HH:mm A')}`}
              key={payment?.id ? payment?.id.toString() : i}
            >
              <Tag
                bordered={false}
                color={
                  payment.payment_method_id == 5
                    ? 'red'
                    : payment.payment_method_id == 2
                    ? 'blue'
                    : 'green'
                }
                key={payment?.id ? payment.id.toString() : i}
                className={` py-1 ${
                  sale.payments.length > 0 && 'mb-2'
                } text-ellipsis   cursor-default overflow-hidden whitespace-nowrap font-MessinaSansSemiBold w-[100%]  text-center`}
              >
                {}
                {payment.payment_method_name.toUpperCase()}
              </Tag>
            </Tooltip>
          ))}
        </div>

        <span
          className={`text-lg  whitespace-nowrap sm:whitespace-normal ${
            customer_id ? 'w-[16%]' : 'w-[16%]'
          } ml-5 flex-row flex`}
        >
          {formatPrice(sale.total, sale.currency)}
          {customer_id &&
          sale?.card_attempts.length > 0 &&
          <div className='flex flex-row items-center gap-2 ml-6'>
         { sale?.card_attempts.map((card_attempt, i) => (
            <Popover
              className='cursor-pointer'
              placement='bottom'
              content={
                <div className='flex flex-col  justify-between gap-1'>
                  <div className='flex flex-row justify-between'>
                    <p>Estado:</p>
                    <Tag 
        color={card_attempt.status == 'approved'? 'green':card_attempt.status == 'in_process' ? 'yellow' :'red'}
       >{card_attempt.status}</Tag>                  </div>
                  <div className='flex flex-row justify-between '>
                    <p className='w-[50%]'>{t(
                          'sales.operation_number',
                          'Número de operación'
                        )}:</p>
                    <p>{card_attempt.operation_reference}</p>
                  </div>
                  <div className='flex flex-row justify-between'>
                    <p>Monto:</p>
                    <p>{formatPrice(card_attempt.amount)}</p>
                  </div>
                  <div className='flex flex-row justify-between'>
                    <p>Descripción:</p>
                    <p className=''>{card_attempt.rejected_description}</p>
                  </div>
                  <div className='flex flex-row justify-between'>
       <p>Fecha:</p>
       <p>{dayjs(card_attempt.attempted_at).tz(getTimeZone()).format('DD/MM/YYYY HH:mm A')}</p>
     </div>
                  <div className='flex flex-row justify-between'>
                    <p>ID Tarjeta:</p>
                    <p>{card_attempt.credit_card_id}</p>
                  </div>
                </div>
              }
            >
              <div
                key={i}
                className={` w-3 h-3 rounded-full ${card_attempt.status == 'approved'? ' bg-primaryGreen':card_attempt.status == 'in_process' ? 'bg-primaryYellow' :'bg-primaryRed'}  `}
              ></div>
            </Popover>
          ))}
          </div>
        }
        </span>
       
        <div className='flex justify-end'>
          {canReturnProduct(state.user.roles) &&
            (isUserAdmin(state.user.roles) ||
              state?.user?.locations
                ?.map((l) => l.id)
                .includes(sale.location_id)) &&
            !sale.full_refunded && (
              <div
                onClick={() => {
                  if (sale.location_id !== getLocationId()) {
                    return openNotification(
                      'error',
                      t(
                        'sales.error_location',
                        'No puedes devolver pagos de otra sucursal. La venta fue realizada en la sucursal: '
                      ) + sale.location_name
                    );
                  }
                  handleOpenReturn(sale);
                }}
                className=' bg-primaryGrey p-1 rounded-sm    cursor-pointer text-white '
              >
                <Tooltip title='Devolver'>
                  <FiCornerDownLeft
                    color='#FFF'
                    size={19}
                    className=' ml-auto mr-auto'
                  />
                </Tooltip>{' '}
              </div>
            )}
          {sale.full_refunded && <div className='w-5'></div>}
          <div
            onClick={() => {
              handleSaleClick(sale);
            }}
            className={`bg-primaryGrey p-1 rounded-sm cursor-pointer text-white ml-2 
  ${sale_details.id === sale.id ? 'rotate-180 duration-150' : ''}
  `}
          >
            <span>
              <FaAngleDown
                color='#FFF'
                size={19}
                className=' ml-auto mr-auto'
              />
            </span>
          </div>
          {customer_id && isUserAdmin(state.user.roles) && (
            <div
              onClick={(e) => e.stopPropagation()}
              className=' rounded-full  border-primaryRed border-[1px] p-1 cursor-pointer hover:opacity-60 ease-in-out duration-200 ml-5'
            >
              <Tooltip title={t('sales.delete_sale', 'Eliminar venta')}>
                <Popconfirm
                  title={t(
                    'sales.delete_title',
                    'Estás seguro que querés borrar esta venta?'
                  )}
                  description={t(
                    'sales.delete_description',
                    'Se eliminara la venta, el pago y la subscripción asociada.'
                  )}
                  onConfirm={() => confirm(sale.id, sale.location_id)}
                  onCancel={cancel}
                  okText={t('delete', 'Eliminar')}
                  open={open == sale.id}
                  cancelText={t('cancel', 'Cancelar')}
                  okButtonProps={{
                    loading: confirmLoading,
                    className:
                      'bg-primaryRed text-white  rounded-md px-2 py-1 hover:bg-primaryRed ',
                  }}
                >
                  <BiTrash
                    size={18}
                    color='#ED4C5C'
                    onClick={() => setOpen(sale.id)}
                  />
                </Popconfirm>
              </Tooltip>
            </div>
          )}
        </div>
      </div>
      {sale_details.id === sale.id && (
        <DetailOperations
          paymentPlatform={paymentPlatform}
          sale_id={sale.id}
          card_attempts={sale.card_attempts}
        />
      )}
    </div>
  );
}
