import React, { useState } from 'react';
import { Button, Modal, Tag } from 'antd';
const { confirm } = Modal;
import { BsArrowRight } from 'react-icons/bs';
import { t } from 'i18next';
import { formatExtraInfo } from '../../../services/membersUtils';
import { useMemberCreditCards } from '../../../api/mp';
import CardRounded from '../../CardRounded';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { formatError } from '../../../services/utils/formatError';
import { updateDebitSplit } from '../../../api/debits';

const ModalDebitCard = ({ openNotification, current_split, user_id, setClose ,open,customer_id}) => {
  if(!open) return <></>

  const { data: credit_cards, isLoading } = useMemberCreditCards(user_id);
  const [selectedMethod, setselectedMethod] = useState( current_split?.credit_card?.id ? current_split?.credit_card : false)
    const queryClient = useQueryClient()
  const UseupdateDebitSplit= useMutation({
    mutationFn: (data) => updateDebitSplit(data.id,data.data),
    onSuccess: async (data) => {
      openNotification('success', 'Debito actualizado correctamente');
      queryClient.invalidateQueries({
        queryKey: ['profile', customer_id],
      });
      setClose();
    },
    onError: (error, variables) => {
      formatError(error, openNotification);
    },
  });
 const updateSplit = async () => {
  let debit = {
    id: current_split.id,
    data:{
      credit_card_id: selectedMethod.id,
      id:current_split.id,
      location_id:current_split.location_id
    }
   
  }  
  await UseupdateDebitSplit.mutateAsync(debit);

 }
  return (
    <div 
    onClick={() => {
      setClose();
    }}
    className={`${
      open ? 'fixed' : 'hidden'
    } inset-0 bg-background bg-opacity-75 backdrop-blur-[1px] flex justify-center items-center z-50`}>
       <div
        onClick={(e) => e.stopPropagation()}
        className='bg-primaryDark rounded-xl  p-7 relative sm:h-fit lg:w-fit lg:min-w-[35%] lg:h-fit lg:top-0 sm:margin-auto sm:left-0 sm:right-0 sm:top-0 sm:bottom-0'
      >
              <h2 className='text-3xl  font-MessinaSansSemiBold'>{t('modify_debit_card', 'Modificar método de pago')}</h2>
          <div className="flex flex-row items-center justify-between my-5 w-full ">
            <div className='flex-1 '>
              <p className="text-secondaryGrey opacity-80 max-w-xs break-words">
                Metodo de pago actual
              </p>
              <div className="py-2 my-2 px-2 opacity-80 bg-primaryGrey flex items-center justify-center text-secondaryGrey">
                {current_split.credit_card ? 
                  `${current_split.credit_card?.card_brand} ${formatExtraInfo(current_split.credit_card?.extra_info)?.first_six_digits}****${current_split.credit_card?.last_four}` 
                  : 'No hay metodo de pago registrado'}
              </div>
            </div>
            <BsArrowRight
              className="mx-4 self-center mt-4"
              color={'#ADFF19'}
              size={22}
            />
            <div className='flex-1 '>
              <p className="text-secondaryGrey opacity-80 max-w-xs break-all">
                Nuevo Metodo de pago
              </p>
              <div className="py-2 my-2 px-2 opacity-80 bg-primaryGrey flex items-center justify-center text-secondaryGrey">
               {selectedMethod.id != current_split.credit_card?.id ?
                `${selectedMethod?.card_brand} ${formatExtraInfo(selectedMethod?.extra_info)?.first_six_digits}****${selectedMethod?.last_four}`
                : 'No hay nuevo metodo de pago seleccionado' }
                {/* Aquí podrías agregar la lógica para mostrar las nuevas tarjetas de crédito */}
              </div>
            </div>
          </div>
          {credit_cards.length > 0 && (
          <span className=' font-MessinaSansSemiBold text-md'>Seleccioná un nuevo Método de pago</span>
        )}
          <div className='flex flex-items self-start gap-5  mt-4'>
        {credit_cards.length == 0 && (
          <CardRounded
            styles={` flex flex-col bg-primaryGrey items-center justify-center max-w-max  border-2 border-darkGreen border-dashed  py-5 px-5`}
          >
            No hay tarjetas guardadas
            <button
              // onClick={() => setopenAddCreditCard(true)}
              className='mt-2 text-darkGreen text-center underline'
            >
              Agregar nueva tarjeta
            </button>
          </CardRounded>
        )}
        
        {credit_cards?.map((cc) => (
          <CardRounded
          key={cc.id}
           onClick={() => {
            if(cc == selectedMethod){
              setselectedMethod(false)
              return
            }
            setselectedMethod(cc)
           }}
            styles={`cursor-pointer bg-primaryGrey max-w-max  border-2 ${
              cc.id == selectedMethod?.id
                ? 'border-darkGreen'
                : 'border-transparent'
            }  py-5 `}
          >
            <div className='flex flex-col  items-start gap-2 px-4'>
              <div className=''>
                {/* <p>ID {cc.id}</p> */}
                {!formatExtraInfo(cc.extra_info) ? (
                  <span>ID: {cc.id}</span>
                ):  <img
                width={50}
                height={50}
                style={{ objectFit: 'contain', minHeight: 50 }}
                //  className=' object-contain'
                src={
                  formatExtraInfo(cc.extra_info)?.payment_method?.secure_thumbnail
                }
                alt='American_express'
              />}
              </div>
              <div className=' items-center gap-1'>
                <p>
                  {cc.card_brand}{' '}
                  {formatExtraInfo(cc.extra_info)?.first_six_digits} ***{' '}
                  {cc.last_four}
                </p>
                <p>Vence {cc.expiration_date}</p>
                {cc.preferred && (
                  <Tag className='mt-1' color={cc.preferred && 'green'}>
                    {' '}
                    {cc.preferred && 'Preferida'}
                  </Tag>
                )}
              </div>
            </div>
          </CardRounded>
        ))}
      </div> 
      <div className='flex items-end self-end  justify-end gap-3 mt-4'>
      <Button danger onClick={()=>setClose()} >
        Cancelar
      </Button>
      <Button 
      loading={UseupdateDebitSplit.isLoading}
      type='primary' onClick={ async()=> await updateSplit()}>
        Aceptar
      </Button>
      </div>
      </div>
        </div>
  );  

};

export default ModalDebitCard;
