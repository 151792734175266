import { Tag ,Tooltip} from 'antd';
import React from 'react'
import imgUrl from '../../assets/images/iconios.png';
import { canCreateMember, canCreateUser } from '../../services/can_user';
import { isValidSuscription } from '../../services/membersUtils';
import AddMemberRoles from './AddMemberRoles';
import { t } from 'i18next';

export default function HeaderPersonalData({member,setmodalSale,openNotification,isProspect,customer_type}) {
  console.log('isProspect',isProspect);
  
  if(!member) return <></>
  return (
    <div className='bg-primaryDark rounded-lg p-4 personal-info-container'>
    <div className='flex items-center'>
      <img
        className='w-[100px] h-[100px] overflow-hidden rounded-full object-cover'
        onError={(e) => {
          e.target.src = imgUrl;
        }}
        src={customer_type !== 'Member' ? ``:`https://bigg-app-profile-prod.s3.amazonaws.com/new_profile_pictures/${member.id}`}
        alt='description of image'
      />
      <div className='pl-5 flex flex-col pr-5 gap-2 '>
        <h2 className=' text-2xl leading-7 font-MessinaSansSemiBold '>
          {member?.name}
        </h2>
   
        <div className='flex flex-row  gap-2 flex-wrap'>
          <span className={`text-xs p-[0.15rem] rounded-sm px-2 uppercase font-semibold ${
              customer_type == 'Lead' ? 'bg-secondaryGrey text-black' : !isProspect ? 'bg-lightGreen text-darkGreen' : 'bg-lightBlue text-primaryBlue'
              }`}>{
                customer_type == 'Lead' ? t('lead', 'Lead') :
                !isProspect
                ? t('member', 'Socio')
                : t('prospect', 'Prospecto')}
          </span>
          {member.roles?.map((role,i)=>(
          <div
          bordered={false}
          key={i}
          className='text-xs p-[0.15rem] rounded-sm px-2 uppercase font-semibold bg-[#444]'
        >
          <span className='text-white font-semibold'>
            {role.name}
          </span>
        </div>
          ))}
          </div>
      </div>
    </div>
    <div className='flex flex-row items-center mt-5 gap-1 flex-wrap'>
      {[
        { title: member.location_name },
        { title: `N°: ${member.id}` },
        // { title: ` Legacy N°: ${member.legacy_id}` },
      ]
        .concat(
          member?.subscriptions
            ?.filter((suscription) =>
              isValidSuscription(
                suscription.started_at,
                suscription.days_left,
                suscription.sessions_left,
                suscription.membership.product.parent_product_id

              )
            ) 
            .map((item) => ({
              title: item.membership.product.name,
            }))
        )
        .map((item, i) => (
          item?.title ?
          <Tag
            bordered={false}
            key={i}
            className={` 
              ${customer_type == 'Lead' ? 'bg-secondaryGrey': !isProspect ?'bg-lightGreen':'bg-lightBlue'} 
              px-2 py-1 mb-2`}
          >
            <p className='text-black font-MessinaSansSemiBold'>
              {item?.title}
            </p>
          </Tag> : ''
        ))}
    </div>


    {customer_type == 'Prospect' && isProspect  && canCreateMember() && (
      <Tooltip
        title={t(
          'action_convert_prospect',
          'Esta accion convertira el prospect en un socio'
        )}
        placement='top'
      >
        <button
          onClick={() => setmodalSale('convert')}
          className={`rounded-sm ${customer_type == 'Prospect' ? 'bg-lightBlue': customer_type == 'Member' ?'bg-lightGreen':'bg-secondaryGrey'}    w-full mt-6 mb-1 py-3`}
        >
          <span className={` font-MessinaSansBold ${customer_type == 'Prospect' ? 'text-primaryBlue': customer_type == 'Member' ?'text-darkGreen' : 'text-black'}  uppercase text-md`}>
            {t('convert_to_member', 'Convertir a socio')}
          </span>
        </button>
      </Tooltip>
    )}
    {canCreateUser() &&
      !isProspect &&
      member?.user?.roles?.length == 0 && (
        <AddMemberRoles
          member_id={member?.id}
          openNotification={openNotification}
          user_id={member?.user_id}
        />
      )}
  </div>
  )
}
