import React, { useRef, useState, useEffect, useMemo } from 'react';
import { Checkbox, notification } from 'antd';

import {
  getLocationId,
  getLocationName,
} from '../../services/utils/getLocation';
import { CardPayment, initMercadoPago } from '@mercadopago/sdk-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuth } from '../../context/AuthProvider';
import { t } from 'i18next';
import { createMemberCreditCard } from '../../api/mp';
import { formatError } from '../../services/utils/formatError';
import { updateUser } from '../../api/users';

export default function ModalCreditCard({
  member,
  setClose,
  openNotification,
  open,
  paymentPlatform,
  handleSaveMember,
  document_types,
}) {
  if (!open) return <></>;
  const { user } = useAuth();
  if (!paymentPlatform) return <></>;
  if (paymentPlatform?.length == 0 && open) {
    return (
      <div
        onClick={() => {
          setClose();
        }}
        className={`${
          open ? 'fixed' : 'hidden'
        } inset-0 bg-background bg-opacity-75 backdrop-blur-[1px] flex justify-center items-center z-50`}
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className='bg-primaryDark rounded-xl  p-7 relative sm:h-fit lg:w-fit lg:min-w-[45%] lg:h-fit lg:top-0 sm:margin-auto sm:left-0 sm:right-0 sm:top-0 sm:bottom-0'
        >
          <h2 className=' text-2xl font-MessinaSansSemiBold'>
            Ocurrio un error.
          </h2>

          <p className='text-primaryRed mt-2 text-md'>
            La sede {getLocationName()} no cuenta con un integracion habilitada
            que permita agregar un metodo de pago.
          </p>
        </div>
      </div>
    );
  }
  initMercadoPago(paymentPlatform[0].public_key, {
    locale: 'es-AR',
  });
  const [loadindBrick, setloadindBrick] = useState(true);
  const email = member.email;
  const queryClient = useQueryClient();
  const [api, contextHolder] = notification.useNotification();
  const [checked_third, setchecked_third] = useState(false);
  const useUpdateUser = useMutation({
    mutationFn: (user) => updateUser(user.id, user),
    onSuccess: (data) => {},
    onError: (error) => formatError(error, openNotification),
  });
  const useCreateCreditCard = useMutation({
    mutationFn: (card) => createMemberCreditCard(card),
    onSuccess: async (data, variables) => {
      openNotification('success', 'Tarjeta guardada con exito');
      queryClient.invalidateQueries({
        queryKey: ['credit_cards', member.user_id],
      });
      if (!checked_third) {
        if (document_types && document_types?.length > 0) {
          let updateIdentification = {
            document_number: variables.payer.identification.number,
          };
          if (
            document_types.find(
              (d) => d.name == variables.payer.identification.type
            )?.id
          ) {
            updateIdentification = {
              ...updateIdentification,
              document_type_id: document_types.find(
                (d) => d.name == variables.payer.identification.type
              )?.id,
            };
          }
          if (updateIdentification.document_type_id) {
            let updateMember = {
              id: member.user_id,
              user_id: member.user_id,
            };
            updateMember.user = {
              id: member.user_id,
              email: variables.payer.email,
              document_number: updateIdentification.document_number,
              document_type_id: updateIdentification.document_type_id,
            };
            await useUpdateUser.mutateAsync(updateMember);
          }
        }
      }
      setClose();
    },
    onError: (error, variables) => {
      let errorParse = JSON.parse(error.message);
      formatError(error, openNotification);
      // openNotification('error');
    },
  });

  const cardPaymentComponent = useMemo(
    () => (
      <CardPayment
        customization={{
          paymentMethods: {
            maxInstallments: 1,
          },
          visual: {
            hideFormTitle: true,
            texts: {
              formSubmit: 'Guardar Metodo de pago',
            },
            style: {
              customVariables: {
                inputBackgroundColor: '#444444',
                formBackgroundColor: '#222222',
                textPrimaryColor: '#FFFFFF',
                outlinePrimaryColor: 'transparent',
                fontSizeSmall: '16px',
                outlineSecondaryColor: '#CEF5CE',
                fontSizeMedium: '17px',
                inputVerticalPadding: '12px',
                formPadding: '0px',
                inputBorderWidth: '0px',
              },
            },
          },
        }}
        initialization={{
          amount: 1000,
          payer: {
            email: email,
          },
        }}
        onReady={() => setloadindBrick(false)}
        onError={(error) => console.log(error)}
        onSubmit={async (cardFormData, additionalData) => {
          console.log(
            'cardFormData, additionalData',
            cardFormData,
            additionalData
          );

          let card = {
            card_brand: cardFormData.payment_method_id,
            external_payment_token: cardFormData.token,
            user_id: member.user_id,
            payment_platform_id: paymentPlatform[0].id,
            last_four: additionalData.lastFourDigits,
            payment_method_id: paymentPlatform[0].payment_method_id,
            external_email: cardFormData.payer.email,
            preferred: false,
            payer: cardFormData.payer,
          };
          await useCreateCreditCard.mutateAsync(card);
        }}
      />
    ),
    []
  );

  return (
    <div
      onClick={() => {
        setClose();
      }}
      className={`${
        open ? 'fixed' : 'hidden'
      } inset-0 bg-background bg-opacity-75 backdrop-blur-[1px] flex justify-center items-center z-50`}
    >
      {contextHolder}
      <div
        onClick={(e) => e.stopPropagation()}
        className='bg-primaryDark rounded-xl  p-7 relative sm:h-fit lg:w-fit lg:min-w-[45%] lg:h-fit lg:top-0 sm:margin-auto sm:left-0 sm:right-0 sm:top-0 sm:bottom-0'
      >
        <div className='flex flex-col  w-full  pb-5'>
          <h1 className='text-3xl  font-MessinaSansSemiBold'>
            Cargar Metodo de pago
          </h1>
          <div className={` mt-16 flex ${!loadindBrick && 'items-end'} `}>
            <>
              {cardPaymentComponent}
              {/* {PaymentComponent} */}
            </>
            {!loadindBrick && (
              <div className='flex flex-row gap-2 absolute  top-24'>
                <Checkbox
                  checked={checked_third}
                  onChange={(e) => setchecked_third(e.target.checked)}
                />
                <p>La tarjeta es de un tercero</p>
              </div>
            )}
            {!loadindBrick && (
              <button
                onClick={() => {
                  setClose();
                }}
                className=' align-bottom  ml-3 py-2  w-[30%]'
              >
                <span className='text-md text-white font-MessinaSansBold border-b-[1px]'>
                  {t('sales.cancel', 'CANCELAR')}
                </span>
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
