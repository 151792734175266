import React, { useState, useEffect } from 'react';
import { Table, ConfigProvider } from 'antd';
import es_ES from 'antd/locale/es_ES';
import { AiOutlineSearch, AiOutlineDownload } from 'react-icons/ai';
import { exportExcel } from '../services/utils/exportExcel';
import AddItemTable from './helpers/AddItemTable';
import DrawerForm from './DrawerForm';
import FormBuilder from './helpers/FormBuilder';
import {
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
  MdOutlineModeEditOutline,
} from 'react-icons/md';
import {
  canDeleteColumn,
  deleteColumn,
} from '../services/Constants/ColumnUtils';
import { MdOutlineViewColumn } from 'react-icons/md';
import { BsChevronDown } from 'react-icons/bs';

import { useGlobalContext } from '../context/GlobalState';
import { isUserAdmin } from '../services/can_user';
import { Input, Tooltip } from 'antd';
import { t } from 'i18next';
import { formatPrice } from '../services/utils/formatPrice';
import { useMediaQuery } from 'react-responsive';
import { getLocationById, getLocationName } from '../services/utils/getLocation';

export default function TableCustom({
  data,
  title,
  handleOpenDrawer,
  setfilterTable,
  filterTable,
  originalColumns,
  searchInput,
  openDrawer,
  onSubmit,
  register,
  handleSubmit,
  setsearchInput,
  control,
  FORM,
  title_singular,
  isLoading,
  mutateLoading,
  expandedRowRender,
  hideTitle,
  multipleTables,
  mainStyles,
  canCreate,
  handleDelete,
  canUpdate,
  handleEditRow,
  dataOriginal,
  showFooterTotal,
  setColumns,
}) {
  const {state}  = useGlobalContext()

  // Función para generar la configuración del filtro para una columna
  const createFilterDropdown = (
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters
  ) => {
    return (
      <div className='p-3'>
        <Input
          placeholder={t('search_in', 'Buscar en')}
          className='!text-black'
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => confirm()}
          style={{ width: 160, marginBottom: 8, display: 'block' }}
        />
        <div className='flex flex-row items-center justify-between border-t-[1px] border-t-lightGrey pt-2'>
          <button onClick={() => clearFilters()}>Reiniciar</button>
          <button
            className='bg-lightGreen  px-2 h-6 rounded-md text-black'
            onClick={() => confirm()}
          >
            {t('accept', 'Aceptar')}
          </button>
        </div>
      </div>
    );
  };
  // Generar las columnas
  const editColumn = (record) => {
    return {
      title: '',
      key: 'action',
      width: '4%',
      render: (text, record) => (
        <button
          onClick={(e) => {
            // e.stopPropagation();
            handleEditRow(record, true);
          }}
          className='rounded-full flex justify-center border-[1px] p-2 cursor-pointer hover:opacity-60 ease-in-out duration-200'
        >
          <MdOutlineModeEditOutline className=' ' size={19} color='#ccc' />
        </button>
      ),
    };
  };

  const columns = handleDelete
    ? [
        ...originalColumns,
        {
          title: '',
          key: 'action',
          width: '4%',
          render: (text, record) =>
            deleteColumn(
              record.id,
              open,
              setOpen,
              `¿Estás seguro de eliminar este ${title_singular}?`,
              'Esta acción no se puede deshacer',
              confirmLoading,
              async () => {
                setConfirmLoading(true);
                try {
                  await handleDelete(record.group_key ? record : record.id);
                } catch (error) {}
                setConfirmLoading(false);
                setOpen(false);
              },
              title_singular
            ),
        },
      ].concat((handleEditRow && canUpdate && editColumn()) || [])
    : [...originalColumns].concat(
        (handleEditRow && canUpdate && editColumn()) || []
      );
  const isAdmin = isUserAdmin();
  const [fieldsForm, setfieldsForm] = useState([]);
  const [totalAmount, settotalAmount] = useState(0);
  const [showColumnsEdit, setshowColumnsEdit] = useState(false);
  useEffect(() => {
    if (FORM) {
      if (!isAdmin && !canUpdate) {
        setfieldsForm(FORM.map((f) => ({ ...f, disabled: true })));
      } else {
        setfieldsForm(FORM);
      }
    }
  }, [FORM]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const handleExpand = (expanded, record) => {
    if (expanded) {
      setExpandedRowKeys([record.key]);
    } else {
      setExpandedRowKeys([]);
    }
  };

  const { state: user } = useGlobalContext();
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [tableWithFilter, settableWithFilter] = useState([]);
  const isMobile = useMediaQuery({ maxWidth: 767 }); // Definir el ancho máximo para dispositivos móviles

  const tableColumns = columns
    .map((item) => {
      if (!item) return;
      const filterProps = !item.filters &&
        item.title !== '' && {
          filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
          }) =>
            createFilterDropdown(
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters
            ),
          filterIcon: (filtered) => (
            <AiOutlineSearch
              size={18}
              style={{ color: filtered ? '#1890ff' : '#fff' }}
            />
          ),
          onFilter: (value, record) => {
            return record[item.dataIndex]
              ? Array.isArray(record[item.dataIndex])
                ? record[item.dataIndex].filter((item) =>
                    item.name?.toLowerCase().includes(value.toLowerCase())
                  ).length > 0
                : record[item.dataIndex]
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase())
              : '';
          },
        };

      return {
        ...item,
        ...filterProps,
      };
    })
    .map((item) => ({
      ...item,
    }))
    .filter((c) => {
      if (c.title == '' && !canDeleteColumn(user?.user?.roles)) {
        return;
      }
      return c;
    });
  // console.log('tableColumns',tableColumns)
  const tableProps = {
    loading: isLoading,
    scroll: { x: '100%' },
    tableLayout: isMobile ?'auto': 'fixed',
  };
  const [updateSearchTotal, setupdateSearchTotal] = useState(null);
  useEffect(() => {
    if (filterTable?.length > 0) {
      setupdateSearchTotal(filterTable.length);
    }
  }, [filterTable]);

  useEffect(() => {
    if (!data || data.length == 0) return setfilterTable([]);
    if (searchInput === '' && filterTable?.length == 0 && !isLoading) {
      settotalAmount(
        data.reduce((acc, item) => {
          return acc + parseFloat(item.amount);
        }, 0)
      );
      return setfilterTable(
        data.map((data, i) => ({ ...data, key: data.key || i.toString() }))
      );
    }

    if (
      searchInput === '' &&
      !isLoading &&
      data?.length !== filterTable?.length
    ) {
      settotalAmount(
        data.reduce((acc, item) => {
          return acc + parseFloat(item.amount);
        }, 0)
      );
      return setfilterTable(
        data.map((data, i) => ({ ...data, key: data.key || i.toString() }))
      );
    }
    if (data?.length > 0 && !isLoading && searchInput.length > 0) {
      let results = data?.filter((item) => {
        // Convierte el valor de búsqueda a minúsculas y elimina espacios en blanco para hacer la búsqueda insensible a mayúsculas y espacios
        const searchValue = searchInput
          .toLowerCase()
          .replace(/\|/g, '')
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .trim();

        // Comprueba si algún campo del objeto cumple con la condición de búsqueda
        return Object.values(item).some((field) => {
          if (typeof field === 'string') {
            // Si el campo es una cadena, reemplaza los espacios en blanco y "|" con nada
            const fieldValue = field
              .toLowerCase()
              .replace(/\|/g, '')
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .trim();
            // Verifica si el campo resultante incluye el término de búsqueda
            return fieldValue.includes(searchValue);
          } else if (typeof field === 'number') {
            // Si el campo es un número, conviértelo a cadena y haz la búsqueda
            const fieldValue = field.toString().toLowerCase().trim();
            return fieldValue.includes(searchValue);
          } else if (Array.isArray(field)) {
            // Si el campo es un array, verifica si contiene objetos con un campo "name"
            return field.some((obj) => {
              const nameValue = obj?.name || obj?.payment_method_name;
              if (nameValue) {
                // Reemplaza los espacios en blanco y "|" con nada en el campo "name"
                const cleanedName = nameValue
                  .replace(/\|/g, '')
                  .toLowerCase()
                  .normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, '')
                  .trim();
                return cleanedName.includes(searchValue);
              }
              return false; // Si 'nameValue' es undefined, no lo incluye en la búsqueda
            });
          }
          // Si el campo no es una cadena, número ni un array, no lo incluye en la búsqueda
          return false;
        });
      });
      settotalAmount(
        results.reduce((acc, item) => {
          return acc + parseFloat(item.amount);
        }, 0)
      );
      setfilterTable(results);
      setupdateSearchTotal(results.length);
    }
  }, [searchInput, isLoading]);
  useEffect(() => {
    if (filterTable && filterTable.length > 0) {
      settableWithFilter(filterTable);
    }
  }, [filterTable]);

  return (
    <div
      className={`h-screen ${
        !hideTitle && 'pl-[3rem] '
      } mr-6 lg:mr-10  mt-10 ${mainStyles} `}
    >
      <div className='mb-10 flex-grow flex flex-row justify-between items-center'>
        <h2 className='text-2xl font-MessinaSansSemiBold'>
          {!hideTitle && title}
        </h2>
        {!multipleTables && canCreate && (
          <AddItemTable
            title={title_singular}
            onClick={() => handleOpenDrawer(true, null)}
          />
        )}
      </div>
      <div className='w-[100%] mt-10 '>
        <div className='w-full bg-primaryGrey rounded-t-md flex items-center px-5 h-24  flex-row relative'>
          <AiOutlineSearch className='absolute ml-2' size={20} />
          <input
            value={searchInput}
            onChange={(e) => setsearchInput(e.target.value)}
            placeholder={`${t('search_in', 'Buscar en')} ${title}`}
            className='  bg-primaryDark rounded-md py-3 pl-10 pr-5 outline-none'
          />
          <div className='flex self-center ml-auto gap-5'>
            {/* {tableColumns.filter((t) => t.hidden).length > 0 && ( 
              <button
                onClick={() => setshowColumnsEdit(!showColumnsEdit)}
                className='bg-primaryDark gap-1 h-14 w-14 flex items-center justify-center rounded-md ml-auto hover:opacity-80 ease-in-out duration-400'
              >
                <MdOutlineViewColumn size={24} />
                <BsChevronDown size={10} />
              </button>
            )} */}
            <button
              onClick={() => {
                const extractProperties = (obj) => {
                  
                  return Object.keys(obj).reduce((acc, key) => {
                    if (Array.isArray(obj[key])) {
                      obj[key].forEach((item, index) => {
                        Object.keys(item).forEach((subKey) => {
                          acc[`${key}_${index}_${subKey}`] = item[subKey];
                        });
                      });
                    } else {
                      acc[key] = obj[key];
                      acc['location_name'] = getLocationById(state.locations,obj.location_id)?.name || obj.location_id
                    }
                    return acc;
                  }, {});
                };
                if (!tableWithFilter) return;
                // check if its assingment report
                let isAssingmentReport = tableWithFilter.some(
                  (item) => item?.week_number
                );
                if (isAssingmentReport) {
                  const exportAssignments = tableWithFilter.map((item) => ({
                    location_name: item.location_name,
                    coach_name: item.coach_name,
                    member_id: item.member_id,
                    member_name: item.member_name,
                    member_period: item.member_period,
                    antiquity: item.antiquity,
                    total_uses: item.total_uses,
                    ...item.week_number.reduce((acc, week) => {
                      acc[week.week_range] = week.assigned_state;
                      return acc;
                    }, {}),
                  }));
                  return exportExcel(
                    exportAssignments,
                    title ? title : 'reporte'
                  );
                }
                // check if its debit report
                let isDebitReport = tableWithFilter.some(
                  (item) =>
                    item?.actual_state &&
                    item.price_debit &&
                    item.debit_id &&
                    item.price_debit_payment &&
                    item.product_name
                );
                if (isDebitReport) {
                  let filteredResults = dataOriginal
                    .map((item) => {
                      if (
                        tableWithFilter
                          .map((item) => item.debit_id)
                          .includes(item.debit_id)
                      ) {
                        return item;
                      }
                    })
                    .filter((item) => item !== undefined);
                  return exportExcel(
                    filteredResults,
                    title ? title : 'reporte'
                  );
                }
                const result = tableWithFilter.map(extractProperties);
                exportExcel(result, title ? title : 'reporte');
              }}
              className='bg-primaryDark h-10 w-10 flex items-center justify-center rounded-md ml-auto'
            >
              <AiOutlineDownload size={20} />
            </button>
            {showColumnsEdit && (
              <div className='absolute bg-primaryDark top-20 right-24  z-50  border-primaryGrey border-[2px] rounded-md '>
                <div className='flex items-center gap-10 border-b-[2px] border-primaryGrey px-5 py-3 pb-2'>
                  <span>Columnas</span>
                  <button className=' text-sm text-lightGreen'>
                    Reiniciar
                  </button>
                </div>
                <div className='py-2'>
                  {tableColumns
                    .filter((t) => t.dataIndex)
                    .map((item) => (
                      <div className='flex items-center gap-2 px-2 py-1'>
                        <input
                          type='checkbox'
                          checked={!item.hidden}
                          onChange={(e) => {
                            setColumns((prev) => {
                              return prev.map((col) => {
                                if (col.dataIndex === item.dataIndex) {
                                  return { ...col, hidden: !e.target.checked };
                                }
                                return col;
                              });
                            });
                          }}
                        />
                        <span className='  capitalize text-sm font-MessinaSansWebRegular'>
                          {item.title}
                        </span>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
        </div>
        {isLoading === false &&
          data.length > 0 &&
          updateSearchTotal !== null &&
          updateSearchTotal !== data.length && (
            <div className='bg-primaryDark text-white w-full pt-3 pb-2 pl-3 text-lg font-MessinaSansSemiBold'>
              <p>
                {' '}
                {updateSearchTotal == 0
                  ? t(
                      'no_results_table',
                      'No se encontraron resultados para tu busqueda'
                    )
                  : `${t('showing', 'Mostrando')} ${updateSearchTotal} ${t(
                      'results_of',
                      'resultados de'
                    )} ${data.length} (${parseFloat(
                      (updateSearchTotal / data.length) * 100
                    ).toFixed(1)}%)`}
              </p>
            </div>
          )}
        <ConfigProvider locale={es_ES}>
          <Table
            style={{ backgroundColor: '#222', width: '100%' }}
            rowClassName={
              'bg-primaryDark text-white border-b-[5px] border-b-primaryRed hover:bg-primaryGreen'
            }
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  if (open) {
                    setOpen(false);
                  }
                  handleOpenDrawer(true, record);
                }, // click row
              };
            }}
            rootClassName='bg-primaryDark text-white cursor-pointer overflow-x-scroll '
            {...tableProps}
            // sticky
            expandable={{
              expandedRowRender,
              expandedRowKeys: expandedRowKeys,
              onExpand: handleExpand,
              expandIcon: ({ expanded, onExpand, record }) =>
                expanded ? (
                  <MdKeyboardArrowUp
                    onClick={(e) => onExpand(record, e)}
                    size={30}
                  />
                ) : expandedRowRender ? (
                  <MdKeyboardArrowDown
                    onClick={(e) => onExpand(record, e)}
                    size={30}
                  />
                ) : (
                  <></>
                ),
            }}
            showSorterTooltip={{
              target: 'sorter-icon',
            }}
            columns={tableColumns}
            onChange={(pagination, filters, sorter, extra) => {
              console.log(pagination, filters, sorter, extra);
              // setfilterTable(extra.currentDataSource);
              settotalAmount(
                extra.currentDataSource.reduce((acc, item) => {
                  return acc + parseFloat(item.amount);
                }, 0)
              );
              settableWithFilter(extra.currentDataSource);
              setupdateSearchTotal(extra.currentDataSource.length);
            }}
            dataSource={filterTable ? filterTable : []}
            scroll={scroll}
            pagination={{
              position: ['bottomRight'],
              defaultPageSize: 15,
              defaultCurrent: 1,
              // total: isLoading ? 0: filterTable?.length,
              showSizeChanger: false,
              showTotal: (total, range) => (
                <span className=' text-white'>
                  {showFooterTotal
                    ? `${total} ${title} con un total de ${formatPrice(
                        totalAmount,
                        0
                      )}`
                    : `Total ${total} ${title}`}
                </span>
              ),
            }}
          />
        </ConfigProvider>
        <div className='h-14'></div>
        <DrawerForm
          title={title_singular}
          openDrawer={openDrawer}
          handleOpenDrawer={handleOpenDrawer}
        >
          <FormBuilder
            title={title_singular}
            canUpdate={canUpdate}
            record={openDrawer.record}
            control={control}
            fields={fieldsForm}
            onSubmit={onSubmit}
            handleSubmit={handleSubmit}
            register={register}
            mutateLoading={mutateLoading}
          />
        </DrawerForm>
      </div>
    </div>
  );
}
