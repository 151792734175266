

import React from 'react';
import { Select, TimePicker, Tooltip, Tag ,Space, Divider} from 'antd';
import SpinIndicador from './SpinIndicador';
import { BsChevronDown } from 'react-icons/bs';
import { Controller } from 'react-hook-form'; // Importamos los componentes necesarios
import { ConfigProvider, DatePicker } from 'antd';
import { AiOutlineClose } from 'react-icons/ai';
import es_ES from 'antd/locale/es_ES';
import { t } from 'i18next';
import dayjs from 'dayjs';
function mapOptions(options) {
  return options.map((opt) => ({
    value: opt.value,
    label: opt.label,
    disabled: opt.disabled,
  }));
}

export default function FormBuilder({
  fields,
  onSubmit,
  loadingForm,
  title,
  record,
  handleSubmit,
  register,
  control,
  mutateLoading,
  loadingSelect,
  canUpdate,
  canCreate,
}) {
  const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={'green'}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        bordered={false}
        closeIcon={<AiOutlineClose color='#111' />}
        onClose={onClose}
        style={{
          marginLeft: 5,
          marginRight: 3,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        {label}
      </Tag>
    );
  };
  const datepickerRef = React.useRef();

  const renderField = (campo) => {
    if (campo.type === 'group') {
      return (
        <div key={campo.name}>
          <label>{campo.label}</label>
          <div className='flex flex-row gap-1 mt-2'>
          {campo.subfields.map((subCampo) => renderField(subCampo))}
          </div>
        </div>
      );
    } else if (campo.type === 'select') {
      return (
        <Controller // Usamos Controller para campos controlados
          name={campo.name}
          key={campo.name}
          control={control}
          disabled={campo.disabled}
          placeholder={campo.label}
          defaultValue={undefined}
          render={({ field }) => (
            <Tooltip title={campo.label} placement='topLeft'>
              <Select
                maxTagCount={'responsive'}
                disabled={campo.disabled}
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                dropdownRender={(menu) => (
                  campo.name == 'location_ids' ?
                  <div>
                    {menu}
                    <Divider style={{ margin: '8px 0' }} />
                    <Space style={{ padding: '0 8px 4px', alignItems: 'center' }}>
                      <span
                        onClick={() => {
                          field.onChange(campo.options.map((opt) => opt.value));
                        }}
                        className='underline text-darkGreen cursor-pointer text-center'
                      >
                        Agregar todas las sedes
                      </span>
                    </Space>
                  </div>
                  : menu
                )}
                loading={loadingSelect} //no anda
                placeholder={campo.label}
                tagRender={tagRender}
                mode={campo?.mode || undefined}
                suffixIcon={<BsChevronDown color='#ADFF19' />}
                bordered={false}
                style={{
                  width: '100%',
                }}
                options={mapOptions(campo.options)}
                className='text-white bg-primaryGrey outline-none rounded-md min-h-14 py-2 flex items-center w-full mb-5'
                {...field}
              />
            </Tooltip>
          )}
        />
      );
    } else if (campo.type === 'checkbox') {
      return (
        <Tooltip title={campo.label} placement='topLeft' key={campo.name}>
          <input
            disabled={campo.disabled}
            //  value={campo.value}
            placeholder={campo.label}
            name={campo.name}
            className='text-white mr-2'
            type={campo.type}
            {...register(campo.name)} // Para campos no select, usamos register
          />
          {campo.label}
        </Tooltip>
      );
      // Código existente para otros tipos
    } else if (campo.type === 'date') {
      return (
        <Controller // Usamos Controller para campos controlados
          name={campo.name}
          key={campo.name}
          control={control}
        
          disabled={campo.disabled}
          placeholder={campo.label}
          defaultValue={undefined}
          render={({ field }) => (
          <ConfigProvider 
          theme={{
            token:{
              cellHoverBg:'red!important',
              hoverBg:'red!important',
            }
           }}
          locale={es_ES}>
              <DatePicker
                allowClear={false}
                ref={datepickerRef}
                value={ field.value && dayjs(field.value)}
                locale={es_ES}
                placeholder={campo.label}
                disabledDate={campo.disabledDate}
                onChange={(date, dateString) => {
                  datepickerRef.current.blur();
                  field.onChange(dateString);
                }}
                defaultValue={null}
                // format={'YYYY-MM-DD'}
                className='shadow-none text-white bg-primaryGrey outline-none rounded-md h-12 flex items-center w-full mb-5 border-none hover:bg-primaryGrey focus:bg-primaryGrey focus-within:bg-primaryGrey'
              />
            </ConfigProvider>
         
          )}
          
        />
      );
    } else if (campo.type === 'TimePicker') {
      return (
        <Controller // Usamos Controller para campos controlados
          name={campo.name}
          control={control}
          key={campo.name}
          disabled={campo.disabled}
          placeholder={campo.label}
          render={({ field }) => (
            <TimePicker.RangePicker
              locale={es_ES}
              format={'HH:mm'}
              placeholder={[
                t('date_init', 'Hora Inicio'),
                t('date_end', 'Hora Fin'),
              ]}
              className='text-white bg-primaryGrey outline-none rounded-md h-12 flex items-center w-full mb-5 border-none hover:bg-primaryGrey focus:bg-primaryGrey focus-within:bg-primaryGrey'
              {...field}
              name={campo.name}
            />
          )}
        />
      );
    }  
    // if (campo.type === 'number') {
    //   return (
    //     <Controller
    //     name={campo.name}
    //     control={control}
    //     render={({ field: { onChange, onBlur, value, ref } }) => (
    //       <InputNumber
    //       changeHandler={onChange}
    //         disabled={campo.disabled}
    //         placeholder={campo.label}
    //         defaultValue={undefined}

    //         value={value}
    //         onChange={(val) => {
    //           console.log('onChange',val);
    //           // Eliminar puntos existentes antes de formatear
    //           let cleanValue = val ? val.replace(/\./g, '') : '';
    //           let [integerPart, decimalPart] = cleanValue.split('.');
    //           integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    //           const formattedValue = decimalPart ? `${integerPart}.${decimalPart}` : `${integerPart}`;
    //           onChange(formattedValue);
    //         }}
    //         // onBlur={onBlur}
    //         name={campo.name}
    //         parser={(value) => value?.replace(/\.\s?|(,*)/g, '')}
    //         formatter={(value) => {
    //           if (!value) return '';
    //           let cleanValue = value.replace(/\./g, '');
    //           let [integerPart, decimalPart] = cleanValue.split('.');
    //           integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    //           return decimalPart ? `${integerPart}.${decimalPart}` : `${integerPart}`;
    //         }}
    //         className={`${
    //           campo.value && 'text-darkGreen'
    //         } appearance-none bg-primaryGrey outline-none rounded-md h-12 w-full mb-5 px-2 border-none items-center justify-center pt-2`}
    //         ref={ref}
    //         step={campo.step || 1}
    //         {...register(campo.name)} // Para campos no select, usamos register
    //       />
    //     )}
    //   />
    //   );

    // }
    
    
    else {
      return (
        <Tooltip title={campo.label} placement='topLeft' key={campo.name}>
          <input
            autoComplete='off'
            disabled={campo.disabled}
            placeholder={campo.label} 
             required={campo.type == 'email'}

            name={campo.name}
            className={`${
              campo.value && 'text-darkGreen'
            } appearance-none   bg-primaryGrey outline-none rounded-md px-5 h-12 w-full mb-5`}
            type={campo.type}
            // type=''
            step={campo.step || 1}
            {...register(campo.name)} // Para campos no select, usamos register
          />
        </Tooltip>
      );
    }
  };

  return (
    <form
      autoComplete='off'
      onSubmit={handleSubmit(onSubmit)}
      className='flex flex-col'
    >
      {fields.map((campo) => (
        <div key={campo.name}>
          <label>{renderField(campo)}</label>
        </div>
      ))}
      {((record && canUpdate) || (!record && canUpdate)) && (
        <button
          disabled={mutateLoading}
          className='uppercase flex flex-row items-center justify-center gap-6 !text-black font-MessinaSansBold text-xl bg-lightGreen rounded-md px-5 h-14 w-full mt-5'
          type='submit'
        >
          {record ? t('update', 'Actualizar') : t('new', 'Nuevo')} {title}
          {mutateLoading && <SpinIndicador />}
        </button>
      )}
    </form>
  );
}


// import React from 'react';
// import { Select, TimePicker, Tooltip, Tag } from 'antd';
// import SpinIndicador from './SpinIndicador';
// import { BsChevronDown } from 'react-icons/bs';
// import { Controller } from 'react-hook-form'; // Importamos los componentes necesarios
// import { ConfigProvider, DatePicker } from 'antd';
// import { AiOutlineClose } from 'react-icons/ai';
// import es_ES from 'antd/locale/es_ES';
// import { t } from 'i18next';
// function mapOptions(options) {
//   return options.map((opt) => ({
//     value: opt.value,
//     label: opt.label,
//     disabled: opt.disabled,
//   }));
// }

// export default function FormBuilder({
//   fields,
//   onSubmit,
//   loadingForm,
//   title,
//   record,
//   handleSubmit,
//   register,
//   control,
//   mutateLoading,
//   loadingSelect,
//   canUpdate,
//   canCreate,
// }) {
//   const tagRender = (props) => {
//     const { label, value, closable, onClose } = props;
//     const onPreventMouseDown = (event) => {
//       event.preventDefault();
//       event.stopPropagation();
//     };
//     return (
//       <Tag
//         color={'green'}
//         onMouseDown={onPreventMouseDown}
//         closable={closable}
//         bordered={false}
//         closeIcon={<AiOutlineClose color='#111' />}
//         onClose={onClose}
//         style={{
//           marginLeft: 5,
//           marginRight: 3,
//           display: 'flex',
//           flexDirection: 'row',
//           alignItems: 'center',
//         }}
//       >
//         {label}
//       </Tag>
//     );
//   };
//   const datepickerRef = React.useRef();
//   return (
//     <form
//       autoComplete='off'
//       onSubmit={handleSubmit(onSubmit)}
//       className='flex flex-col'
//     >
//       {fields.map((campo) => (
//         <div key={campo.name}>
//           <label>
//             {campo.type === 'select' ? (
//               <Controller // Usamos Controller para campos controlados
//                 name={campo.name}
//                 control={control}
//                 disabled={campo.disabled}
//                 placeholder={campo.label}
//                 defaultValue={undefined}
//                 render={({ field }) => (
//                   <Tooltip title={campo.label} placement='topLeft'>
//                     <Select
//                       maxTagCount={'responsive'}
//                       disabled={campo.disabled}
//                       showSearch
//                       filterOption={(input, option) =>
//                         (option?.label ?? '')
//                           .toLowerCase()
//                           .includes(input.toLowerCase())
//                       }
                 
//                       loading={loadingSelect} //no anda
//                       placeholder={campo.label}
//                       tagRender={tagRender}
//                       mode={campo?.mode || undefined}
//                       suffixIcon={<BsChevronDown color='#ADFF19' />}
//                       bordered={false}
//                       style={{
//                         width: '100%',
//                       }}
//                       options={mapOptions(campo.options)}
//                       className='text-white bg-primaryGrey outline-none rounded-md min-h-14 py-2 flex items-center w-full mb-5'
//                       {...field}
//                     />
//                   </Tooltip>
//                 )}
//               />
//             ) : campo.type == 'checkbox' ? (
//               <Tooltip title={campo.label} placement='topLeft'>
//                 <input
//                   disabled={campo.disabled}
//                   //  value={campo.value}
//                   placeholder={campo.label}
//                   name={campo.name}
//                   className='text-white mr-2'
//                   type={campo.type}
//                   {...register(campo.name)} // Para campos no select, usamos register
//                 />
//                 {campo.label}
//               </Tooltip>
//             ) : campo.type == 'date' ? (
//               <Controller // Usamos Controller para campos controlados
//                 name={campo.name}
//                 control={control}
//                 disabled={campo.disabled}
//                 placeholder={campo.label}
//                 defaultValue={undefined}
//                 render={({ field }) => (
//                   <ConfigProvider locale={es_ES}>
//                     <DatePicker
//                       allowClear={false}
//                       ref={datepickerRef}
//                       placeholder={campo.label}
//                       // defaultPickerValue={today} //using dayjs
//                       onChange={(date, dateString) => {
//                         datepickerRef.current.blur();
//                         field.onChange(dateString);
//                       }}
//                       defaultValue={null}
//                       showToday
//                       // format={'YYYY-MM-DD'}
//                       className='shadow-none text-white bg-primaryGrey outline-none rounded-md h-12 flex items-center w-full mb-5 border-none'
//                     />
//                   </ConfigProvider>
//                 )}
//               />
//             ) : campo.type == 'TimePicker' ? (
//               <Controller // Usamos Controller para campos controlados
//                 name={campo.name}
//                 control={control}
//                 disabled={campo.disabled}
//                 placeholder={campo.label}
//                 // defaultValue={undefined}
//                 render={({ field }) => (
//                   <TimePicker.RangePicker
//                     locale={es_ES}
//                     format={'HH:mm'}
//                     placeholder={[t('date_init','Hora Inicio'), t('date_end','Hora Fin')]}
//                     className='text-white bg-primaryGrey outline-none rounded-md h-12 flex items-center w-full mb-5 border-none'
//                     {...field}
//                     name={campo.name}
//                   />
//                 )}
//               />
//             ) : (
//               <Tooltip title={campo.label} placement='topLeft'>
//                 <input
//                   autoComplete='off'
//                   disabled={campo.disabled}
//                   placeholder={campo.label}
//                   name={campo.name}
//                   required={campo.type == 'email'}
//                   className={`${
//                     campo.value && 'text-darkGreen'
//                   } appearance-none   bg-primaryGrey outline-none rounded-md px-5 h-12 w-full mb-5`}
//                   type={campo.type}
//                   // type=''
//                   step={campo.step || 1}
//                   {...register(campo.name)} // Para campos no select, usamos register
//                 />
//               </Tooltip>
//             )}
//           </label>
//         </div>
//       ))}
//       {((record && canUpdate) || (!record && canUpdate)) && (
//         <button
//           disabled={mutateLoading}
//           className='uppercase flex flex-row items-center justify-center gap-6 !text-black font-MessinaSansBold text-xl bg-lightGreen rounded-md px-5 h-14 w-full mt-5'
//           type='submit'
//         >
//           {record ? t('update','Actualizar') : t('new','Nuevo')} {title}
//           {mutateLoading && <SpinIndicador />}
//         </button>
//       )}
//     </form>
//   );
// }
