import React from 'react';
import { AiOutlineBell } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { useGetUncontactedProspects } from '../../api/prospects';
import SpinIndicador from '../helpers/SpinIndicador';
import { t } from 'i18next';

export default function ProspectsUncontacted() {
  const { data, isLoading } = useGetUncontactedProspects();
  return (
    <div
      className={`consultas-container column-mobile bg-primaryDark ${
        data?.count > 0 || isLoading ? 'border-primaryRed' : 'border-primaryGreen opacity-90'
      } border-2 rounded-md w-fit  flex items-center min-w-[25%] ml-auto`}
    >
      <div
        className={`p-3 ${
          data?.count > 0|| isLoading ? 'text-primaryRed' : 'text-primaryGreen'
        } font-MessinaSansSemiBold flex  items-center justify-center gap-3 px-5 text-lg`}
      >
        <AiOutlineBell size={24} />
        <div className='text-center'>
          {!isLoading & (data?.count > 0) ? (
            // `Hay ${data.count} consultas sin contactar`
            t('header.prospects_uncontacted',{count:data.count})
            // t()
          ) : data?.count == 0 ? (
             t('header.no_prospects','No hay consultas sin contactar')
          ) : (
            <div className='flex flex-row item-center gap-2'>{t('header.loading_prospects','Cargando consultas')}...</div>
          )}
        </div>
      </div>
      <Link
      
        to={'prospects'}
        className={`p-3 ${
          data?.count > 0 || isLoading ? 'bg-primaryRed' : 'bg-primaryGreen '
        } h-full pr-5 pl-5 text-black text-center  font-MessinaSansBold ml-auto hover:opacity-75 duration-300 ease-in-out`}
      >
      {  isLoading? <SpinIndicador color={`fill-primaryRed`} /> :`  ${t('header.prospect_resolve','RESOLVER')}`}
      </Link>
    </div>
  );
}
