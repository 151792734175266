import { Select } from 'antd';
import React,{useState} from 'react';
import { AiOutlineUser } from 'react-icons/ai';
import { t } from 'i18next';
import { search_members } from '../../api/search';

export default function SelectMember({ setValue, value, width }) {
  const selectRef = React.useRef();
  const [data, setData] = useState([]);

  const fetch = async (value, callback) => {
    const result = await search_members(value);
    const data = result.results.map((item) => ({
      value: item.id,
      user_id:item.user_id,
      email: item.email,
      text: item.first_name + ' ' + item.last_name,
      label: item.first_name + ' ' + item.last_name + ' | ' + 'N°' + item.id + ' | ' + item.location_name,
    }));
    callback(data);
  };
  const handleSearch = (newValue) => {
    fetch(newValue, setData);
  };
  return (
    <Select
      ref={selectRef}
      showSearch
      placeholder={
        <div className='flex flex-row items-center gap-2 opacity-70'>
          <AiOutlineUser />
          {t('search_members', 'Buscar socio')}...
        </div>
      }
      allowClear
      bordered={false}
      placement='bottomLeft'
      value={value}
      onSelect={(newValue) => {
        if (newValue == value) {
          setValue(undefined);
        }
      }}
      notFoundContent={ () => <div className='text-white  z-50 pb-50 pt-20 bg-black'>No data found</div>}
      filterOption={(input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
      }
      onSearch={handleSearch}
      onChange={(newValue,option) => {
        if(!option) return setValue(undefined)
        // console.log('option',option);
        setValue(newValue,option.user_id,option.email);
        selectRef?.current.blur();
      }}
      className=' bg-primaryGrey text-white outline-none  border-none  py-1  rounded-sm  '
      style={{
        width: width ? width : 180,
      }}
      options={(data || []).map((d) => ({
        value: d.value,
        label: d.label,
        user_id:d.user_id,
        email:d.email
      }))}
    />
  );
}
