import { Tag, Tooltip } from 'antd';
import {
  createdAtColumn,
  formatDateColumn,
  generateColumn,
  IDCOLUMN,
  sorterText,
} from './ColumnUtils';
import dayjs from 'dayjs';
import { compareByCreatedAt } from '../utils/compareByDate';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { CheckboxRenderer } from './LocationColumns';
import { formatSuscriptionDaysLeft, isValidSuscription } from '../membersUtils';
import { useCRMOptions } from '../utils/CRM_OPTIONS';
import { useAuth } from '../../context/AuthProvider';
export const USEUsersColumns = () =>{
  const { t, ready } = useTranslation();
if (!ready) return [];
 return  [
  IDCOLUMN,
  generateColumn(
    t('first_name', 'Nombre'),
    'first_name',
    '15%',
    false,
    sorterText('first_name')
  ),
  generateColumn(
    t('last_name', 'Apellido'),
    'last_name',
    '15%',
    false,
    sorterText('last_name')
  ),
  generateColumn('email', 'email', '20%'),
  generateColumn( t('roles', 'Roles'), 'roles', '15%', (tags) => (
    <>
      {tags.map((tag, i) => {
        let color = tag.length > 3 ? 'geekblue' : 'green';
        if (tag.name == 'Sales') {
          color = 'volcano';
        } else if (tag.name == 'Owner') {
          color = 'geekblue';
        }
        return (
          <Tooltip title={tag.description} key={i}>
            <Tag
              className={`${tags.length > 1 && 'mt-2'}`}
              color={color}
              bordered={false}
              key={i}
            >
              {tag.name.toUpperCase()}
            </Tag>
          </Tooltip>
        );
      })}
    </>
  )),
  generateColumn(t('locations', 'Sedes'), 'locations', '25%', (tags) => (
    <>
      {tags.map((tag, i) => {
        let color = tag.length > 3 ? 'geekblue' : 'green';
     
        if (i <= 10) {
          return (
            <Tooltip title={tag.description} key={i}>
              <Tag
                className={`${tags.length > 1 && 'mt-2'}`}
                color={color}
                bordered={false}
                key={i}
              >
                {tag.name.toUpperCase()}
              </Tag>
            </Tooltip>
          );
        } else if (i == 12) {
          return (
            <div className='flex flex-row items-end'>
              <Tooltip title={tag.description} key={i}>
                <Tag
                  className={`${tags.length > 1 && 'mt-2'}`}
                  color={color}
                  bordered={false}
                  key={i}
                >
                  {tag.name.toUpperCase()}
                </Tag>
              </Tooltip>
              <p>+ {tags.length - 12}</p>
            </div>
          );
        } else {
          return null;
        }
      })}
    </>
  )),
  createdAtColumn,
];
}
export const USECoachColumns = () =>{
  const { t, ready } = useTranslation();
if (!ready) return [];
 return  [
  IDCOLUMN,
  generateColumn(t('first_name', 'Nombre'), 'first_name', '15%'),
  generateColumn(t('last_name', 'Apellido'), 'last_name', '15%'),
  generateColumn('email', 'email', '20%'),
  generateColumn(t('locations', 'Sedes'), 'locations', '20%', 
  (tags) => (
  <>
  {tags.map((tag, i) => {
    let color = tag.length > 3 ? 'geekblue' : 'green';
    // if (tag.name == 'Sales') {
    //   color = 'volcano';
    // } else if (tag.name == 'Owner') {
    //   color = 'geekblue';
    // }
    if (i <= 18) {
      return (
        <Tooltip title={tag.description} key={i}>
          <Tag
            className={`${tags.length > 1 && 'mt-2'}`}
            color={color}
            bordered={false}
            key={i}
          >
            {tag.name.toUpperCase()}
          </Tag>
        </Tooltip>
      );
    } else if (i == 20) {
      return (
        <div className='flex flex-row items-end'>
          <Tooltip title={tag.description} key={i}>
            <Tag
              className={`${tags.length > 1 && 'mt-2'}`}
              color={color}
              bordered={false}
              key={i}
            >
              {tag.name.toUpperCase()}
            </Tag>
          </Tooltip>
          <p>+ {tags.length - 20}</p>
        </div>
      );
    } else {
      return null;
    }
  })}
</>)),
  generateColumn('Activo', 'active', '7%', CheckboxRenderer, {
    sorter: (a, b) => a.active - b.active,
    filters: [
      {
        text: 'Activo',
        value: true,
      },
      {
        text: 'Inactivo',
        value: false,
      },
    ],
    onFilter: (value, record) => record.active === value,
  }),
  createdAtColumn,
];}
export const USEMemberColumns = () =>{
  const { t, ready } = useTranslation();
if (!ready) return [];
 return  [
  IDCOLUMN,
  generateColumn(
    t('first_name', 'Nombre'),
    'first_name',
    '12%',
    false,
    sorterText('first_name')
  ),
  generateColumn(
    t('last_name', 'Apellido'),
    'last_name',
    '12%',
    false,
    sorterText('last_name')
  ),
  generateColumn('email', 'email', '15%'),
  generateColumn(
    t('product', 'Producto'),
    'subscriptions',
    '23%',
    (tags) => (
      <div className=' flex flex-wrap '>
        {tags.map((tag, i) => {

          let color = tag.length > 3 ? 'geekblue' : 'green';
          return (
            <Tooltip
              title={
                <div>
                  {t('product.days_left','Dias Restantes')}: {tag.days_left}
                  <br />
                  {t('product.sessions_left','Sesiones restantes')}: {tag.sessions_left}
                </div>
              }
              key={i}
            >
              <Tag
                className={`${tags.length > 1 && 'mt-2'} py-1`}
                color={color}
                bordered={false}
                key={i}
              >
                {tag.product_name}
              </Tag>
            </Tooltip>
          );
        })}
      </div>
    ),
    false,
  ),
  generateColumn(
    t('debit_automatic_singular','Debito Automatico'),
    'debits',
    '20%',
    (tags) => (
      <div className=' flex flex-wrap '>
        {tags.map((tag, i) => {
          let color = tag.length > 3 ? 'green' : 'green';
          return (
            <Tooltip
              title={
                <div>
                  {t('start', 'Inicio')}: {dayjs(tag.start_at).format('DD/MM/YYYY')}
                  {tag.end_at && `${ <br /> }Fin: ${dayjs(tag.end_at).format('DD/MM/YYYY')}`}
                  <br />
                </div>
              }
              key={i}
            >
              <Tag
                className={`${tags.length > 1 && 'mt-2'} py-1`}
                color={ tag.actual_state =='active'?  'success' : tag.actual_state == 'new' ? 'yellow': 'error'}
                bordered={false}
                key={i}
              >
                {tag.product_name}
              </Tag>
            </Tooltip>
          );
        })}
      </div>
    ), {},
    
  ),
  generateColumn(t('assign_coach','Coach asignado'), 'coach_name', '10%', false, sorterText('coach_name')),
  generateColumn(t('state_singular', 'Estado'), 'member_type', '10%', (text)=>(
<Tooltip  title={text == "High Risk" ?  t('no_training_in_30_days','No entrena hace 30 dias'): text == 'Risk'? t('trained_5_times','Entreno 5 veces o menos') : text == 'Good' ? t('trained_5_8','Entreno entre 5 y 8 veces'):t('trained_more_8','Entreno mas de 8 veces')} >
  <span>{text == "High Risk" ? t('high_risk','Riesgo Alto'): text == 'Risk'?t('risk','Riesgo') : text == 'Good' ? t('good','Bueno'):t('very_good','Muy bueno')}</span>
</Tooltip>

  ), {
    ...sorterText('member_type'),
    filters:[
      {
        text: t('high_risk','Riesgo Alto'),value:'High Risk',
      },
      {
        text:t('risk','Riesgo'),value:'Risk',
      },
      {
        text:t('good','Bueno'),value:'Good',
      },
      {
        text:t('very_good','Muy bueno'),value:'Very Good',
      }
    ],
    onFilter: (value, record) => record.member_type == value,
  }),
 
  // createdAtColumn,
]
}
export const USEPROSPECTColumns = () =>{
  const { t, ready } = useTranslation();
if (!ready) return [];
const CRM_OPTIONS = useCRMOptions();
const {user} = useAuth()

 if(!user) return [];
const generateCrmColumns = () => {
  return CRM_OPTIONS.filter(
    (option) =>
      option.title !== t('crm.others', 'Otros') &&
      option.title !== t('sales.title_singular', 'Venta') &&
      option.action_type_id !== -1
  ).concat({title: t('no_contact','Sin Contacto'), action_type_id: false}) .map((type) => ({
    text:type.title,
    value:type.action_type_id ,
  })
  );
}
 return  [
  generateColumn('ID', 'id', '4%', (t,r)=><span className={`${r.discharge_reason_name && 'text-primaryRed'}`}>{t}</span>, {
    sorter: {
      compare: (a, b) => a.id - b.id ,
      defaultSortOrder: 'descend',

    },
  }),
  generateColumn(t('first_name', 'Nombre'), 'first_name', '6%', (t,r)=><span className={`${r.discharge_reason_name && 'text-primaryRed'}`}>{t}</span>, sorterText('first_name')),
  generateColumn(t('last_name', 'Apellido'), 'last_name', '6%',  (t,r)=><span  className={`${r.discharge_reason_name && 'text-primaryRed'}`}>{t}</span>, sorterText('last_name')),
  generateColumn(
    t('cdp.title','Clase de prueba'),
    '',
    '7%',
    (text, record) =>
      record.reserves.length == 0 ? (
        <span className={`${record.discharge_reason_name && 'text-primaryRed'}`}>{t('withour_reserves','Sin Reserva')}</span>
      ) : (
        <Tooltip
     
          title={t('reserve_at','Reservo el') + dayjs(record.reserves[0].scheduled_at).format(
            'LLLL A'
          ) + t('at',' en ') + record.reserves[0].location_name}
        >
          <span className={`${record.discharge_reason_name && 'text-primaryRed'}`}>
            {record.reserves[0].has_attended ? t('reserves.present_singular', 'Presente') : t('reserves.absent_singular','Ausente')}
          </span>
        </Tooltip>
      ),
    {
     filters:[
      {
        text: t('withour_reserves','Sin Reserva'),
        value: 'no_reserve',
      },
      {
        text: t('reserves.present_singular', 'Presente'),
        value: 'show',
      },
      {
        text: t('reserves.absent_singular','Ausente'),
        value: 'no_show',
      },
     ],
     onFilter: (value, record) => {
      if(value == 'no_reserve'){
        return record.reserves.length == 0
      }else if(value == 'show'){
        return record.reserves.length > 0 && record.reserves[0].has_attended
      }else if(value == 'no_show'){
        return record.reserves.length > 0 && !record.reserves[0].has_attended
      }
     }
    }
  ),
  generateColumn(t('crm.first_contact','Primer contacto'), 'first_crm', '6%', (text, record) => (
    <span className={`${record.discharge_reason_name && 'text-primaryRed'}`}>
      {!record.first_crm
        ?  t('no_contact','Sin Contacto')
        : 
        <Tooltip title={ record.first_crm?.action_type_name + ': '+ record.first_crm?.message}>
        {formatDateColumn(record.first_crm.created_at)}
        </Tooltip>
        }
    </span>
  ),{
   
    filters: generateCrmColumns(),
    onFilter: (value, record) =>  value  ?  record.first_crm.action_type_id == value  : !record.first_crm,
  }),
  // generateColumn(t('type', 'Tipo'), 'first_crm', '5%', (text, record) => (
  //   <Tooltip title={record.first_crm?.message}>
  //     <span className={`${record.discharge_reason_name && 'text-primaryRed'}`}>{record?.first_crm?.action_type_name}</span>
  //   </Tooltip>
  // ),sorterText('first_crm')),
  generateColumn(t('last_contact','Ultimo contacto'), '', '6%', (text, record) => (
    <span className={`${record.discharge_reason_name && 'text-primaryRed'}`}>
      {!record.last_crm
        ? t('no_contact','Sin Contacto')
        :  <Tooltip title={ record.last_crm?.action_type_name + ': '+ record.last_crm?.message}>
        {formatDateColumn(record.last_crm.created_at)}
        </Tooltip>}
    </span>
  ),{
   
    filters: generateCrmColumns(),
    onFilter: (value, record) =>  value  ?  record.last_crm.action_type_id == value  : !record.last_crm,
  }),
  // generateColumn(t('type', 'Tipo'), 'last_crm', '5%', (text, record) => (
  //   <Tooltip title={record.last_crm?.message}>
  //     <span className={`${record.discharge_reason_name && 'text-primaryRed'}`}>{record?.last_crm?.action_type_name}</span>
  //   </Tooltip>
  // ),sorterText('last_crm')),

  generateColumn(t('convertion','Conversión'), 'converted_date', '7%', (text, record) => (
    <span className={`${record.discharge_reason_name && 'text-primaryRed'}`}> 
      {record.converted_date && ![33,32,207].includes(record.converted_subscription?.membership?.product.parent_product_id) ? formatDateColumn(record.converted_date) : ''}
    </span>
  ),{
    sorter: (a,b)=> {
      {
        const dateA = dayjs(a.converted_date);
        const dateB = dayjs(b.converted_date);
      
        if (dateA.isBefore(dateB)) {
          return 1;
        }
        if (dateA.isAfter(dateB)) {
          return -1;
        }
        return 0;
      };
    },
    filters: [
      {
        text:  t('converted','Convertido'),
        value: true,
      },
      {
        text:  t('no_converted','No Convertido'),
        value: false,
      },
    ],
    onFilter: (value, record) =>  value == true ?  record.converted_date && ![33,32,207].includes(record.converted_subscription?.membership?.product.parent_product_id)  : !record.converted_date ,
  }),
  generateColumn(t('prospect.campaing', 'Campaña'), 'campaign_name', '6%',(t,r)=><span className={`${r.discharge_reason_name && 'text-primaryRed'}`}>{t}</span>,sorterText('campaign_name')),
  generateColumn(t('prospect.source', 'Fuente'), 'channel_name', '6%',(t,r)=><span className={`${r.discharge_reason_name && 'text-primaryRed'}`}>{t}</span>,sorterText('channel_name')),
  generateColumn(t('prospect.how_did_you_know', 'Como conocio'), 'approach_type_name', '6%',(t,r)=><span className={`${r.discharge_reason_name && 'text-primaryRed'}`}>{t}</span>,sorterText('approach_type_name')),
  generateColumn('RNC', 'discharge_reason_name', '5%',(t,r)=><span className={`${r.discharge_reason_name && 'text-primaryRed'}`}>{t}</span>,sorterText('discharge_reason_name')),
  generateColumn('TIEMPO RESPUESTA', '', '5%',(text,record)=> 
  record.first_crm &&
  <span 
  className={`${record.discharge_reason_name && 'text-primaryRed'}`}>
    {(dayjs(record.first_crm.sent_at).tz(user.country.time_zone).diff(dayjs(record.created_at).tz(user.country.time_zone),'hours') > 0 
   ? dayjs(record.first_crm.sent_at).tz(user.country.time_zone).diff(dayjs(record.created_at).tz(user.country.time_zone),'hours') +'h' : '') +' '+
  dayjs(record.first_crm.sent_at).tz(user.country.time_zone).diff(dayjs(record.created_at).tz(user.country.time_zone),'minutes')%60+'m'}</span> ,{
    sorter: (a,b)=> {
      {
        const dateA = dayjs(a?.first_crm?.sent_at).diff(a.created_at,'minutes');
        const dateB = dayjs(b?.first_crm?.sent_at).diff(b.created_at,'minutes');
      
        if (dateA > dateB) {
          return 1;
        }
        if (dateA < dateB) {
          return -1;
        }
        return 0;
      }
    
    }}),
  generateColumn(
    t('created','Creado'),
    'created_at',
    '6%',
     (t,r)=><span className={`${r.discharge_reason_name && 'text-primaryRed'}`}>{dayjs(t).tz(user.country.time_zone).format('DD/MM/YYYY HH:mm A')}</span>,
    {
      sorter: (a,b)=>compareByCreatedAt(a,b),
      defaultSortOrder: 'ascend',

    }
  ),
  // generateColumn(
  //   t('phone', 'Telefono'),
  //   'phone',
  //   '5%',
  //   (t,record)=><span>{record.user.phone}</span>,
  //   false,false,true
  // ),
  // generateColumn(
  //   'Email',
  //   'Email',
  //   '5%',
  //   (t,record)=><span>{record.user.email}</span>,
  //   false,false,true
  // ),
  // generateColumn(
  //   t('alerts.user_assinged', 'Usuario asignado'),
  //   'owner_user_name',
  //   '5%',
  //   false,
  //   sorterText('owner_user_name'),false,true
  // ),
  // generateColumn(
  //   'Mensaje primer contacto',
  //   // t('alerts.user_assinged', 'Usuario asignado'),
  //   's',
  //   '10%',
  //   (t,record)=><span>{record.first_crm?.message}</span>,
  //   sorterText('owner_user_name'),false,true
  // ),
  // generateColumn(
  //   'Mensaje último contacto',
  //   // t('alerts.user_assinged', 'Usuario asignado'),
  //   's',
  //   '10%',
  //   (t,record)=><span>{record.last_crm?.message}</span>,
  //   sorterText('owner_user_name'),false,true
  // ),
]
}
export const UseLeadsColumns = () =>{
  const { t, ready } = useTranslation();
if (!ready) return [];
// first_name,last_name,email,phone
const {user} = useAuth()

if(!user) return [];
  return  [
  IDCOLUMN,
  generateColumn(
    t('first_name', 'Nombre'),
    'first_name',
    '15%',
    false,
    sorterText('first_name')
  ),
  generateColumn(
    t('last_name', 'Apellido'),
    'last_name',
    '15%',
    false,
    sorterText('last_name')
  ),
  generateColumn('email', 'email', '20%'),
  generateColumn('phone', 'phone', '20%'),
  generateColumn(
    t('created','Creado'),
    'created_at',
    '6%',
     (t,r)=><span>{dayjs(t).tz(user.country.time_zone).format('DD/MM/YYYY HH:mm A')}</span>,
    {
      sorter: (a,b)=>compareByCreatedAt(a,b),
      defaultSortOrder: 'ascend',

    }
  ),
  ]
}