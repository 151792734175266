import React, { useState, useEffect } from 'react';
import TextArea from '../helpers/TextArea';
import { ConfigProvider, DatePicker, TimePicker, Tooltip } from 'antd';
import es_ES from 'antd/locale/es_ES';

import { createMemberCrm, updateAlert } from '../../api/crm';
import { getLocationId } from '../../services/utils/getLocation';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import SpinIndicador from '../helpers/SpinIndicador';
import { useAuth } from '../../context/AuthProvider';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

import SelectUser from '../helpers/SelectUser';
import SelectMember from '../helpers/SelectMember';
import { t } from 'i18next';
import { track_create_crm } from '../../services/utils/CustomerEvents';
import { customerIoIdentify } from '../../services/utils/CustomerIoUtils';
import * as Sentry from '@sentry/react';
export default function ModalCRM({
  CRM_OPTIONS,
  handleOpenCRM,
  member,
  type,
  visible,
  isProspect,
  start,
  end,
  openNotification,
  editing,
}) {
  if (!visible) return null;
  // console.log('editing',editing);

  const [selectedOption, setselectedOption] = useState(
    type ? CRM_OPTIONS.find((item) => item.action_type_id === type) : null
  );
  const [memberSelected, setmemberSelected] = useState(
    member ? member.id : null
  );
  const [inputValue, setinputValue] = useState('');
  const { user } = useAuth();
  const [user_selected, setuser_selected] = useState(user.user_id);
  const [showError, setshowError] = useState('');
  const queryClient = useQueryClient();
  const [hour_selected, sethour_selected] = useState();
  const [alert_date, setalert_date] = useState();

  const useCreateCRM = useMutation({
    mutationFn: (crm) => createMemberCrm(crm),
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ['alerts', start, end],
      });
      if (!member) {
        openNotification(
          'success',
          t('crm.success', 'El seguimiento se creó correctamente')
        );
        handleOpenCRM({ visible: false });
      } else {
        openNotification(
          'success',
          'Le generaste una ' +
            selectedOption?.title?.toLowerCase() +
            ' a ' +
            member.user.first_name +
            ' ' +
            member.user.last_name +
            (selectedOption.action_type_id == 6
              ? ' para el ' + dayjs(alert_date).format('YYYY-MM-DD')
              : ''),
          t('crm.success', 'El seguimiento se creó correctamente')
        );
      }
      if (memberSelected) {
        queryClient.invalidateQueries({ queryKey: ['crm', memberSelected] });
      }
      try {
        if (data.prospect) {
          customerIoIdentify(data.prospect.user_id, data.prospect.user.email);
        } else {
          customerIoIdentify(data.member.user_id, data.member.email);
        }
      } catch (error) {
        console.error('Error in customerIoIdentify:', error);
        Sentry.captureException(new Error(error.message), {
          extra: {
            user: user,
            screen: 'ModalCRM',
            endpoint: 'customerIoIdentify',
          },
        });
      }
      track_create_crm(data);
      // }
    },
    onError: (error) => {
      console.log('error', error.message, error);
      Sentry.captureException(error.message);
      openNotification(
        'error',
        t('crm.error', 'Hubo un error al crear el seguimiento')
      );
    },
  });
  const useUpdateCRM = useMutation({
    mutationFn: (crm) => updateAlert(crm.id, crm.crm),
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ['crm', memberSelected],
      });
      handleOpenCRM({ visible: false });
      openNotification(
        'success',
        t('crm.update_success', 'El seguimiento se actualizó correctamente')
      );
    },
    onError: (error) => {
      Sentry.captureException(error.message);
      openNotification(
        'error',
        t('crm.update_error', 'Hubo un error al actualizar el seguimiento')
      );
    },
  });
  useEffect(() => {
    setTimeout(() => {
      setshowError('');
    }, 2500);
  }, [showError]);
  useEffect(() => {
    if (editing) {
      setselectedOption(
        CRM_OPTIONS.find(
          (item) => item.action_type_id === editing.action_type_id
        )
      );
      setinputValue(editing.message);
      setalert_date(dayjs(editing.sent_at));
      // if (editing.action_type_id == 2) {

      sethour_selected(
        dayjs(editing.sent_at_original)
          .tz(user.country.time_zone)
          .startOf('minute')
      );

      // }
    }
  }, [editing]);

  const handleCreateCRM = async () => {
    if (inputValue.length == 0) {
      return setshowError(
        t('crm.add_message', 'Agregá un mensaje para continuar')
      );
    }
    if (selectedOption.action_type_id == 6 && !alert_date) {
      return setshowError(
        t(
          'crm.select_date',
          t('crm.select_date', 'Seleccioná una fecha para continuar')
        )
      );
    }
    const dateToFormat = alert_date ? dayjs(alert_date) : dayjs();
  
    const formattedDate = `${dateToFormat.tz(user.country.time_zone).format('YYYY-MM-DD')} ${
      hour_selected
        ? dayjs.utc(hour_selected).format('HH:mm:ss')
        :  dayjs().tz(user.country.time_zone).utc().format('HH:mm:ss')
    }`;
    
// return
    let crm = {
      crm: {
        user_id: user_selected,
        creator_id: user.user_id,
        action_type_id: selectedOption.action_type_id,
        customer_id: memberSelected,
        customer_type: isProspect ? 'Prospect' : 'Member',
        message: inputValue,
        location_id: getLocationId(),
        sent_at: formattedDate,
      },
      location_id: getLocationId(),
    };
    if (editing) {
      crm.crm.id = editing.id;
      crm.id = editing.id;
      await useUpdateCRM.mutateAsync(crm);
    } else {
      await useCreateCRM.mutateAsync(crm);
    }
    setinputValue('');
    setselectedOption(null);
    setuser_selected(user.user_id);
    setalert_date(undefined);
    sethour_selected(null);
  };

  return (
    <div
      onClick={() => {
        handleOpenCRM();
      }}
      className={`${
        visible ? 'fixed' : 'hidden'
      } inset-0 bg-background bg-opacity-75 backdrop-blur-[1px] flex justify-center items-center z-50`}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className='bg-primaryDark rounded-xl  p-7 relative sm:h-fit lg:w-[40%]  lg:h-fit lg:top-0 sm:margin-auto sm:left-0 sm:right-0 sm:top-0 sm:bottom-0'
      >
        <div className='flex flex-col justify-between w-full items-center py-10'>
          <h3 className='text-3xl font-MessinaSansSemiBold text-center'>
            {!editing
              ? t('crm.add_crm', 'Cargar seguimiento')
              : t('crm.edit_crm', 'Editar seguimiento')}
          </h3>
          {!editing && (
            <div className='flex flex-row items-center self-center mt-10 gap-x-4 '>
              {CRM_OPTIONS.filter(
                (item) => item.type !== 'sale' && item.show == null
              )
                .filter((item) => (type ? item.type == type : true))
                .map((item, index) => (
                  <Tooltip title={item.title} key={index}>
                    <div
                      onClick={() => setselectedOption(item)}
                      className={`rounded-full hover:cursor-pointer hover:scale-105 hover:ease-in-out duration-200 hover:opacity-90 ${
                        selectedOption &&
                        selectedOption.type !== item.type &&
                        'opacity-50'
                      }`}
                    >
                      {item.icon_lg}
                    </div>
                  </Tooltip>
                ))}
            </div>
          )}
          {!selectedOption && (
            <span className='mt-8 text-primaryGrey'>
              {t(
                'crm.select_option',
                'Seleccioná el tipo de seguimiento para continuar'
              )}
            </span>
          )}
          {selectedOption && (
            <div className='pt-5 items-center justify-center flex flex-col  min-w-[55%] '>
              {selectedOption.hasDateHeader && (
                <div className=' flex flex-row items-center placeholder:mt-1 mb-5 '>
                  <ConfigProvider locale={es_ES}>
                    <DatePicker
                      allowClear={false}
                      showToday
                      value={alert_date}
                      onChange={(date) => {
                        setalert_date(date);
                      }}
                      placeholder={selectedOption.placeholderDate}
                      className=' shadow-none bg-primaryGrey outline-none border-none py-2 appearance-none hover:bg-primaryGrey focus:bg-primaryGrey focus-within:bg-primaryGrey'
                    />
                  </ConfigProvider>
                  {selectedOption.hasUserSelect && (
                    <SelectUser
                      initialUser={{
                        full_name: user.first_name + ' ' + user.last_name,
                      }}
                      value={user_selected}
                      setValue={setuser_selected}
                    />
                  )}
                  {selectedOption.action_type_id == 6 && !member && (
                    <div className='ml-5'>
                      <SelectMember
                        value={memberSelected}
                        setValue={setmemberSelected}
                      />
                    </div>
                  )}
                  {selectedOption.hasDateHeader &&
                    selectedOption.action_type_id !== 6 && (
                      <ConfigProvider locale={es_ES}>
                        <TimePicker
                          showNow={false}
                          value={hour_selected || dayjs()}
                          onChange={(date) => {
                            sethour_selected(date)
                          }}
                          placeholder={t(
                            'crm.whatsapp_hour',
                            'Hora Conversacion'
                          )}
                          className=' shadow-none bg-primaryGrey outline-none border-none py-2 appearance-none ml-5 hover:bg-primaryGrey focus:bg-primaryGrey focus-within:bg-primaryGrey '
                          defaultValue={dayjs()}
                          format={'HH:mm'}
                        />
                      </ConfigProvider>
                    )}
                </div>
              )}

              <TextArea
                value={inputValue}
                styles={`w-full`}
                onChange={(e) => setinputValue(e.target.value)}
                placeholder={selectedOption.placeholder}
              />
              {selectedOption && (
                <button
                  disabled={useCreateCRM.isLoading || useUpdateCRM.isLoading}
                  onClick={() => handleCreateCRM()}
                  className={`bg-lightGreen rounded-sm py-4 ${
                    !member ? 'w-[53%]' : 'w-[100%]'
                  } self-center mt-5 flex flex-row items-center gap-2 justify-center`}
                >
                  <p className='font-MessinaSansBold text-xl text-black text-center'>
                    {!editing
                      ? t('crm.add', 'AGREGAR')
                      : t('crm.crm_edit', 'CONFIRMAR')}
                  </p>
                  {useCreateCRM.isLoading ||
                    (useUpdateCRM.isLoading && <SpinIndicador />)}
                </button>
              )}
            </div>
          )}

          {showError && (
            <span className='text-primaryRed text-center mt-5 mb-[-10px] font-MessinaSansSemiBold text-lg'>
              {showError}
            </span>
          )}
        </div>
      </div>
    </div>
  );
}
