import { t } from 'i18next';
import {
  LuRefreshCcw
} from 'react-icons/lu';
export const Statistics = ({
  filteredReserves,
  countByCondition,
  max_attendances,
  isFetching
}) => {
  
  return (
    <div className='px-5 py-5 flex jic border-b-primaryWhite border-b-[1px]'>
      <div className='flex gap-3'>
        {filteredReserves.filter((r) => r?.subscription?.owner_type =='Prospect').length > 0 && (
          <>
        <p className=' text-center'>
        <span id='reservas'>{filteredReserves.filter((r) => r?.subscription?.owner_type =='Prospect').length}</span> CDP
            </p>
            <p> | </p>
          </>
        )}
        <p className=' text-center'>
          <span id='presentes'>
            {countByCondition((reserve) => reserve?.has_attended)}
          </span>{' '}
          {t('reserves.present','Presentes')}
        </p>
        <p>|</p>
        <p className=' text-center'>
          <span id='ausentes'>
            {countByCondition((reserve) => !reserve?.has_attended)}
          </span>{' '}
          {t('reserves.absent','Ausentes')}
        </p>
        <p>|</p>
        <p className=' text-center'>
          <span id='reservas'>{filteredReserves.length}</span> {' '}
          {t('reserves.reserves','Reservas')}
        </p>
      </div>
      {/* This part can be further optimized by saving the result of the find operation */}
      {/* <p className='flex flex-row gap-2 items-center cursor-pointer'>
        Refrescar < LuRefreshCcw />
      </p> */}
        <p className=' text-center'>
        {t('reserves.total_reserves','Cupo Total')}: <span id='cupo'>{max_attendances}</span>
      </p>
    </div>
  );  
};
