import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
//BsChevronDown
import FormBuilder from '../components/helpers/FormBuilder';
import DrawerForm from '../components/DrawerForm';
import { get_user_lookup, getDocumentTypes } from '../api/users';
import {
  createProspect,
  get_mobile_prospect,
  getApproachTypes,
  getCampaings,
  getChannels,
} from '../api/prospects';
import { getPointSales } from '../api/locations';
import { useMutation } from '@tanstack/react-query';
import {
  getLocationId,
  getMaster_account_id,
} from '../services/utils/getLocation';
import { notification } from 'antd';
import { openNotificationFields } from '../services/utils/openNotificationFields';
import { USE_FORM_PROSPECT } from '../services/Constants/FORMS';
import { useAuth } from '../context/AuthProvider';
import { t } from 'i18next';
import { create_prospect } from '../services/utils/CustomerEvents';
import { get_mobile_member } from '../api/members';

export default function CreateProspect({
  typeMemberCreate,
  setTypeMemberCreate,
  setModalMemberOpen,
}) {
  if (!typeMemberCreate) return null;
  const FORM_PROSPECT = USE_FORM_PROSPECT();
  const user = useAuth();
  const [showModal, setshowModal] = useState({ visible: false });
  const [FORM, setFORM] = useState(FORM_PROSPECT);
  const [loadingSelect, setloadingSelect] = useState(false);
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    handleOpenDrawer(typeMemberCreate);
  }, [typeMemberCreate]);

  const onSubmit = async (data) => {
    const user_attributes = {
      master_account_id: getMaster_account_id(),
      document_type_id: 1,
      email: data.email,
      phone: data.phone,
      first_name: data.first_name,
      gender: data.gender,
      last_name: data.last_name,
      document_number: 'N/A',
      password: data.password,
      password_confirmation: data.password_confirmation,
      active: true,
    };
    const prospectData = {
      channel_id: data.channel_id,
      approach_type_id: data.approach_type_id,
      point_of_sale_id: data.point_of_sale_id,
      location_id: getLocationId(),
      user_attributes,
    };
    if (user?.user?.user_id !== 248) {
      //si nati carga uno en otra sede queda en null {
      prospectData.owner_user_id = user?.user?.user_id;
    } else if (getLocationId() == 1 || getLocationId() == 64) {
      //si es reco o online si se lo pone a nati
      prospectData.owner_user_id = user?.user?.user_id;
    }
    await useCreateProspect.mutateAsync(prospectData);
    return setshowModal({ visible: false, record: null });
  };
  const mapOption = (data) => {
    return data.map((item) => ({
      label: item.name,
      value: item.id,
    }));
  };

  const updateFormItemWithOptions = (item, options) => ({
    ...item,
    options: mapOption(options),
  });

  const handleOpenDrawer = async (visible) => {
    if (visible) {
      try {
        setloadingSelect(true);
        setshowModal({ visible: true });

        const [document_types, approach_types, point_of_sale] =
          await Promise.all([
            getDocumentTypes(),
            getApproachTypes(),
            getPointSales(),
          ]);

        let formItems = FORM.map((item) => {
          switch (item.name) {
            case 'document_type_id':
              return updateFormItemWithOptions(item, document_types);
            case 'point_of_sale_id':
              return updateFormItemWithOptions(item, point_of_sale);
            case 'approach_type_id':
              return updateFormItemWithOptions(item, approach_types);

            default:
              return item;
          }
        });

        const [campaings, channels] = await Promise.all([
          getCampaings(),
          getChannels(),
        ]);

        formItems = formItems.map((item) => {
          switch (item.name) {
            case 'channel_id':
              return updateFormItemWithOptions(item, channels.filter((l)=>l.visibility));
            case 'campaign_id':
              return updateFormItemWithOptions(item, campaings);
            default:
              return item;
          }
        });
        if (point_of_sale.length == 1) {
          setValue('point_of_sale_id', point_of_sale[0].id);
          formItems = formItems.filter(
            (item) => item.name != 'point_of_sale_id'
          );
        }

        setFORM(formItems);
        setloadingSelect(false);
      } catch (error) {
        // Manejo del error
      }
    } else {
      setTypeMemberCreate(false);
      setshowModal({ visible: false });
    }
  };
  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm({});
  const useCreateProspect = useMutation({
    mutationFn: (prospect) => createProspect(prospect),
    onSuccess: (data) => {
      create_prospect({
        id: data.user_id,
        prospect_id: data.id,
        email: data.user.email,
        customer_type: 'Prospect',
        phone: data.user.phone,
        first_name: data.user.first_name,
        last_name: data.user.last_name,
        gender: data.user.gender,
        username: data.user.username,
        birdthdate: data.user.birdthdate,
        location_name: data.location_name,
        channel_id: data.channel_id,
        location_id: data.location_id,
        created_in: 'biggeye',
      });
      setModalMemberOpen({ id: data.id, isProspect: true ,customer_type:'Prospect'});
      setTypeMemberCreate(false);
      reset();
    },
    onError: async (error, variables) => {
      let errorParse = JSON.parse(error.message);
      if (
        Object.keys(errorParse)[0] == 'email' ||
        Object.keys(errorParse)[0] == 'user.email'
      ) {
        const lookup = await get_user_lookup(variables.user_attributes.email);
        if (lookup.customer_type == 'Member') {
          const member = await get_mobile_member(lookup.token);
          openNotification('error', [
            t(
              'member_already_error',
              'El email ya se encuentra registrado como socio en la sede'
            ) +
              ' ' +
              member.location?.name +
              ' y su ID es ' +
              member.member_id,
          ]);
          return;
        } else {
          try {
            const prospect = await get_mobile_prospect(lookup.token);
            openNotification('error', [
              t(
                'prospectalready_error',
                'El email ya se encuentra registrado como prospecto en la sede'
              ) +
                ' ' +
                prospect.location?.name +
                ' y su ID es ' +
                prospect.prospect_id,
            ]);
            return;
          } catch (error) {
            openNotification(
              'error',
              Object.keys(errorParse).map(
                (key) => `${key} : ${errorParse[key]}`
              )
            );
          }
        }
      }
      openNotification(
        'error',
        Object.keys(errorParse).map((key) => `${key} : ${errorParse[key]}`)
      );
    },
  });

  const openNotification = (type, description) => {
    api[type]({
      message:
        type == 'success'
          ? t('prospect_success', 'Prospecto creado correctamente')
          : t('prospect_error', 'Ocurrió un error al crear el prospecto'),
      ...openNotificationFields(type, description),
    });
  };

  return (
    <DrawerForm
      title={t('prospect', 'Prospecto')}
      openDrawer={showModal}
      handleOpenDrawer={handleOpenDrawer}
    >
      {contextHolder}
      <FormBuilder
        canUpdate={true}
        record={null}
        canCreate={true}
        control={control}
        title={t('prospect', 'Prospecto')}
        fields={FORM}
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
        loadingSelect={loadingSelect}
        register={register}
        mutateLoading={useCreateProspect.isLoading}
      />
    </DrawerForm>
  );
}
