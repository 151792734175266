import { fetchData } from '../services/utils/axiosInstance';
import { getLocationId } from '../services/utils/getLocation';
import axiosInstance from './prospects';
import { useQuery } from '@tanstack/react-query';
export const getMemberships = async () => {
  const location_id = getLocationId();
  const result = await fetchData(`/memberships?location_id=${location_id}`);
  if (!result || result.length === 0) return [];
  return result.map((membership) => ({
    ...membership,
    name: membership.product.name,
    description: membership.product.description,
    price: membership.product.price,
    product_type_id: membership.product.product_type_id,
    product_type_name: 'Membership',
    product_type_name_es: 'Membresias',
  }));
};
export const useGetMemberships = () => {
  return useQuery({
    queryKey: ['memberships'],
    queryFn: async () => getMemberships(),
  });
};

export const getMembership = async (id) => {
  const result = await fetchData(`/memberships/${id}`);
  if (!result) return {};
  return {
    ...result,
    name: result.product.name,
    description: result.product.description,
    price: result.product.price,
  };
};
export const updateMembership = async (id, data) => {
  const location_id = getLocationId();
  return await fetchData(
    `/memberships/${id}?location_id=${location_id}`,
    'PATCH',
    data
  );
};
export const createMembership = async (data) => {
  const location_id = getLocationId();

  return await fetchData(
    `/memberships?location_id=${location_id}`,
    'POST',
    data
  );
};
export const deleteMembership = async (id) => {
  const location_id = getLocationId();

  return await fetchData(
    `/memberships/${id}?location_id=${location_id}`,
    'DELETE'
  );
};

export default axiosInstance;
