import React, { useState, useEffect } from 'react';
import { Checkbox, notification ,Divider} from 'antd';
import { useOutletContext } from 'react-router-dom';
import CardRounded from '../components/CardRounded';

import { deleteMember, useGetMembers } from '../api/members';
import TableCustom from '../components/TableCustom';
import { USEMemberColumns } from '../services/Constants/UsersColumns';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { formatError } from '../services/utils/formatError';
import { openNotificationFields } from '../services/utils/openNotificationFields';
import SpinIndicador from '../components/helpers/SpinIndicador';
import { t } from 'i18next';
import { getLocationId } from '../services/utils/getLocation';
import MembersCompositionChart from '../components/charts/MembersCompositionChart';

export default function Members() {
  const MemberColumns = USEMemberColumns();
  const TITLE = t('members', 'Socios');
  const TITLE_SINGULAR = t('member', 'Socio');
  const location_id = getLocationId();
  const [
    modalMemberOpen,
    setModalMemberOpen,
    setmodalSale,
    setmodalTransaction,
    setTypeMemberCreate,
  ] = useOutletContext();
  const { isLoading, isError, data, error } = useGetMembers();
  const [filterTable, setfilterTable] = useState([]);
  const [columns, setColumns] = useState(MemberColumns.map((item,i) => ({ ...item, key: i.toString() })));
  const [api, contextHolder] = notification.useNotification();
  const [open, setOpen] = useState(false);
  const [activeFilter, setactiveFilter] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [searchInput, setsearchInput] = useState('');
  const queryClient = useQueryClient();
  const handleDelete = async (id) => {
    setConfirmLoading(true);
    await useMemberDelete.mutateAsync(id);
    setConfirmLoading(false);
    setOpen(false);
  };
  useEffect(() => {
    if (!isLoading) {
      const createFilters = (data, key) =>
        [...new Set(data.map((d) => d[key]))]
          .filter(Boolean)
          .map((value) => ({ text: value, value }));
  
      const createSubscriptionsFilters = (data) =>
        [...new Set(data.flatMap((d) => d.subscriptions.map((sub) => sub.product_name)))]
          .map((value) => ({ text: value, value }));
  
      const filtersForDebits = [
        { text: t('with_debit_automatic', 'Con debito automatico'), value: true },
        { text: t('without_debit_automatic', 'Sin debito automatico'), value: false },
        { text: t('active_debit_automatic','Debitos Activos'), value: 'active' },
        { text: t('inactive_debits','Debitos Inactivos'), value: 'deactivated' },
        { text: 'Alta de Debito', value: 'new' },
      ];
  
      const onFilterDebits = (value, record) =>
      { 
         if(value === false) return record.debits.length === 0;
         if (value === 'deactivated') {
          return (
            record.debits.length > 0 && // Asegurarse de que tenga al menos un débito
            record.debits.every((c) => c.actual_state === 'deactivated') // Todos los débitos deben estar en estado 'deactivated'
          );
        }
        //  if(value == 'deactivated') return record.debits.filter((c) => c.actual_state !== 'deactivated').length == 0;
        return record.debits.some((c) => {
          if (value === true) return record.debits.length > 0;
          return c.actual_state === value;}
        );
      }
  
      setColumns(
        columns.map((column,i) => {
          if (column.dataIndex === 'subscriptions') {
            return {
              ...column,
              filters: createSubscriptionsFilters(data),
              onFilter: (value, record) =>
                record.subscriptions.some((c) => c.product_name === value),
            };
          }
          if (column.dataIndex === 'coach_name') {
            return {
              ...column,
              filters: createFilters(data, 'coach_name'),
              onFilter: (value, record) => record.coach_name === value,
            };
          }
          if (column.dataIndex === 'debits') {
            return {
              ...column,
              filters: filtersForDebits,
              onFilter: onFilterDebits,
            };
          }
          return { ...column, visible: false,key: i.toString() };
        })
      );
  
      setfilterTable(data);
    }
  }, [data, isLoading]);

  const useMemberDelete = useMutation({
    mutationFn: (id) => deleteMember(id),
    onSuccess: () => {
      openNotification('success');
      queryClient.invalidateQueries({
        queryKey: ['members', location_id],
      });
      setConfirmLoading(false);
    },
    onError: (error) => {
      formatError(error, openNotification);
      setConfirmLoading(false);
    },
  });
  const openNotification = (type, description) => {
    api[type]({
      message:
        type == 'success'
          ? t(
              'member.update_success',
              `El Socio se ha actualizado correctamente`
            )
          : t(
              'member.update_error',
              `El Socio no se ha actualizado correctamente`
            ),
      ...openNotificationFields(type, description),
    });
  };
const handleClickFilter = (name,filter)=>{
  if(activeFilter == name){
    setfilterTable(data)
    return setactiveFilter(false)
  }
  setfilterTable(data.filter(filter))
  setactiveFilter(name)
}
  return (
    <div className='h-screen pl-[1rem] lg:pl-[3rem]'>
      {contextHolder}
      <div className=' mt-6  flex-grow flex flex-row items-center justify-between  flex-wrap'>
        <h2 className='text-2xl font-MessinaSansSemiBold'>
          {TITLE} {isLoading ? '' : data.length}
        </h2>
        <div className='flex  gap-5 mr-10 justify-end  '>
       
          <CardRounded styles={`mt-6  py-3 relative px-6 flex cursor-pointer  items-center flex ${activeFilter == 'free' && '!bg-lightGreen !text-black'}`}>
            <div
            onClick={()=>{
              handleClickFilter('free', (p) => p.subscriptions.some((c) => c.parent_product_id == 29))
            }}
            className='flex flex-col items-center justify-center '>
              {isLoading ? (
                <SpinIndicador color={'fill-secondaryGrey'} />
              ) : (
                <h3 className={`text-center font-DrukWideMedium ${activeFilter == 'free' ? 'text-black' : 'text-white'} text-xl`}>
                  {
                    data.filter((p) =>
                      p.subscriptions.some((c) => c.parent_product_id == 29)
                    ).length
                  }
                </h3>
              )}{' '}
              <h3 className=' text-center mt-2'>Free</h3>
            </div>
          </CardRounded>
          <CardRounded styles={`mt-6  relative px-5 flex cursor-pointer  items-center flex ${activeFilter == 'active_debit' && '!bg-lightGreen !text-black'}`}>
            <div
              onClick={()=>{
                handleClickFilter('active_debit',
                (p) => p.debits.some((c) => c.actual_state == 'active')
                )
              }}
            className='flex flex-col items-center justify-center '>
              {isLoading ? (
                <SpinIndicador color={'fill-secondaryGrey'} />
              ) : (
                <h3 className={`text-center font-DrukWideMedium ${activeFilter == 'active_debit' ? 'text-black' : 'text-white'} text-xl`}>
                  {data.map((p)=>p.debits).flat().filter((p) =>  p.actual_state == 'active')?.length}
                </h3>
              )}{' '}
              <h3 className=' text-center mt-2'>
                {/* activos */}
                {t('active_debit_automatic', 'Debitos Activos')}
              </h3>
            </div>
          </CardRounded> 
          <CardRounded styles={`mt-6  relative px-5 flex cursor-pointer  items-center flex ${activeFilter == 'inactive_debit' && '!bg-lightGreen !text-black'}`}>
            <div
              onClick={()=>{
                handleClickFilter('inactive_debit',
                  (p) => 
                    p.debits.length > 0 && // Asegúrate de que tenga al menos un débito
                    p.debits.every((d) => d.actual_state === 'deactivated') // Todos los débitos deben estar en estado 'deactivated'
                  )
                
              }}
            className='flex flex-col items-center justify-center '>
              {isLoading ? (
                <SpinIndicador color={'fill-secondaryGrey'} />
              ) : (
                <h3 className={`text-center    font-DrukWideMedium ${activeFilter == 'inactive_debit' ? 'text-black' : 'text-primaryRed'} text-xl`}>
            {data.filter((p) => 
  p.debits.length > 0 && // Asegúrate de que tenga al menos un débito
  p.debits.every((d) => d.actual_state === 'deactivated') // Todos los débitos deben estar en estado 'deactivated'
)?.length}
                </h3>
              )}{' '}
              <h3 className={`text-center mt-2  ${ activeFilter == 'inactive_debit' ? 'text-black':'text-primaryRed'}`}>
                {/* inactivos */}
                {t('inactive_debits','Debitos Activos')}
              </h3>
            </div>
          </CardRounded> 
          <CardRounded styles={`mt-6  relative px-5 flex cursor-pointer  items-center flex ${activeFilter == 'new_debit' && '!bg-lightGreen !text-black'}`}>
         
            <div 
             onClick={()=>{
              handleClickFilter('new_debit',
              (p) => p.debits.some((c) => c.actual_state == 'new' ))
            }}
            className='flex flex-col items-center justify-center '>
              {isLoading ? (
                <SpinIndicador color={'fill-secondaryGrey'} />
              ) : (
                <h3 className={`text-center font-DrukWideMedium ${activeFilter == 'new_debit' ? 'text-black' : 'text-white'} text-xl`}>
                  {data.map((p)=>p.debits).flat().filter((p) =>  p.actual_state == 'new')?.length}
                </h3>
              )}{' '}
              <h3 className=' text-center mt-2'>
                
                {t('new_debit_automatic', 'Debitos Nuevos')}
              </h3>
            </div>
          </CardRounded> 
        </div>
      </div>

      <div className=' mt-[-40px]'>
        <TableCustom
          multipleTables
          hideTitle
          handleDelete={handleDelete}
          filterTable={filterTable}
          data={data}
          openDrawer={() => {}}
          setfilterTable={setfilterTable}
          handleOpenDrawer={(visible, record) => {
            if (record) {
              setModalMemberOpen({ id: record.id, isProspect: false ,customer_type:'Member'});
            }
          }}
          title={TITLE}
          title_singular={TITLE_SINGULAR}
          searchInput={searchInput}
          onSubmit={() => {}}
          originalColumns={columns}
          handleSubmit={() => {}}
          setsearchInput={setsearchInput}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
}
