import React, { useState, useEffect ,useMemo,useCallback} from 'react';
import {
  notification,
  ConfigProvider,
  DatePicker,
  Select,
  Checkbox,
Tooltip,
Cascader
} from 'antd';
import {  useOutletContext } from 'react-router-dom';
import CardRounded from '../components/CardRounded';
import es_ES from 'antd/locale/es_ES';
import { BiCheckboxChecked, BiErrorAlt, BiXCircle } from 'react-icons/bi';
import {
  MdOutlineChevronRight,
} from 'react-icons/md';
import TableCustom from '../components/TableCustom';

import dayjs from 'dayjs';
import { useGetReports } from '../api/reports';
import { use_REPORT_LIST_OPTIONS } from '../services/Constants/Reports';
import HelpQuestion from '../components/helpers/popovers/HelpQuestion';
import { getClasses } from '../api/gym_classes';
import { useQueryClient } from '@tanstack/react-query';
import { t } from 'i18next';
import { useGlobalContext } from '../context/GlobalState';
import { getLocationId } from '../services/utils/getLocation';
import { isUserManager} from '../services/can_user';
import SelectLocation from './SelectLocation';

const start = dayjs().startOf('month');
const end = dayjs().add(1, 'month').startOf('month');
export default function Reports() {
  const [
    modalMemberOpen,
    setModalMemberOpen,
    setmodalSale,
    setmodalTransaction,
    setTypeMemberCreate,
  ] = useOutletContext();
  const [start_date, setstart_date] = useState(start);
  const [end_date, setend_date] = useState(end);
  const REPORT_LIST_OPTIONS = use_REPORT_LIST_OPTIONS(start_date, end_date);
  const REPORT_LIST = REPORT_LIST_OPTIONS.reduce((acc, curr) => {
    return acc.concat(curr.options);
  }, []);
const [location_selected, setlocation_selected] = useState([getLocationId()])
  const [month_selected, setMonthSelected] = useState(start);
  const queryClient = useQueryClient();
  const [active_checkbox, setactive_checkbox] = useState(false);
  const [isSelectAttendance, setisAttendance] = useState(null);
  const [gym_classes, setGymclasses] = useState([]);
  const [selectedClass, setselectedClass] = useState(null);
  const [selectSameMoment, setSelectSameMoment] = useState(null);
  const state = useGlobalContext();
  const [openLocations, setopenLocations] = useState(false)
  const [reportSelected, setreportSelected] = useState(null);
  const { isLoading, isError, data, error } = useGetReports(
    location_selected,
    reportSelected,
    start_date.format('YYYY-MM-DD'),
    end_date.format('YYYY-MM-DD'),
    REPORT_LIST.find((r) => r.value == reportSelected)?.pickerMonth
      ? month_selected.format('YYYY-MM-DD')
      : null,
    active_checkbox,
    isSelectAttendance,
    selectedClass,
    selectSameMoment
  );
  const [filterTable, setfilterTable] = useState([]);
  const [api, contextHolder] = notification.useNotification();

  const datepickerFrom = React.useRef();
  const datepickerMonth = React.useRef();

  const datepickerAt = React.useRef();
  const selectRef = React.useRef();

  const [columns, setColumns] = useState([]);
  const [searchInput, setsearchInput] = useState('');

  const reduceDebits = (data) => {
    if (!data) return [];
   return  data.reduce((acc, item) => {
      const index = acc.findIndex((el) => el.debit_id === item.debit_id);
      
      if (index === -1) {
        acc.push({
          debit_id: item.debit_id,
          ...item,
          amount : Number(item.price_debit_payment) || 0, // Asegurarse de que sea un número
          price_debit_payment: Number(item.price_debit_payment) || 0, // Asegurarse de que sea un número
        });
      } else {
        acc[index].price_debit_payment += Number(item.price_debit_payment) || 0; // Sumar como número
      }
      
      return acc;
    }, []);
  }
  useEffect(() => {
    if (isError) {
      api.error({
        message: t('errorLoadingData', 'Error al cargar los datos'),
        description: error.message,
        placement: 'bottomRight',
      });
    }
    if (!isLoading && data) {
      if(reportSelected== 14){
        let dataFiltered =reduceDebits(data)
    return setfilterTable(dataFiltered);
      }
      setfilterTable(data);
    }
  }, [data, isLoading, reportSelected]);


  const items = [
    ...[
      t('reports', 'Reportes'),
      t('fromLabel', 'Desde'),
      t('toLabel', 'Hasta'),
    ].filter((c) =>
      reportSelected &&
      REPORT_LIST.find((r) => r.value === reportSelected).pickerMonth
        ? c === 'Reportes'
        : c
    ),
    ...(REPORT_LIST.find((r) => r.value === reportSelected)?.pickerMonth
      ? ['Mes']
      : []),
    ...(REPORT_LIST.find((r) => r.value === reportSelected)?.activeFilter
      ? ['Activos']
      : []),
    ...(REPORT_LIST.find((r) => r.value === reportSelected)?.hasAttendance
      ? ['Asistencia']
      : []),
    ...(REPORT_LIST.find((r) => r.value === reportSelected)?.selectClass
      ? ['Clase']
      : []),
    ...(REPORT_LIST.find((r) => r.value === reportSelected)?.sameMoment
      ? [`Mismo  momento`]
      : []),
     ...isUserManager() ?  [`Sede`] :[]
  ];

  const CardRoundedItem = ({ item, index, reportSelected, setreportSelected, location_selected, setlocation_selected, setfilterTable, active_checkbox, setactive_checkbox, start_date, setstart_date, end_date, setend_date, month_selected, setMonthSelected, isSelectAttendance, setisAttendance, selectedClass, setselectedClass, selectSameMoment, setSelectSameMoment, openLocations, setopenLocations, state, selectRef, datepickerFrom, datepickerAt, datepickerMonth }) => {
    const report = useMemo(() => REPORT_LIST.find((r) => r.value == reportSelected), [reportSelected]);
  
    const isSelect = index === 0;
    const isDatePicker = !report?.pickerMonth && index > 0 && index < 3;
    const isMonthPicker = report?.pickerMonth && index === 1;
    const isActiveCheckbox = report?.activeFilter && index === 3;
    const isSameMoment = report?.sameMoment && index === 2;
    const isSelectClass = report?.selectClass && index === 4;
    const isAttendance = report?.hasAttendance && index === 3;
  
    const handleSelectChange = useCallback((newValue) => {
      setfilterTable([]);
      setlocation_selected(location_selected);
      
      setreportSelected(newValue[1]);
      // selectRef?.current.blur();
    }, [setfilterTable, location_selected, setlocation_selected, setreportSelected]);
  
  console.log('reportSelected',reportSelected);
    const handleDatePickerChange = useCallback((date) => {
      if (index === 1) {
        datepickerFrom.current.blur();
        setstart_date(date);
      } else if (index === 2) {
        datepickerAt.current.blur();
        setend_date(date);
      }
    }, [index, setstart_date, setend_date]);
  
    const handleMonthPickerChange = useCallback((date) => {
      setMonthSelected(date);
      datepickerMonth.current.blur();
    }, [setMonthSelected]);
  
    const handleCheckboxChange = useCallback((e) => {
      setactive_checkbox(e.target.checked);
    }, [setactive_checkbox]);
  
    const handleAttendanceChange = useCallback((newValue) => {
      setisAttendance(newValue);
    }, [setisAttendance]);
  
    const handleClassChange = useCallback((newValue) => {
      setselectedClass(newValue);
    }, [setselectedClass]);
  
    const handleSameMomentChange = useCallback((newValue) => {
      setSelectSameMoment(newValue);
    }, [setSelectSameMoment]);
    const displayRender = (labels) => {
      // console.log('labels',labels[1]);
      return REPORT_LIST.find((r) => r.value == labels[0])?.label;
    }
    const filter = (inputValue, path) =>
  path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
  return (
      <div className='relative border-b-primaryGrey pt-10 pb-2 px-5 last:border-b-transparent flex flex-row items-center  '>
        <p className='opacity-40 absolute top-2 font-MessinaSansSemiBold pl-3 w-full'>{item}</p>
        {isSelect && (
           <Cascader
           options={REPORT_LIST_OPTIONS.map((p)=>({
            value:p.label,
            label:p.label,
            children:p.options
           }))}
           showSearch={{
            filter,
          }}
           allowClear={false}
           value={reportSelected}
           expandIcon={<MdOutlineChevronRight size={22} color='#444444' />}
           expandTrigger="hover"
           bordered={false}
           disabled={location_selected.length === 0}
           placeholder='Buscar reporte'
           displayRender={displayRender}
           onChange={handleSelectChange}
           className=' text-white outline-none border-none py-1 rounded-md font-DrukWideMedium '
         />
     
        )}
        {isDatePicker && (
          <ConfigProvider locale={es_ES}>
            <DatePicker
              disabled={active_checkbox}
              picker={report?.value === 18 ? 'month' : 'date'}
              allowClear={false}
              ref={index === 1 ? datepickerFrom : datepickerAt}
              onChange={handleDatePickerChange}
              format={report?.value === 18 ? 'MMMM YYYY' : 'DD/MM/YYYY'}
              value={index === 1 ? start_date : end_date}
              showToday
              className={`bg-transparent outline-none border-none h-12 w-full appearance-none border-transparent shadow-none hover:bg-primaryGrey focus:bg-primaryGrey focus-within:bg-primaryGrey ${active_checkbox && 'opacity-30'}`}
            />
          </ConfigProvider>
        )}
        {isMonthPicker && (
          <ConfigProvider locale={es_ES}>
            <DatePicker
              picker='month'
              allowClear={false}
              ref={datepickerMonth}
              onChange={handleMonthPickerChange}
              format='MMMM YYYY'
              value={month_selected}
              showToday
              className='bg-transparent outline-none border-none h-12 w-full appearance-none border-transparent shadow-none hover:bg-primaryGrey focus:bg-primaryGrey focus-within:bg-primaryGrey'
            />
          </ConfigProvider>
        )}
        {isActiveCheckbox && (
          <Checkbox
            className='w-16 flex justify-center'
            checked={active_checkbox}
            onChange={handleCheckboxChange}
          />
        )}
        {isAttendance && (
          <Select
            ref={selectRef}
            bordered={false}
            value={isSelectAttendance}
            onChange={handleAttendanceChange}
            className='bg-transparent text-white outline-none border-none py-1 rounded-md font-DrukWideMedium'
            style={{ width: 220 }}
            options={[
              { label: 'Asistido', value: true },
              { label: 'No Asistido', value: false },
            ]}
          />
        )}
        {isSelectClass && (
          <Select
            ref={selectRef}
            bordered={false}
            value={selectedClass}
            onChange={handleClassChange}
            className='bg-transparent text-white outline-none border-none py-1 rounded-md font-DrukWideMedium'
            style={{ width: 220 }}
            options={gym_classes}
          />
        )}
        {isSameMoment && (
          <Select
            ref={selectRef}
            bordered={false}
            value={selectSameMoment}
            onChange={handleSameMomentChange}
            className='bg-transparent text-white outline-none border-none py-1 rounded-md font-DrukWideMedium'
            style={{ width: 220 }}
            options={[
              { label: 'Si', value: true },
              { label: 'No', value: false },
            ]}
          />
        )}
        {item === 'Sede' && state.state?.availableLocations && (
          <SelectLocation
            openLocations={openLocations}
            setopenLocations={setopenLocations}
            location_selected={location_selected}
            state={state}
            handleChange={()=>{
              queryClient.invalidateQueries({
                queryKey: [
                  'reports',
                  location_selected,
                  reportSelected,
                  start_date.format('YYYY-MM-DD'),
                  end_date.format('YYYY-MM-DD'),
                  REPORT_LIST.find((r) => r.value == reportSelected)?.pickerMonth
                    ? month_selected.format('YYYY-MM-DD')
                    : null,
                  active_checkbox,
                  isSelectAttendance,
                  selectedClass,
                  selectSameMoment,
                ],
              });
            }}
            setlocation_selected={setlocation_selected}
          />
        )}
      </div>
    );
  };
  useEffect(() => {
    if (reportSelected && !isLoading && data && data.length > 0) {
      const createFilters = (data, key) =>
        data
          .filter((d) => d[key])
          .map((d) => ({ text: d[key], value: d[key] }))
          .sort((a, b) => a.text > b.text)
          .reduce((acc, current) => {
            if (!acc.find((item) => item.value === current.value)) {
              acc.push(current);
            }
            return acc;
          }, []);
  
      let columns = REPORT_LIST.find((r) => r.value == reportSelected).columns.map((column) => {
        const { dataIndex } = column;
        if (
          dataIndex === 'product_name' ||
          dataIndex === 'coach_assigned' ||
          dataIndex === 'discharge_reason' ||
          dataIndex === 'channel' ||
          dataIndex === 'payment_methods_name' ||
          dataIndex === 'discount_name' ||
          dataIndex === 'username' ||
          dataIndex === 'coach_name' ||
          dataIndex === 'member_type' ||
          dataIndex === 'member_state' ||
          dataIndex === 'crm_type' ||
          dataIndex === 'product_type' ||
          dataIndex === 'member_period' ||
          dataIndex === 'total_uses'  ||
          dataIndex === 'antiquity'  || dataIndex === 'type'
        ) {
          return {
            ...column,
            filters: createFilters(data, dataIndex),
            onFilter: (value, record) => record[dataIndex] == value,
          };
        }
        return column;
      });
      if (reportSelected == 21) {
        const weekColumns = [];
        const weekAttributes = ['assigned_state'];
        data.forEach((d) => {
          if (Array.isArray(d.week_number)) {
            const sortedWeeks =  d.week_number.sort((a,b)=>a.week > b.week ? 1 : -1)
            sortedWeeks.forEach((week, i) => {
              weekAttributes.forEach((attr) => {
                const columnKey = `week_${week.week}_${attr}`;
                if (!weekColumns.find(col => col.key === columnKey) && attr !== 'weekly_uses') {
                  weekColumns.push({
                    title: `Semana ${week.week_range}`,
                    dataIndex: `week_number.${week.week}.${attr}`,
                    key: columnKey,
                    width: '6%',
                    render: (text, record) => {
              const weekData = record.week_number.find((w) => w.week == week.week);
                      if(!weekData) return null;
                      if(attr == 'assigned_state' ){
                        return (<Tooltip
                         title={weekData[attr] == 'OK' ? 'Debió ser contactado, se contacto' : weekData[attr] == 'NO' ? 'Se debió haber contactado, no se contacto' : weekData[attr] == 'REGULAR' ? 'No debía ser contactado, se contacto' : ''}
                        >
                          {weekData[attr] == 'OK' ?  
                          <BiCheckboxChecked size={29} color='#1EA05A' />
                        : weekData[attr] =='NO'? <BiXCircle size={29} color={'#ED4C5C'} /> : weekData[attr] == 'REGULAR'? <BiErrorAlt size={29} color='#FFD500' /> :<></>}
                        </Tooltip>)
                        
                      }
                      return weekData ? weekData[attr] : null;
                    },
                  });
                }
              });
            });
          }
        });
        columns = columns.concat(weekColumns);
      }
      if (location_selected.length > 1 && ![12, 16].includes(reportSelected)) {
        columns = columns.concat({
          title: t('location_singular', 'Sede'),
          dataIndex: 'location_name',
          key: 'location_name',
          width: '6%',
          render: (text, record) =>
            state.state.availableLocations.find((l) => l.id == record.location_id)?.name,
          filters: state.state.availableLocations
            .sort((a, b) => a.name > b.name)
            .map((l) => ({
              text: l.name,
              value: l.id,
            }))
            .filter((l) => location_selected.includes(l.value)),
          onFilter: (value, record) => record.location_id == value,
        });
      }
      
      setColumns(columns);
    }
  }, [reportSelected, isLoading, data]);
  

  useEffect(() => {
    if (reportSelected == 5) {
      fetchGymClasses();
    }
  }, [reportSelected]);
  const fetchGymClasses = async () => {
    const result = await getClasses();

    setGymclasses(
      result
        .filter((c) => c.parent_id)
        .map((c) => ({ label: c.name, value: c.name }))
    );
  };
  return (
    <div 

    className='h-screen pl-[1rem] lg:pl-[3rem]   mt-8 gap-9  '>
      {contextHolder}
      <div className='mb-10 flex-grow flex flex-col mt-2'>
        <h2 className='text-2xl font-MessinaSansSemiBold flex items-center gap-3'>
          {t('reports', 'Reportes')}
          <HelpQuestion
            description={`Ir al centro de ayuda`}
            link={`https://biggeye.kb.help/reportes`}
          />
        </h2>
        <CardRounded
          styles={' mt-6 py-1 relative flex flex-row mr-6   lg:mr-0   gap-0 lg:gap-10  w-fit  flex-wrap'}
        >
          {items.map((item, index) => (
            <CardRoundedItem
              key={index}
              item={item}
              index={index}
              reportSelected={reportSelected}
              setreportSelected={setreportSelected}
              location_selected={location_selected}
              setlocation_selected={setlocation_selected}
              setfilterTable={setfilterTable}
              active_checkbox={active_checkbox}
              setactive_checkbox={setactive_checkbox}
              start_date={start_date}
              setstart_date={setstart_date}
              end_date={end_date}
              setend_date={setend_date}
              month_selected={month_selected}
              setMonthSelected={setMonthSelected}
              isSelectAttendance={isSelectAttendance}
              setisAttendance={setisAttendance}
              selectedClass={selectedClass}
              setselectedClass={setselectedClass}
              selectSameMoment={selectSameMoment}
              setSelectSameMoment={setSelectSameMoment}
              openLocations={openLocations}
              setopenLocations={setopenLocations}
              state={state}
              selectRef={selectRef}
              datepickerFrom={datepickerFrom}
              datepickerAt={datepickerAt}
              datepickerMonth={datepickerMonth}

            />
          ))}
        </CardRounded>
      </div>

      <div
        // onClick={()=>{
        //   console.log('click');
        //   setopenLocations(false)
        //  }}
      className='w-[100%] mt-[-40px]'>
        {reportSelected && (
          <TableCustom
            hideTitle
            multipleTables
            filterTable={filterTable}
            dataOriginal={data}
            data={
reportSelected == 14 ? reduceDebits(data) : data
            }
            openDrawer={() => {}}
            setfilterTable={setfilterTable}
            handleOpenDrawer={(visible, record) => {
              if (record) {
                if (reportSelected == 20) {
                  record.customer_type = 'Member';
                }
                // console.log('record', !record.member_id || record.customer_type == 'Prospect' ? false: true);
                setModalMemberOpen({
                  id: record.member_id
                    ? record.member_id
                    : record.prospect_id
                    ? record.prospect_id
                    : record.customer_id
                    ? record.customer_id
                    : record.id,
                  isProspect:
                    record.member_id || record.customer_type == 'Member'
                      ? false
                      : true,
                      customer_type:
                      record.customer_type ? record.customer_type : record.member_id ? 'Member' : 'Prospect'
                });
              } else {
                setTypeMemberCreate(true);
              }
            }}
            title={REPORT_LIST.find((r) => r.value == reportSelected)?.label}
            title_singular={''}
            showFooterTotal={REPORT_LIST.find((r) => r.value == reportSelected)?.showFooterTotal}
            handleDelete={false}
            searchInput={searchInput}
            onSubmit={() => {}}
            originalColumns={columns}
            handleSubmit={() => {}}
            setsearchInput={setsearchInput}
            isLoading={isLoading}
          />
        )}
      </div>
    </div>
  );
}
