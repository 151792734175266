import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import TableCustom from '../components/TableCustom';
import dayjs from 'dayjs';
import { USE_FORM_SALE_GOAL } from '../services/Constants/FORMS';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { notification, ConfigProvider, Table } from 'antd';
import { formatError } from '../services/utils/formatError';
import { openNotificationFields } from '../services/utils/openNotificationFields';
import { canCreateSaleGoals } from '../services/can_user';
import { t } from 'i18next';
import {
  createSaleGoal,
  deleteSaleGoal,
  getSaleGoal,
  getSalesGoalsTypes,
  updateSaleGoal,
  useGetSalesGoals,
} from '../api/sales_objectives';
import { SalesGoalsColumns } from '../services/Constants/LocationColumns';
import { getLocationId } from '../services/utils/getLocation';
import { getPointSales } from '../api/locations';
import { formatPrice } from '../services/utils/formatPrice';
import { v4 as uuid } from 'uuid';
import AddItemTable from '../components/helpers/AddItemTable';
const start = dayjs().startOf('month');
const end = dayjs();
const location_id = getLocationId();
export default function SalesGoals() {
  const { data, isLoading } = useGetSalesGoals();
  const columns = SalesGoalsColumns();
  const TITLE = t('sales_goals', 'Objetivos comerciales');
  const TITLE_SINGULAR = t('sales_goals_singular', 'Objetivo comercial');
  const FORM_SALE = USE_FORM_SALE_GOAL();
  const [start_date, setstart_date] = useState(start);
  const [end_date, setend_date] = useState(end);
  const [filterTable, setfilterTable] = useState(isLoading ? [] : data);
  const queryClient = useQueryClient();
  const [api, contextHolder] = notification.useNotification();
  useEffect(() => {
    setfilterTable(isLoading ? [] : data);
  }, [data]);

  const handleDelete = async (goals) => {
    // console.log('goals',goals);
    goals.goals.forEach(async (goal) => {
      await useDeleteSaleGoal.mutateAsync(goal.id);
    });
  };
  const openNotification = (type, description) => {
    api[type]({
      message:
        type == 'success'
          ? t(
              'sale_goal_success',
              'Objetivo comercial actualizado correctamente'
            )
          : t(
              'sale_goal_error',
              'Ocurrió un error al actualizar el objetivo comercial'
            ),
      ...openNotificationFields(type, description),
    });
  };

  const [FORM, setFORM] = useState(FORM_SALE);
  const [searchInput, setsearchInput] = useState('');
  const [openDrawer, setopenDrawer] = useState({
    visible: false,
    record: null,
  });
  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const onSubmit = async (goal) => {
    let orderObjectives = [1, 2, 3, 4, 5];
    // Common data for sale goal
    const newUuid = uuid();
    const commonData = {
      start_date: goal.start_date,
      end_date: goal.end_date,
      location_id: location_id,
      sale_goal_type_id: goal.sale_goal_type_id,
      point_of_sale_id: goal.point_of_sale_id,
      group_key: newUuid,
    };
    const removeThousandsSeparator = (value) => {
      if (!value) return '';
      // Verificar si hay más de un punto o si el punto está antes de los tres últimos dígitos
      const parts = value.split('.');

      // Si hay más de dos partes, es probable que los puntos sean separadores de miles
      if (parts.length > 2) {
        return value.replace(/\./g, '');
      }

      // Si hay exactamente dos partes y la primera parte es mayor a 3 dígitos, es probable que los puntos sean separadores de miles
      if (parts.length === 2 && parts[0].length > 3) {
        return value.replace(/\./g, '');
      }

      // Si la primera parte tiene más de tres dígitos y no hay partes decimales, es probable que los puntos sean separadores de miles
      if (parts.length === 1 && parts[0].length > 3) {
        return value.replace(/\./g, '');
      }

      // Verificar el caso específico de "35.000"
      if (
        parts.length === 2 &&
        parts[0].length === 2 &&
        parts[1].length === 3
      ) {
        return value.replace(/\./g, '');
      }

      return value;
    };
    if (!openDrawer.record) {
      let sale_goal = {
        data: {
          ...goal,
          location_id: location_id,
        },
      };
      console.log('sale_goal',sale_goal);
      // Filter and map to create goals
      const goals = orderObjectives
        .filter((element) => sale_goal.data['amount_' + element])
        .map((element) => ({
          data: {
            ...commonData,
            order: element,
            amount: removeThousandsSeparator(
              sale_goal.data['amount_' + element]
            ),
          },
        }));
      // Create sale goals concurrently
      await Promise.all(
        goals.map((goal) => useCreateSaleGoal.mutateAsync(goal))
      );
      setopenDrawer({ visible: false, record: null });
    } else {
      let originalGoal = data.find((g) =>
        g.goals.find((goal) => goal.id == openDrawer.record.id)
      );
      orderObjectives.forEach(async (order, i) => {
        if (goal['amount_' + order]) {
          let findGoal = originalGoal.goals.find((g) => g.order == order);
          if (findGoal) {
            // update goal with patch here
            let updated_goal = {
              id: findGoal.id,
              data: {
                ...commonData,
                order: order,
                amount: removeThousandsSeparator(
                  goal['amount_' + order].toString()
                ),
              },
            };
            await useUpdateSaleGoal.mutateAsync(updated_goal);
          } else {
            // create goal with post here
            let new_goal = {
              data: {
                ...commonData,
                order: order,
                amount: removeThousandsSeparator(goal['amount_' + order]),
              },
            };
            await useCreateSaleGoal.mutateAsync(new_goal);
          }
        }
      });
      setopenDrawer({ visible: false, record: null });
    }
  };
  const handleOpenDrawer = async (visible, record) => {
    reset();
    let point_of_sale_id = await getPointSales();
 let sale_goal_types = await getSalesGoalsTypes();
 setValue('point_of_sale_id', point_of_sale_id[0].id);
 setValue('start_date',dayjs().startOf('month').format('YYYY-MM-DD'))
  setValue('end_date',dayjs().endOf('month').format('YYYY-MM-DD'))
    setFORM(
      FORM.map((item) => {
        if (item.name === 'sale_goal_type_id') {
          return {
            ...item,
            options: sale_goal_types.map((item) => ({
              label: item.name,
              value: item.id,
            })),
          };
        }
        return item;
      })
    );

    setopenDrawer({ visible: visible, record: record });
  };

  const useUpdateSaleGoal = useMutation({
    mutationFn: (sale) => updateSaleGoal(sale.id, sale.data),
    onSuccess: () => {
      openNotification(
        'success',
        t(
          'update_sale_goal_success',
          'Objetivo comercial actualizado correctamente'
        )
      );

      queryClient.invalidateQueries({
        queryKey: ['sales_goals', location_id],
      });
    },
    onError: (error) => formatError(error, openNotification),
  });
  const useCreateSaleGoal = useMutation({
    mutationFn: (sale) => createSaleGoal(sale.data),
    onSuccess: () => {
      openNotification(
        'success',
        t('create_sale_goal_success', 'Objetivo comercial creado correctamente')
      );
      queryClient.invalidateQueries({
        queryKey: ['sales_goals', location_id],
      });
    },
    onError: (error) => formatError(error, openNotification),
  });
  const useDeleteSaleGoal = useMutation({
    mutationFn: (id) => deleteSaleGoal(id),
    onSuccess: () => {
      openNotification(
        'success',
        t(
          'delete_sale_goal_success',
          'Objetivo comercial eliminado correctamente'
        )
      );
      queryClient.invalidateQueries({
        queryKey: ['sales_goals', location_id],
      });
    },
    onError: (error) => {
      formatError(error, openNotification);
    },
  });
  const expandedRowRender = (record) => {
    let goals = [];
    record.goals.forEach((t, i) => {
      goals.push({
        key: t.id,
        id: t.id,
        amount: t.amount,
        order: t.order,
        sale_goal_type_id: record.sale_goal_type_id,
      });
    });

    const columns = [
      {
        title: 'Objetivo',
        dataIndex: 'order',
        key: 'order',
        render: (text) => <p className='text-sm'>Objetivo {text}</p>,
        sorter: (a, b) => a.id - b.id,
      },
      {
        title: 'Monto',
        dataIndex: 'amount',
        key: 'amount',
        render: (text, record) => (
          <p className='text-sm'>
            {record.sale_goal_type_id == 1 ? formatPrice(text) : text}
          </p>
        ),
        sorter: (a, b) => a.id - b.id,
      },
    ];
    return (
      <ConfigProvider
        theme={{
          components: {
            Table: {
              footerColor: '#FFD500',
              footerBg: '#FFD500',
              /* here is your component tokens */
            },
          },
        }}
      >
        <Table
          columns={columns}
          dataSource={goals}
          // tableLayout=''
          pagination={false}
          showHeader={true}
        />
      </ConfigProvider>
    );
  };
  const handleEditRow = async (record) => {
    reset()
    try {
      let sale_goal;
      let point_of_sale_id = await getPointSales();

      setValue('point_of_sale_id', point_of_sale_id[0].id);
      sale_goal = await getSaleGoal(record.id);
      let selectedGoals = data.find((d) => d.group_key === record.group_key);
      FORM.forEach((item) => {
        setValue(item.name, selectedGoals[item.name]);
        selectedGoals.goals.forEach((goal, i) => {
          if (item.name == 'order_' + (i + 1)) {
            setValue(
              item.subfields[0].name,
              selectedGoals.sale_goal_type_id == 1
                ? parseInt(goal.amount)
                : goal.amount
            );
          }
        });
      });

      let sale_goal_types = await getSalesGoalsTypes();
      setFORM(
        FORM.map((item) => {
          if (item.name === 'sale_goal_type_id') {
            return {
              ...item,
              options: sale_goal_types.map((item) => ({
                label: item.name,
                value: item.id,
              })),
            };
          }
          return item;
        })
      );

      setopenDrawer({ visible: true, record: record });
    } catch (error) {
      console.error('Error opening drawer:', error);
    }
  };
  return (
    <>
      <div className='h-screen pl-[1rem] lg:pl-[3rem]   mt-8 gap-9  '>
        <div className='mb-10 flex-grow flex  justify-between mt-2 mr-10'>
          <h2 className='text-2xl font-MessinaSansSemiBold'>{TITLE}</h2>
          <AddItemTable
            onClick={() => handleOpenDrawer(true, null)}
            title={TITLE_SINGULAR}
          />
        </div>
        <div className='w-[100%] mt-[-40px]'>
          <TableCustom
            title={TITLE}
            filterTable={filterTable}
            data={data}
            canCreate={canCreateSaleGoals()}
            canUpdate={canCreateSaleGoals()}
            hideTitle
            multipleTables
            openDrawer={openDrawer}
            setfilterTable={setfilterTable}
            handleOpenDrawer={(visible, record) => {
              if (visible) return;
              setopenDrawer({ visible: visible, record: record });
            }}
            title_singular={TITLE_SINGULAR}
            searchInput={searchInput}
            onSubmit={onSubmit}
            control={control}
            FORM={FORM}
            handleDelete={handleDelete}
            originalColumns={columns}
            handleEditRow={handleEditRow}
            register={register}
            handleSubmit={handleSubmit}
            setsearchInput={setsearchInput}
            expandedRowRender={expandedRowRender}
            isLoading={isLoading}
            mutateLoading={
              useUpdateSaleGoal.isLoading || useCreateSaleGoal.isLoading
            }
          />
          {contextHolder}
        </div>
        {contextHolder}
      </div>
    </>
  );
}
