import React, { useState } from 'react';
import { BiTrash } from 'react-icons/bi';
import { MdCheck, MdOutlineFrontHand } from 'react-icons/md';
import { Tooltip, Popconfirm } from 'antd';
import { formatSuscriptionDaysLeft } from '../../services/membersUtils';
import imgUrl from '../../assets/images/iconios.png';
import { t } from 'i18next';
import { useMediaQuery } from 'react-responsive';

export const ReserveList = ({
  filteredReserves,
  useDelete_member_gym_class,
  usePatch_member_gym_class,
  setModalMemberOpen,
  checkAttendance,
}) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 767 }); // Definir el ancho máximo para dispositivos móviles

  const cancel = (e) => {
    setOpen(false);
  };

  const confirm = async (reserve) => {
    setConfirmLoading(true);
    try {
      const result = await useDelete_member_gym_class.mutateAsync(reserve);
      console.log('result', result);
    } catch (error) {
      console.log('error', error);
    }
    setConfirmLoading(false);
    setOpen(false);
    // setConfirmLoading(true);
  };

  return filteredReserves.map((reserve, i) => {
    if (!reserve) return;
    if (!reserve.subscription) return;

    return (
      <div
        key={i}
        className={` px-5 py-5 border-b-[1px]  last:border-none last:pb-7 border-b-primaryGrey flex flex-row items-center hover:cursor-pointer hover:bg-primaryGrey smooth-t ${
          reserve?.subscription?.owner_type == 'Prospect' && 'bg-lightGreen'
        }`}
        onClick={() =>
          setModalMemberOpen({
            id: reserve?.subscription?.owner_id,
            isProspect:
              reserve?.subscription?.owner_type == 'Member' ? false : true,
              customer_type: reserve?.subscription?.owner_type,
          })
        }
      >
        <img
          className='h-12 w-12 rounded-full object-cover'
          onError={(e) => {
            e.target.src = imgUrl;
          }}
          src={`https://bigg-app-profile-prod.s3.amazonaws.com/new_profile_pictures/${reserve?.subscription?.owner_id}`}
          alt='description of image'
        />
        {/* <div className='h-12 w-12 bg-white rounded-full'></div> */}
        <div className='mx-4'>
          <p
            className={`text-md lg:text-lg ${
              reserve?.subscription?.owner_type == 'Prospect' &&
              'text-darkGreen'
            }`}
          >
            {reserve?.subscription?.owner.first_name +
              ' ' +
              reserve?.subscription?.owner.last_name}
          </p>
          <p
            className={`text-xs  ${
              reserve?.subscription?.owner_type == 'Prospect'
                ? 'text-darkGreen'
                : 'text-secondaryGrey'
            } desktop`}
          >
            N° {reserve?.subscription?.owner_id} |{' '}
            {reserve?.subscription?.location_name}
          </p>
        </div>
        {/* {reserve?.subscription.membership.product.parent_product_id == 29 && (
          <Tooltip title={t('free_product', 'Producto Free')}>
            <div className=' bg-darkGreen h-6 w-6  flex items-center font-MessinaSansSemiBold text-sm text-black justify-center rounded-full'>
              F
            </div>{' '}
          </Tooltip>
        )} */}
         {reserve?.reserves_count<= 15 && (
          <Tooltip title={t('bigginner_reserve', 'Este socio es BIGGinner por contar con menos de 15 reservas')}>
            <div className='ml-2 bg-primaryRed h-6 w-6  flex items-center font-MessinaSansSemiBold text-sm text-black justify-center rounded-full'>
              B
            </div>{' '}
          </Tooltip>
        )}
        {reserve?.subscription?.owner_type == 'Member' && (
          <div className='ml-5 flex gap-2 desktop'>
            <Tooltip title={t('product.sessions_left', 'Sesiones restantes')}>
              <span className='font-MessinaSansSemiBold '>
                {reserve?.subscription?.sessions_left}
              </span>
            </Tooltip>
            <span>/</span>
            <Tooltip title={t('days_left', 'Días restantes')}>
              <span className='font-MessinaSansSemiBold'>
                {formatSuscriptionDaysLeft(
                  reserve?.subscription?.started_at,
                  reserve?.subscription?.days_left,
                  reserve?.subscription?.owner_id
                )}
              </span>
            </Tooltip>
          </div>
        )}

        {reserve?.subscription?.owner_type == 'Prospect' && (
          <div className=' border-darkGreen border-[1px] rounded-full px-3 py-2'>
            <span className=' font-MessinaSansSemiBold text-darkGreen text-md uppercase'>
              {t('cdp', 'clase de prueba')}
            </span>
          </div>
        )}
        <div className='flex flex-row gap-3 ml-auto'>
          <div
            onClick={(e) => {
              e.stopPropagation();
              usePatch_member_gym_class.mutate(reserve);
            }}
            className={`rounded-full  ${
              reserve?.has_attended
                ? 'border-darkGreen'
                : reserve?.subscription?.owner_type == 'Prospect'
                ? 'border-primaryGrey'
                : 'border-secondaryGrey'
            } border-[1px] w-fit h-fit p-2  cursor-pointer hover:opacity-60 ease-in-out duration-200`}
          >
            <Tooltip
              title={`${
                !reserve?.has_attended
                  ? t('mark_present', 'Marcar presente')
                  : t('mark_absent', 'Marcar ausente')
              }`}
            >
              {reserve?.has_attended ? (
                <MdCheck size={isMobile ? 15: 25} color='#1EA05A' />
              ) : (
                <MdOutlineFrontHand
                  size={isMobile ? 15: 25}
                  color={
                    reserve?.subscription?.owner_type == 'Prospect'
                      ? '#444444'
                      : '#CCCCCC'
                  }
                />
              )}
            </Tooltip>
          </div>
          <div
            onClick={(e) => e.stopPropagation()}
            className=' rounded-full  border-primaryRed border-[1px] w-fit h-fit p-2 cursor-pointer hover:opacity-60 ease-in-out duration-200'
          >
            {/* <Tooltip title="Cancelar reserva"> */}
            <Popconfirm
              title={t(
                'reserves.delete_title',
                '¿Estás seguro de cancelar la reserva?'
              )}
              description={t(
                'reserves.delete_description',
                'Se agregará una sesión al socio.'
              )}
              onConfirm={() => confirm(reserve)}
              onCancel={cancel}
              okText={t('accept', 'Aceptar')}
              open={open == reserve.id}
              cancelText={t('cancel', 'Cancelar')}
              okButtonProps={{
                loading: confirmLoading,
                className:
                  'bg-primaryRed text-white rounded-md px-3 py-1 hover:bg-primaryRed ',
              }}
            >
              <BiTrash
                                size={isMobile ? 15: 25}

                color='#ED4C5C'
                onClick={() => setOpen(reserve.id)}
              />
              {/* onClick={()=>setOpen(true)} */}
            </Popconfirm>
            {/* </Tooltip> */}
          </div>
        </div>
      </div>
    );
  });
};
