import React ,{useEffect,useState}from 'react'
import {AiOutlineDown} from 'react-icons/ai';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthProvider';
import { useGlobalContext } from '../../context/GlobalState';
import { getLocationCountry, getLocationId } from '../../services/utils/getLocation';
import { useQueryClient } from '@tanstack/react-query';
export default function Locations({setIsLocationOpen,setisHoveredLocation,isLocationOpen,isHoveredLocation}) {
  const { user,changeLocation } = useAuth();
  const {state,dispatch} = useGlobalContext()
  const data = useLoaderData();
  const queryClient = useQueryClient();
  const location_id = getLocationId();
const [searchLocation, setsearchLocation] = useState('')
// console.log('user',user);
  useEffect(() => {
    if(state.locations.length == 0 && data.locations?.length >0){
      dispatch({ type: 'SET_LOCATIONS',payload:{
        reduceByCountry:data.locations,
        availableLocations:data.available_locations
      } })
    }
    }, [state,data])

  const navigate = useNavigate()
const invalidateAllCharts = () => {
  queryClient.invalidateQueries({
    queryKey: ['new_accounts', location_id],
  })
  queryClient.invalidateQueries({
    queryKey: ['dashboard_sales', location_id],
  })
  queryClient.invalidateQueries({
    queryKey: ['pxq', location_id],
  })
  queryClient.invalidateQueries({
    queryKey: ['dashboard_crm', location_id],
  }) 
  queryClient.invalidateQueries({
    queryKey: ['active_members', location_id],
  })
  queryClient.invalidateQueries({
    queryKey: ['members_evolution', location_id],
  })
  queryClient.invalidateQueries({
    queryKey: ['dashboard_conversion_lead_cdp', location_id],
  })
  queryClient.invalidateQueries({
    queryKey: ['dashboard_conversion_2b', location_id],
  })
 
  queryClient.invalidateQueries({
    queryKey: ['dashboard_renews', location_id],
  })
  queryClient.invalidateQueries({
    queryKey: ['point_of_sales', location_id],
  })
}
if(!user) return null
  return (
    <div
    className='flex flex-row gap-2  items-center relative location-nav cursor-pointer hover:bg-primaryGrey rounded-md p-2 smooth-t min-w-[20%] justify-between'
    onClick={(e) => {
      setIsLocationOpen(!isLocationOpen);
    }}
    onMouseLeave={(e) => {
      e.stopPropagation();

      setIsLocationOpen(false);
      setisHoveredLocation(false);
    }}
    onMouseEnter={() => setisHoveredLocation(true)}
  >
    <h1 className='text-lg lg:text-3xl font-MessinaSansBold  whitespace-nowrap'>{user?.location_name}</h1>
    <AiOutlineDown
      size={15}
      className={`${
        isHoveredLocation && 'transform -rotate-90'
      } smooth-t`}
    />
    <div
    onClick={(e) => {
      e.stopPropagation();
    }}
      className={`
    ${!isLocationOpen && 'opacity-0 pointer-events-none'}
    absolute top-[100%] py-4 bg-black z-50 w-fit left-0 rounded-md location-select overflow-y-scroll max-h-[20rem] border-[2px] border-primaryGrey `}
    >
      {data.locations.map((item, index) => (
        <div key={index} className='py-2 px-4 '>
          <div className='w-full'>
            {index == 0 && (
              <input value={searchLocation} onChange={(e)=>setsearchLocation(e.target.value)} placeholder='Filtrar'  type='search' className=' bg-primaryDark rounded-md outline-none py-1 w-[95%] mb-2 px-2'  />
            )}
            <p className=' text-primaryGrey text-sm uppercase font-MessinaSansBold'>
              {item.country}
            </p>
            {item.locations.filter((location)=>location.name.toLowerCase().includes(searchLocation.toLowerCase())).
            map((location, index) => (
              <button
                className='hover:cursor-pointer py-2  px-2 searchbox-item smooth-t w-[100%] rounded-md last:border-none  text-left'
                key={index}
                onClick={() => {
                  // const country = getLocationCountry(state.locations,location.id)
                  // console.log('location',country);
                  invalidateAllCharts()
                  navigate(0)
                  changeLocation(location)
                }}
              >
                {location.name}
              </button>
            ))}
          </div>
        </div>
      ))}
    </div>
  </div>
  )
}
