import dayjs from 'dayjs';
export const formatSuscriptionDaysLeft = (started_at, days_left) => {
  const startedAt = dayjs(started_at);
  const today = dayjs();
  const days = today.diff(startedAt, 'days');
  if (days < 0) return days_left;
  const daysLeft = days_left - days;
  return daysLeft;
};
export const isValidSuscription = (
  started_at,
  days_left,
  sessions_left,
  parent_product_id
) => {
  const daysLeft = formatSuscriptionDaysLeft(started_at, days_left);
  // console.log(daysLeft, sessions_left);
  return (
    (daysLeft > 0 && sessions_left > 0) ||
    (parent_product_id == 32 && daysLeft > 0) || //matricula product
    (parent_product_id == 33 && daysLeft >= 0) //cdp product
  );
};

export const validateEmail = (email) => {
  const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return isValidEmail.test(email);
};
export const formatExtraInfo = (extraInfo) => {
  if (!extraInfo) return null;
  try {
    // Reemplazar => con : y nil con null
    const formattedString = extraInfo
      .replace(/=>/g, ':')
      .replace(/nil/g, 'null');
    // Parsear el JSON
    return JSON.parse(formattedString);
  } catch (error) {
    console.error('Error parsing extra_info:', error);
    return null;
  }
};
