import React, { useState, useEffect,useRef } from 'react';
import PersonalData from '../components/ModalMembers/PersonalData';
import Header from '../components/ModalMembers/Header';
import ProductsMember from '../components/ModalMembers/ProductsMember';
import ToDoList from '../components/ToDoList';
import TrainingInfoMember from '../components/ModalMembers/TrainingInfoMember';
import ModalCRM from '../components/ModalMembers/ModalCRM';
import ModalSales from '../components/Desktop/ModalSales';
import { useMediaQuery } from 'react-responsive';
import { getMemberData, updateMember } from '../api/members';
import Drawer from '../components/helpers/Drawer';
import { formatError } from '../services/utils/formatError';
import { notification } from 'antd';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useCRMOptions } from '../services/utils/CRM_OPTIONS';
import {
  createProspect,
  updateProspect,
  useGetDischargeReasons,
} from '../api/prospects';
import ModalDebit from '../components/ModalMembers/ModalDebit';
import {  USE_PROSPECT_COMMON_FIELDS } from '../services/Constants/FORMS';
import { openNotificationFields } from '../services/utils/openNotificationFields';
import { useGetPointOfSales } from '../api/locations';
import ModalCC from '../components/ModalMembers/ModalCC';
import ModalReturn from '../components/ModalMembers/ModalReturn';
import { ModalConfirmCoach } from '../components/ModalMembers/ModalConfirmCoach';
import { createCoachHistories } from '../api/coaches';
import { useTranslation } from 'react-i18next';
import ModalCreditCard from '../components/ModalMembers/ModalCreditCard';
import { customerIoIdentify } from '../services/utils/CustomerIoUtils';
import { track_modify_cdpCoach, track_modify_discharge_reason_id, track_modify_emailUser, track_modify_locationUser } from '../services/utils/CustomerEvents';
import { useGetPaymentPlatforms } from '../api/mp';
import { getLocationById, getLocationId } from '../services/utils/getLocation';
import CRM from '../components/ModalMembers/CRM';
 import * as Sentry from '@sentry/react';
import { useGetDocumentTypes } from '../api/users';
import { useGlobalContext } from '../context/GlobalState';
export default function ModalMember({ modalMemberOpen, setModalMemberOpen }) {
  if (modalMemberOpen.id == null) return null;
  const { t, ready } = useTranslation();
const {state}  = useGlobalContext()
  if (!ready) return null;
  const queryClient = useQueryClient();
  const [id, setid] = useState(modalMemberOpen.id); // id of member or prospect
  const [isProspect, setisProspect] = useState(
    modalMemberOpen.isProspect ? true : false
  );
  const [customer_type, setcustomer_type] = useState(modalMemberOpen.customer_type);
  const {data:paymentPlatform,isLoading:loadingPlatforms}= useGetPaymentPlatforms(getLocationId());

  const CRM_OPTIONS = useCRMOptions();
  const isMobile = useMediaQuery({ maxWidth: 767 }); // Definir el ancho máximo para dispositivos móviles
  const drawerWidth = isMobile ? '100%' : '85%'; // Determinar el ancho del Drawer según el dispositivo
  const [menu_selected, setMenu_selected] = useState(t('header_member.general', 'General'));
  const [modalSale, setmodalSale] = useState(false);
  const [openCRM, setOpenCRM] = useState(false);
  const [openDebits, setopenDebits] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [FORM_MEMBER, setFORM_MEMBER] = useState(false);
  const [openCC, setopenCC] = useState(false);
  const [openCreditCard, setCreditCard] = useState(false);

  const [openReturn, setopenReturn] = useState(false);
  const { data: dischargeReasons, isLoading: isLoadingDischarge } =
    useGetDischargeReasons();
    const reasonSelectedRef = useRef();
  const [
    { data: member, isLoading },
    {
      isLoading: isLoadingCRM,
      isError: isErrorCrm,
      data: crm_list,
      error: errorCrm,
    },
    {
      data:sales,
      isLoading: isLoadingSales,
    },
    {
      data:coachHistories,
      isLoading: isLoadingCoachHistories,
    }
  ] = getMemberData(isProspect, id,customer_type);
  const { data: point_of_sales, isLoading: isLoadingPoints } =
    useGetPointOfSales();
    const { data: document_types, isLoading: isLoadingDocument_types } =
    useGetDocumentTypes();
  useEffect(() => {
    if (member && member?.member_id && isProspect) {
      setisProspect(false);
      setid(member.member_id);
    }
  }, [member]);

  const handleOpenCRM = (visible,action) => {
    setOpenCRM({ visible: !openCRM.visible,action:action });
  };
  const handleOpenDebit = () => {
    setopenDebits(!openDebits);
  };
  const handleOpenCC = (cc) => {
    if (!openCC) {
      setopenCC(cc);
      return;
    }
    setopenCC(false);
  };
   const handleOpenCreditCard = (cc) => {
    if (!openCreditCard) {
      setCreditCard(cc);
      return;
    }
    setCreditCard(false);
  };
  const handleOpenReturn = (sale) => {
    if (!openReturn) {
      setopenReturn(sale);
      return;
    }
    setopenReturn(false);
  };
  const useCreateHistory = useMutation({
    mutationFn: (data) => createCoachHistories(data),
    onSuccess: (data) => {
      reasonSelectedRef.current = null;
      openNotification('success',  t('coach_success','Coach actualizado correctamente'));
    },
    onError: (error) => {
      reasonSelectedRef.current = null;
      console.log('error', error);
      formatError(error, openNotification);
    },
  });
  const openNotification = (type, description, title) => {
    api[type]({
      message: title
        ? title
        : type == 'success'
        ? isProspect ?t('modal_prospect.success',`Prospecto actualizado correctamente`):  t('modal_member.success',`Socio actualizado correctamente`)
        : isProspect ?t('modal_prospect.error',`Error al actualizar prospecto`):  t('modal_member.error',`Error al actualizar socio`),
      ...openNotificationFields(type, description),
    
    });
  };
  const menuComponents = {
    [t('header_member.general', 'General')]: (
      <CRM
        openCRM={openCRM}
        crm_list={crm_list}
        coachHistories={coachHistories || []}
        isLoading={isLoadingCRM}
        member_id={member?.id}
        handleOpenCRM={handleOpenCRM}
        CRM_OPTIONS={CRM_OPTIONS}
        sales={sales || []}
        handleOpenReturn={handleOpenReturn}

      />
    ),
    [t('products', 'Productos')]: (
      <ProductsMember
        debits={member?.debits}
        setopenCC={handleOpenCC}
        paymentPlatform={paymentPlatform}
        products={member?.subscriptions}
        handleOpenCreditCard={handleOpenCreditCard}
        customer_id={member?.id}
        handleOpenDebit={handleOpenDebit}
        handleOpenReturn={handleOpenReturn}
        setmodalSale={setmodalSale}
        isProspect={isProspect}
        openNotification={openNotification}
        dischargeReasons={dischargeReasons}
        user_id={member?.user_id}
        member_name={`${member?.first_name} ${member?.last_name}`}
        api={api}
      />
    ),
    [t('training', 'Entrenamiento')]: (
      <TrainingInfoMember
        id={member?.id}
        customer_location_id={member?.location_id}
        isProspect={isProspect}
        name={`${member?.first_name} ${member?.last_name}`}
      />
    ),
    [t('tasks', 'Tareas')]: (
      <ToDoList
        setOpenCRM={(visible, type) => setOpenCRM({ visible, type })}
        customer_id={member?.id}
      />
    ),
  };
  const handleSave = async (data, prospect_data) => {
    let appendProspectData = { ...data, ...prospect_data };
    data.id = data.user_id;
    let member_data = member;
    if(data.coach_id !== member_data.coach_id){
      try {
        customerIoIdentify(member_data.member_data,member.email);
        track_modify_cdpCoach(appendProspectData)
      } catch (error) {
        Sentry.captureException(new Error(error.message), { 
          extra: { 
            error: error.message, 
            user_id: member.user_id,
            email: member.email,
            appendProspectData,
            screen: 'ModalMember'
          } 
        });
      }
    }
    if ((data.coach_id !== member.coach_id) && !isProspect && member.coach_id !== 1) {
      const confirmResult =  await ModalConfirmCoach(
        member.coach.first_name + ' ' + member.coach.last_name,
        {...data.coach,id:data.coach_id},
        dischargeReasons.filter((t) => t.discharge_reason_type_id == 10),
        reasonSelectedRef,
        openNotification,
        member.id,
        useCreateHistory
      );
    
    }
   
    if (isProspect) { // is only prospect
      await useUpdateProspect.mutateAsync({
        id: member.id,
        data: appendProspectData,
      });
    } else if (member.prospect_id) { // is member and has prospect
      await Promise.all([
        useUpdateProspect.mutateAsync({
          id: member.prospect_id,
          data: appendProspectData,
        }),
        useUpdateMember.mutateAsync({ id: member.id, data }),
      ]);
 
    }
    if(appendProspectData.user_attributes.email !== member_data.email){ // log event to email change
try {
    customerIoIdentify(appendProspectData.user_id,member_data.email);
  track_modify_emailUser(appendProspectData);
} catch (error) {
  Sentry.captureException(new Error(error.message), { 
    extra: { 
      error: error.message, 
      user_id: member_data.user_id,
      email: member_data.email,
      appendProspectData,
      screen: 'email'
    } 
  });
}
    }
    if(appendProspectData.location_id !== member_data.location_id){ // log event to location change
   try {
    customerIoIdentify(member_data.user_id,member_data.email);
    track_modify_locationUser({
      ...appendProspectData,
      location: getLocationById(
        state.locations,
        appendProspectData.location_id)
    }) || {}
   } catch (error) {
    Sentry.captureException(new Error(error.message), { 
      extra: { 
        error: error.message, 
        user_id: member_data.user_id,
        email: member_data.email,
        appendProspectData,
        screen: 'location'
      }
   } );
    } 
    }
    if(!member_data.discharge_reason_id && appendProspectData.discharge_reason_id){
      try {
        customerIoIdentify(member_data.user_id,member_data.email);
        track_modify_discharge_reason_id(appendProspectData)
      } catch (error) {
        Sentry.captureException(new Error(error.message), { 
          extra: { 
            error: error.message, 
            user_id: member_data.user_id,
            email: member_data.email,
            appendProspectData,
            screen: 'discharge_reason_id'
          } 
        });
      }
    }
    if(modalMemberOpen?.extra?.date_selected){
      queryClient.invalidateQueries({
        queryKey: [
          'day_reserves_trial',
          modalMemberOpen?.extra?.date_selected,
        ],
      });
    }
    //  else {
    //   //POST PROSPECT
    //   let hasProspectData =
    //    PROSPECT_COMMON_FIELDS.find((field) => {
    //     if (FORM_MEMBER[field.name]) {
    //       return true;
    //     }
    //     return false;
    //   });
    //   if (hasProspectData) {
    //     const createProspect = {
    //       ...prospect_data,
    //       user_id: member.user_id,
    //       point_of_sale_id: point_of_sales?.[0]?.id,
    //       location_id: member.location_id,
    //     };
    //     useCreateProspect.mutate({ data: createProspect });
    //   }
    // }
  };
  const useUpdateMember = useMutation({
    mutationFn: (member) => updateMember(member.id, member.data),
    onSuccess: (data) => {
      openNotification('success');
      queryClient.invalidateQueries({
        queryKey: ['profile', member.id],
      });
    },
    onError: (error) => formatError(error, openNotification),
  });
  const useCreateProspect = useMutation({
    mutationFn: (prospect) => createProspect(prospect.data),
    onSuccess: (data) => {
      // openNotification('success');
      // queryClient.invalidateQueries({
      //   queryKey: ['prospect',member.id],
      // })
    },
    onError: (error) => formatError(error, openNotification),
  });
  const useUpdateProspect = useMutation({
    mutationFn: (prospect) => updateProspect(prospect.id, prospect.data),
    onSuccess: (data) => {
      if (isProspect) {
        openNotification('success');
        queryClient.invalidateQueries({
          queryKey: ['prospect', member.id],
        });
      }
    },
    onError: (error) => formatError(error, openNotification),
  });
  const converToMember = async (data, isNowProspect) => {
    setisProspect(isNowProspect);
    if (!isNowProspect) {
      setid(data.owner_id);
    }
  };
  return (
    <Drawer
      width={drawerWidth}
      openDrawer={modalMemberOpen.id}
      setopenDrawer={setModalMemberOpen}
    >
      <div className=' relative min-h-[100vh] z-20 overflow-x-hidden'>
        <Header
          openNotification={openNotification}
          customer_type={customer_type}
          menu_selected={menu_selected}
          setMenu_selected={setMenu_selected}
          setModalMemberOpen={setModalMemberOpen}
          handleSaveMember={handleSave}
          isLoading={
            useUpdateMember.isLoading ||
            useUpdateProspect.isLoading 
            // ||
            // useCreateProspect.isLoading
          }
          FORM_MEMBER={FORM_MEMBER}
          member={member}
          isProspect={isProspect}
        />
        <div className={`flex lg:flex-row flex-col justify-between `}>
          <PersonalData
          customer_type={customer_type}
            dischargeReasons={dischargeReasons}
            isLoadingDischarge={isLoadingDischarge}
            openNotification={openNotification}
            member={member}
            // prospect={}
            isLoading={isLoading}
            FORM_MEMBER={FORM_MEMBER}
            setmodalSale={setmodalSale}
            setFORM_MEMBER={setFORM_MEMBER}
            isProspect={isProspect}
            document_types={document_types}
            isLoadingDocument_types={isLoadingDocument_types}
          />
          <div className='flex flex-column lg:pl-5 w-[100%]  lg:w-[70%] h-[100%] bg-red  lg:ml-7'>
            {menuComponents[menu_selected]}
          </div>
        </div>
        {member?.id && (
          <>
            <ModalDebit
            paymentPlatform={paymentPlatform}
              openNotification={openNotification}
              visible={openDebits}
              setClose={handleOpenDebit}
              member_id={member?.id}
              user_email={member?.email}
              user_id={member?.user_id}
              member_name = {`${member?.first_name} ${member?.last_name}`}
              document_types={document_types}
            />
            <ModalCC
              openNotification={openNotification}
              sale={openCC}
              setClose={handleOpenCC}
              member_id={member?.id}
              owner_user_id={member.user_id}
              owner_email={member.email}
              owner_type={isProspect ? 'Prospect' : 'Member'}
              document_types={document_types}
              member_name={`${member?.first_name} ${member?.last_name}`}
              api={api}
            />
            <ModalReturn
              openNotification={openNotification}
              sale={openReturn}
              setClose={handleOpenReturn}
              dischargeReasons={dischargeReasons}
            />
            <ModalCreditCard 
              openNotification={openNotification}
              open={openCreditCard}
              member={member}
              handleSaveMember={handleSave}
              paymentPlatform={paymentPlatform}
              setClose={handleOpenCreditCard}
              document_types={document_types}
            />
            <ModalSales
              openNotification={openNotification}
              owner_type={isProspect ? 'Prospect' : 'Member'}
              modalSale={modalSale}
              setmodalSale={setmodalSale}
              setisProspect={converToMember}
              id={member?.id}
              owner_user_id={member.user_id}
              owner_email={member.email}
              referrer_id={member.referrer_id || null}
            />
            <ModalCRM
              openNotification={openNotification}
              visible={openCRM.visible}
              CRM_OPTIONS={CRM_OPTIONS}
              handleOpenCRM={handleOpenCRM}
              member={member}
              type={openCRM?.type}
              isProspect={isProspect}
              editing={openCRM?.action}
            />
          </>
        )}

        {contextHolder}
      </div>
    </Drawer>
  );
}
