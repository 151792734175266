import { fetchData } from '../services/utils/axiosInstance';
import { getLocationId } from '../services/utils/getLocation';
import { useQuery } from '@tanstack/react-query';

export const getSalesGoalsTypes = async (start, end) => {
  const location_id = getLocationId();
  let q = `/sale_goal_types?location_id=${location_id}`;
  if (start && end) q += `&start=${start}&end=${end}`;
  return await fetchData(q);
};
export const useGetSalesGoalsTypes = (start, end) => {
  const location_id = getLocationId();

  return useQuery({
    queryKey: ['sales_goals_types', location_id],
    queryFn: async () => getSalesGoalsTypes(start, end),
  });
};
export const getSalesGoals = async (start, end) => {
  const location_id = getLocationId();
  let q = `/sale_goals?location_id=${location_id}`;
  if (start && end) q += `&start=${start}&end=${end}`;
  return await fetchData(q);
};
export const useGetSalesGoals = (start, end) => {
  const location_id = getLocationId();

  return useQuery({
    queryKey: ['sales_goals', location_id],
    queryFn: async () => getSalesGoals(start, end),
    select: (data) =>
      Object.values(
        data
          .sort((a, b) => a.order > b.order)
          .reduce((acc, item) => {
            const key = item.group_key;
            item.amount = parseInt(item.amount);
            if (!acc[key]) {
              acc[key] = {
                ...item,
                goals: [],
                key: item.id,
              };
            }
            acc[key].goals.push(item);
            return acc;
          }, {})
      ),
  });
};
export const getSaleGoalType = async (id) => {
  const location_id = getLocationId();
  return await fetchData(`/sale_goal_types/${id}?location_id=${location_id}`);
};
export const getSaleGoal = async (id) => {
  const location_id = getLocationId();
  return await fetchData(`/sale_goals/${id}?location_id=${location_id}`);
};
export const getSaleGroupGoal = async (group_key) => {
  const location_id = getLocationId();
  return await fetchData(
    `/sale_goals/?group_key=${group_key}&location_id=${location_id}`
  );
};
export const createSaleGoalType = async (data) => {
  const location_id = getLocationId();
  return await fetchData(
    `/sale_goal_types?location_id=${location_id}`,
    'POST',
    data
  );
};
export const deleteSaleGoalType = async (id) => {
  const location_id = getLocationId();
  return await fetchData(
    `/sale_goal_types/${id}?location_id=${location_id}`,
    'DELETE'
  );
};
export const updateSaleGoalType = async (id, data) => {
  const location_id = getLocationId();
  return await fetchData(
    `/sale_goal_types/${id}?location_id=${location_id}`,
    'PATCH',
    data
  );
};

export const createSaleGoal = async (data) => {
  const location_id = getLocationId();
  return await fetchData(
    `/sale_goals?location_id=${location_id}`,
    'POST',
    data
  );
};
export const deleteSaleGoal = async (id) => {
  const location_id = getLocationId();
  return await fetchData(
    `/sale_goals/${id}?location_id=${location_id}`,
    'DELETE'
  );
};
export const updateSaleGoal = async (id, data) => {
  const location_id = getLocationId();
  return await fetchData(
    `/sale_goals/${id}?location_id=${location_id}`,
    'PATCH',
    data
  );
};
