import React, { useMemo } from 'react';
import SpinIndicador from '../SpinIndicador';
import { t } from 'i18next';

export default function PopActiveMembers({ data ,member_type}) {

  if (!data) return <SpinIndicador />;
console.log('data',data);
  // 4. Mapear sobre la lista ordenada para renderizar
  return (
    <div className='flex flex-col p-0 m-0'>
      {data.sort((a,b)=>a.members < b.members).
      filter((m)=> member_type == 'Free'? m.member_type =='Free':  m.member_type !=='Free').map((item, i,arr) => (
        <div
          key={item.member_type}
          className={`${
            i < arr.length - 1
              ? 'border-b-primaryGrey border-b-[1px] py-2 '
              : 'border-b-transparent pt-2'
          }  flex flex-row justify-between`}
        >
          <p className='text-white mr-4'>
            {(
              item.member_type === 'Free'
                ? 'Free'
                : item.member_type === 'monthly'
                ? t('monthly','Mensual')
                : item.member_type === 'recurrent'
                ? t('recurrent','Cobro recurrente')
                : item.member_type == 'weekly'?
                t('weekly','Semanal'):
                 
                
                t('extended','Anticipado')
            ) +
              ` (Tasa ${
                (
                  (item.members /
                    [
                      ...(item.member_type === 'Free' ? data : arr)
                    ].reduce((acc, item) => acc + item.members, 0)) *
                  100
                ).toFixed(0)
              }%)`}
          </p>

          <p className='text-white font-DrukWideMedium'>{item.members}</p>
        </div>
      ))}
    </div>
  );
}
