import React, { useState } from 'react';
import AddButton from '../helpers/AddButton';
import CardRounded from '../CardRounded';
import { deleteMemberCreditCard, pay_by_link_tokens, useMemberCreditCards } from '../../api/mp';
import { t } from 'i18next';
import { BiTrash } from 'react-icons/bi';
import { MdOutlineModeEditOutline } from 'react-icons/md';
import { CiLink } from 'react-icons/ci';

import { formatExtraInfo } from '../../services/membersUtils';
import { Tooltip, Popconfirm, Tag,Dropdown, notification, Space,Button,message } from 'antd';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getLocationId, getLocationName } from '../../services/utils/getLocation';
import { useAuth } from '../../context/AuthProvider';
import { openLinkNotification } from '../../services/utils/openNotificationFields';
import { closeMessageApi, loadingLink } from './MessageApi';


export default function MemberCreditCards({
  handleOpenCreditCard,
  user_id,
  member_name,
  openNotification
}) {
  const {user} =  useAuth();


  const { data: credit_cards, isLoading } = useMemberCreditCards(user_id);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [messageApi, contextHolderMessage] = message.useMessage();

  const queryClient = useQueryClient();
  const cancel = (e) => {
    setOpen(false);
  };
   

    const handleLink = async (id) => {
      loadingLink(messageApi);
      try {
        const createLink = await pay_by_link_tokens({
          location_id: getLocationId(),
          expiration_minutes: 0,
          token: user.single_access_token,
          user_id: user.user_id,
        });
     messageApi.destroy();
        // Aquí creas el link para compartir
        const linkToCopy = `https://gym.bigg.fit/mp?location_id=${getLocationId()}&owner_user_id=${user_id}&card_upload=true&uuid=${createLink.uuid}`;
        // Muestra la notificación de éxito si todo fue bien
        openLinkNotification('success', `Compartile este link a ${member_name} para que pueda cargar su método de pago`, 'Link generado con exito',linkToCopy,api,closeMessageApi,messageApi);
      } catch (error) {
        openLinkNotification('error', 'Hubo un problema al copiar el link al portapapeles. Por favor, intentalo nuevamente.',api,closeMessageApi);
      }
    };
    

  const confirm = async (id) => {
    setConfirmLoading(true);
    try {
      await useDeleteCreditCard.mutateAsync(id);
    } catch (error) {
      console.log('error', error);
    }
    setConfirmLoading(false);
    setOpen(false);
  };
  const useDeleteCreditCard = useMutation({
    mutationFn: (card_id) => deleteMemberCreditCard(card_id,{deleted:true}),
    onSuccess: async (data) => {
      openNotification('success', 'Tarjeta eliminada con exito','Socio actualizado correctamente',);
      //  console.log('data',data,member);
      queryClient.invalidateQueries({
        queryKey: ['credit_cards', user_id],
      });
    },
    onError: (error, variables) => {
      let errorParse = JSON.parse(error.message);
      openNotification('error',Object.keys(errorParse).map((key) => `${key} : ${errorParse[key]}`), 'Hubo un problema al eliminar la tarjeta');
    },
  });
  return (
    <div className='mb-10'>
      
      <div className='flex flex-row items-center justify-between mb-4 mr-1'>
        <h3 className='font-MessinaSansSemiBold text-xl pb-4'>
          {/* {t('cc_title','Cuenta corriente')} */}
     {t('payment_methods_title', 'Métodos de pago')}
        </h3>
        <div className='flex flex-row gap-4 z-50'>
        <AddButton
          title={'Nueva tarjeta'}
          greenColor
          onClick={() => handleOpenCreditCard(true)}
        />
   <AddButton
          title={'Generar link de carga'}
          icon={<CiLink size={20}  color='#111' />}
          bg={'bg-white'}
          onClick={handleLink}
        />
        </div>
      </div>
      <CardRounded>
        {!isLoading && credit_cards?.length === 0 && (
          <h3 className='py-7 mx-5'>
            {t(
              'no_creditCards_in',
              'Este socio no posee ningun metodo de pago asociado en'
            ) +' '+ getLocationName()}
          </h3>
        )}
        {credit_cards?.map((cc) => (
          <div
            key={cc.id}
            className=' border-b-[1px] border-b-primaryGrey last:border-b-transparent  mx-5 py-5 flex flex-row items-center justify-between '
          >
            <div className='flex flex-row items-center  gap-5 w-full '>
            {! formatExtraInfo(cc.extra_info) ? (
              <span>ID:{cc.id}</span>
            ): <img
            width={50}
            height={50}
            // style={{objectFit:'cover'}}
            src={
              formatExtraInfo(cc.extra_info)?.payment_method?.secure_thumbnail
            }
            alt='American_express'
          />}
             
              <div>
                <div className='flex flex-row gap-5 item-center mb-2'>
                  <p className='text-xl font-MessinaSansSemiBold capitalize'>
                    {cc.card_brand}
                  </p>
                  <p>
                    {' '}
                    {formatExtraInfo(cc.extra_info)?.first_six_digits}****{' '}
                    {cc.last_four}
                  </p>
                </div>
                <p className='text-md font-MessinaSansSemiBold'>
                  Vence {cc.expiration_date}
                </p>
              </div>
            </div>
            {cc.preferred && (
              <Tag className='mr-5' color={cc.preferred && 'green'}>
                {' '}
                {cc.preferred && 'Preferida'}
              </Tag>
            )}
                <Tooltip title={t('edit_card', 'Editar Metodo de pago')}>
                  <Dropdown
                    menu={{
                      items: [
                        {
                          label: <p
                          className={`${cc.preferred && 'text-primaryRed '}font-MessinaSansSemiBold cursor-pointer hover:opacity-60 ease-in-out duration-200`}
                          >{
                            cc.preferred ? t('unmark_as_preferred', 'Desmarcar como preferida') : t('mark_as_preferred', 'Marcar como preferida')
                            }</p>,
                          key: 'edit_start',
                        },
                      ],
                      onClick: async ({ key }) => {
                        // showPromiseConfirm(key, debit);
                      },
                    }}
                    trigger={['click']}
                    className=''
                  >
                    <div className='rounded-full  border-primaryGray border-[1px] p-1 cursor-pointer hover:opacity-60 ease-in-out duration-200  mr-3'>
                      <MdOutlineModeEditOutline size={18} color='#ccc' />
                    </div>
                  </Dropdown>
                </Tooltip>
            <div
              onClick={(e) => e.stopPropagation()}
              className='rounded-full  border-primaryRed border-[1px] p-1 cursor-pointer hover:opacity-60 ease-in-out duration-200 '
            >
              <Tooltip title={t('payments.delete', 'Eliminar método de pago')}>
                <Popconfirm
                  title={t(
                    'creditcard.delete_title',
                    'Estás seguro que querés borrar este método de pago?'
                  )}
                  description={t(
                    'creditcard.delete_description',
                    'Se eliminara el metodo de pago seleccionado.'
                  )}
                  onConfirm={() => confirm(cc.id)}
                  onCancel={cancel}
                  okText={t('delete', 'Eliminar')}
                  open={open === cc.id}
                  cancelText={t('cancel', 'Cancelar')}
                  okButtonProps={{
                    loading: confirmLoading,
                    className:
                      'bg-primaryRed text-white rounded-md px-2 py-1 hover:bg-primaryRed ',
                  }}
                >
                  <BiTrash
                    size={18}
                    color='#ED4C5C'
                    onClick={() => setOpen(cc.id)}
                  />
                </Popconfirm>
              </Tooltip>
            </div>
          </div>
        ))}
      </CardRounded>
      {contextHolder}
      {contextHolderMessage}
    </div>
  );
}
