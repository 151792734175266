import React from 'react';
import CardRounded from '../CardRounded';
import DailyOperations from '../Desktop/DailyOperations';

import DebitMember from './DebitMember';
import ListMemberProducts from './ListMemberProducts';
import ListMemberCC from './ListMemberCC';
import { t } from 'i18next';
import MemberCreditCards from './MemberCreditCards';
export default function ProductsMember({
  products,
  customer_id,
  debits,
  handleOpenDebit,
  setmodalSale,
  isProspect,
  openNotification,
  dischargeReasons,
  setopenCC,
  handleOpenReturn,
  handleOpenCreditCard,
  user_id,
  paymentPlatform,
  member_name,
  api
}) {
  if (!products) return null;
  return (
    <div>
     <ListMemberProducts 
       openNotification={openNotification}
       customer_type={isProspect?'Prospect':'Member'}
     customer_id={customer_id}
     products={products} isProspect={isProspect} />
            {/* Debits */}

      <DebitMember
       openNotification={openNotification}
        debits={debits}
        handleOpenDebit={handleOpenDebit}
        customer_id={customer_id}
        isProspect={isProspect}
        dischargeReasons={dischargeReasons}
        user_id={user_id}
        paymentPlatform={paymentPlatform}
        api={api}
        member_name={member_name}
      />
            {/* CC */}

      <div className='mb-10'>
        <h3 className='font-MessinaSansSemiBold text-xl pb-4'>
          {t('cc_title','Cuenta corriente')}
        </h3>
        <CardRounded >
          <ListMemberCC
          setopenCC={(cc)=>setopenCC(cc)}
          openNotification={openNotification}
          customer_id={customer_id}
          customer_type={isProspect?'Prospect':'Member'}
          />
        </CardRounded>
      </div>

     {/* Cards */}
    {paymentPlatform?.length > 0  &&  <div className='mb-10'>
          <MemberCreditCards
          user_id={user_id}
          openNotification={openNotification}
          handleOpenCreditCard={(cc)=>handleOpenCreditCard(cc)}
          customer_id={customer_id}
          customer_type={isProspect?'Prospect':'Member'}
          member_name={member_name}
          />
      </div>}
      {/* Transacciones */}
      <DailyOperations
      paymentPlatform={paymentPlatform}
                handleOpenReturn={handleOpenReturn}

        customer_type={isProspect?'Prospect':'Member'}
      setmodalSale={setmodalSale} customer_id={customer_id} openNotification={openNotification} />
        <ListMemberProducts 
        showInvalid={true}
       openNotification={openNotification}
       customer_type={isProspect?'Prospect':'Member'}
     customer_id={customer_id}
     products={products} isProspect={isProspect} />
    </div>
  );
}
