import React, { useState, useEffect, useRef } from 'react';
import {
  AiOutlineUserAdd,
  AiOutlineSearch,
  AiOutlineBell,
} from 'react-icons/ai';

import SpinIndicador from '../../components/helpers/SpinIndicador';
import { Dropdown } from 'antd';
import { useAuth } from '../../context/AuthProvider';
import { useNavigate } from 'react-router-dom';
import SalesHeader from '../../components/Desktop/Header/SalesHeader';
import Locations from './Locations';
import { useQuery } from '@tanstack/react-query';
import { useGetBalance } from '../../router/loaders/AccountingLoaders';
import { canCreateProspect } from '../../services/can_user';
import { search_all } from '../../api/search';
import { getLocationId } from '../../services/utils/getLocation';
import { useMediaQuery } from 'react-responsive';
import { useGlobalContext } from '../../context/GlobalState';
import { useTranslation } from 'react-i18next';
import HamburgerButton from '../HamburgerMenuButton/HamburgerButton';
export default function index({
  setopenSales,
  setModalMemberOpen,
  setTypeMemberCreate,
  isOpenMobileMenu,
  setisOpenMobileMenu,
}) {
  const { state } = useGlobalContext();
  // console.log('state',state);
  const componentRef = useRef(null);
  const { user, logout } = useAuth();
  const [todayAlerts, settodayAlerts] = useState([]);
  const location_id = getLocationId();
  const toogleMobileMenu = () => {
    setisOpenMobileMenu(!isOpenMobileMenu);
  };
  const { data: balances, isLoading: isLoadingBalances } = useQuery(
    useGetBalance(location_id)
  );
  const { t, i18n } = useTranslation();
  // const { isLoading: isLoadingAlerts, data: alerts } =
  //   useGetAlerts(todayFormatted);

  const navigate = useNavigate();
  // useEffect(() => {
  //   if (alerts && !isLoadingAlerts) {
  //     settodayAlerts(
  //       alerts.filter(
  //         (alert) =>
  //           alert.user_id == user.user_id &&
  //           !alert.performed_at &&
  //           dayjs(alert.sent_at, 'YYYY-MM-DD HH:mm:ss').isSame(dayjs(), 'day')
  //       )
  //     );
  //   }
  // }, [alerts,isLoadingAlerts]);


  const [isHoveredLocation, setisHoveredLocation] = useState(false);
  const [isLocationOpen, setIsLocationOpen] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const searchRef = useRef(null);
  const showSearchRef = useRef(null);
  const searchContainerRef = useRef(null);

  const [suggestionList, setsuggestionList] = useState([]);
  const [loadingSuggestions, setloadingSuggestions] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [searchMember, setSearchMember] = useState('');
  const [focusSearch, setfocusSearch] = useState(false)
  const [debouncedSearch, setDebouncedSearch] = useState(searchMember);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(searchMember);
    }, 300); // 300 ms de debounce time

    return () => {
      clearTimeout(handler);
    };
  }, [searchMember]);
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      if (currentScrollPos > 100 && prevScrollPos < currentScrollPos) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos]);
  useEffect(() => {
    if (debouncedSearch.trim().length < 2) {
      // Si la cadena de búsqueda tiene menos de 2 caracteres, no hacer nada.
      return;
    }

    apiGetMembers2();
  }, [debouncedSearch]);
  const handleModalCreateMember = (key) => {
    setTypeMemberCreate(key);
  };
  const isBalanceOpen = () => {
    if (isLoadingBalances) return;
    if (balances) {
      let orderBalances = balances.sort((a, b) => {
        const timeA = new Date(b.created_at).getTime();
        const timeB = new Date(a.created_at).getTime();
        return timeA - timeB;
      });
      return orderBalances[0]?.operation_type || 'close';
    } else {
      return 'close';
    }
  };

  const handleKeyPress = React.useCallback((e) => {
    if (
      document.activeElement &&
      !(document.activeElement instanceof HTMLInputElement) &&
      !(document.activeElement instanceof HTMLSelectElement) &&
      !(document.activeElement instanceof HTMLTextAreaElement)
    ) {
      showSearch();
      // setsearchMember('s');

      document.removeEventListener('keypress', handleKeyPress);
    }
  }, []);
  useEffect(() => {
    document.addEventListener('keypress', handleKeyPress);
    return () => {
      document.removeEventListener('keypress', handleKeyPress);
    };
  }, []);
  const showSearch = (e) => {
    searchContainerRef.current.classList.remove(
      'opacity-0',
      'pointer-events-none'
    );
    setfocusSearch(true);
    searchRef.current.focus();
    // e.target.classList.add('hidden');
  };

  const handleLeave = React.useCallback(() => {
    searchContainerRef.current.classList.add(
      'opacity-0',
      'pointer-events-none'
    );
    if(!isMobile){
      showSearchRef.current.classList.remove('hidden');

    }
    setfocusSearch(false);

    setSearchMember('');
  }, []);
  function handleClickOutside(event) {
    if (componentRef.current && !componentRef.current.contains(event.target)) {
      handleLeave();
    }
  }
  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleLeave]);

  const apiGetMembers2 = async () => {
    const members = await search_all(searchMember);
    setsuggestionList(members.user_results);
  };
  useEffect(() => {
    if (searchMember.length == 0 && suggestionList.length > 0) {
      setsuggestionList([]);
    }
  }, [searchMember, suggestionList]);
  const isMobile = useMediaQuery({ maxWidth: 767 }); // Definir el ancho máximo para dispositivos móviles
  const [lngs, setLngs] = useState({});

  useEffect(() => {
    i18n.services.backendConnector.backend.getLanguages((err, ret) => {
      if (err) return; // TODO: handle err...
      setLngs(ret);
    });
  }, []);
  return (
    <div
      ref={componentRef}
      className={`border-b-[2px] right-0 lg:ml-[100px] left-0 border-b-primaryGrey flex flex-row items-center fixed top-0 z-30 bg-black  transition-transform duration-500 ${
        isScrolled ? '-translate-y-full' : 'translate-y-0'
      }`}
    >
      <div
        className={` ml-2 lg:ml-10 flex flex-row items-center gap-5  mr-6  lg:mr-0 w-[100%] lg:w-[45%] py-5 ${
          searchRef.current === document.activeElement
        } `}
      >
        <div className='flex lg:hidden mr-3'>
          <HamburgerButton
        toggleMenu={toogleMobileMenu}
        isOpen={isOpenMobileMenu}
        /> 
        </div>
        <Locations
          setIsLocationOpen={setIsLocationOpen}
          setisHoveredLocation={setisHoveredLocation}
          isLocationOpen={isLocationOpen}
          isHoveredLocation={isHoveredLocation}
        />
        {canCreateProspect() && (
          <button
            onClick={() => {
              handleModalCreateMember('Prospect');
            }}
            className={`flex flex-row items-center gap-2 ${isMobile && focusSearch && 'hidden'}`}
          >
            <AiOutlineUserAdd size={20} />
            <p className='hidden lg:flex'>{t('header.new_member')}</p>
          </button>
        )}
        {/* </Dropdown> */}
        {!isLoadingBalances ? (
          <SalesHeader
          styles={isMobile && focusSearch && 'hidden'}
            isBalanceOpen={isBalanceOpen}
            setopenSales={() => setopenSales(isBalanceOpen())}
          />
        ) : (
          <SpinIndicador />
        )}
      </div>

      <div className={`flex flex-row items-center justify-end  ${ isMobile && focusSearch ? 'w-[100%]' : 'w-[50%]'} gap-14 `}>
        <div
          className={`flex flex-row items-center gap-3 relative ${
            isMobile ? 'w-[100%]' : 'w-[50%]'
          } py-5`}
        >
          <div className='flex items-center gap-3 cursor-pointer p-2 rounded-md hover:bg-primaryGrey bg-gray-900 smooth-t'>
            <AiOutlineSearch onClick={showSearch} size={isMobile ? 18 : 25} />
            <p
              ref={showSearchRef}
              onClick={showSearch}
              className={`outline-none bg-transparent w-full hover:cursor-pointer hidden  lg:flex`}
            >
              {/* Empezá a escribir para buscar... */}
              {t('header.search_member', 'Buscar socio')}...
            </p>
          </div>
          <div
            ref={searchContainerRef}
            className='bg-background rounded-md absolute top-10 z-50 left-0 right-0 max-h-[20rem] overflow-y-scroll searchbox-container w-100 opacity-0 pointer-events-none smooth-t flex flex-column px-4'
          >
            <div className='flex items-center gap-3 sticky top-0  bg-background'>
              <input
                ref={searchRef}
                placeholder={`${t(
                  'header.start_search',
                  'Empezá a escribir para buscar'
                )}...`}
                className='outline-none bg-transparent w-full border-b-[1px] border-b-primaryGrey py-4 px-2 smooth-t search-input text-xs lg:text-md'
                // onChange={handleSearch}
                onChange={(e) => {
                  if (e.target.value !== ' ') {
                    setSearchMember(e.target.value);
                  }
                }}
                value={searchMember}
                onPaste={(e) => setSearchMember(e.target.value)}
                // onPaste={handleSearch}
                type='search'
              />
              {loadingSuggestions && <SpinIndicador />}
            </div>

            {suggestionList.map((item) => (
              <div
                className='hover:cursor-pointer py-2 my-1 px-2  searchbox-item smooth-t w-full rounded-md '
                onClick={() => {
                  // setSearchMember('')
                  setModalMemberOpen({
                    id: item.member_id ? item.member_id : item.prospect_id,
                    isProspect: item.member_id ? false : true,
                    customer_type: item.member_id ? 'Member' : item.prospect_id? 'Prospect':'Lead',
                  });
                  handleLeave();
                }}
              >
                <p> {item.first_name + ' ' + item.last_name} </p>

                <span
                  className={` text-xxs lg:text-xs p-[0.15rem] rounded-sm mr-2 uppercase font-semibold ${
                    item.member_id
                      ? 'bg-lightGreen text-darkGreen'
                      : ' bg-lightBlue text-primaryBlue'
                  }`}
                >
                  {item.member_id
                    ? t('member', 'Socio')
                    : t('prospect', 'Prospecto')}
                </span>
                <span className='text-secondaryGrey text-xs'>
                  N° {item.member_id ? item.member_id : item.prospect_id} |{' '}
                  {item.member_location_name
                    ? item.member_location_name
                    : item.prospect_location_name}
                </span>
              </div>
            ))}
          </div>
        </div>

        <div className='lg:flex flex-row items-center gap-6 py-5 hidden '>
          <div
            className='relative alerts-nav p-2 smooth-t hover:bg-primaryGrey rounded-md cursor-pointer'
            onClick={() => setIsAlertOpen(true)}
            onMouseLeave={() => setIsAlertOpen(false)}
          >
            <AiOutlineBell
              size={28}
              className=' cursor-pointer hover:scale-110 '
            />
            {/* {todayAlerts.length > 0 && (
              <div className=' bg-primaryRed h-5 w-5 rounded-full absolute top-0 right-0 flex items-center justify-center '>
                <span className='text-black  text-sm'>
                  {todayAlerts.length}
                </span>
              </div>
            )} */}
            <div
              className={`absolute top-10 bg-black z-50 w-max right-0 rounded-md alert-select overflow-y-scroll max-h-[20rem] px-4 smooth-t border-[2px] border-primaryGrey ${
                !isAlertOpen && 'opacity-0 pointer-events-none'
              }`}
            >
              {todayAlerts.length == 0 && (
                <p className='text-center py-2 px-4 mt-1'>
                  {t('no_alerts', 'Sin Notificaciones')}
                </p>
              )}
              {todayAlerts.map((alert, index) => (
                <div
                  onClick={() =>
                    setModalMemberOpen({
                      id: alert.customer_id,
                      isProspect: false,
                    })
                  }
                  key={index}
                  className='hover:cursor-pointer py-2 my-1 px-2 searchbox-item smooth-t w-max rounded-md last:border-none '
                >
                  <button className='mr-2 text-primaryGreen  font-MessinaSansSemiBold'>
                    {alert.member.user.first_name +
                      ' ' +
                      alert.member.user.last_name}
                  </button>
                  {alert.message}
                </div>
              ))}
              {/* <div className='sticky z-100 border-t-[1px] border-t-primaryGrey  py-5'>
                <p className='text-center'>Ver todos</p>
              </div> */}
            </div>
          </div>
          <Dropdown
            menu={{
              items: [
                {
                  label: t('sign_out', 'Cerrar sesion'),

                  key: 'logout',
                },
              ],

              onClick: ({ key }) => {
                if (key == 'logout') {
                  navigate('/login', { replace: true });
                  logout();
                }
              },
            }}
            trigger={['click']}
          >
            <button className='hidden lg:flex  hover:opacity-80 ease-in-out duration-300'>
              <span>{user?.email}</span>
            </button>
          </Dropdown>
          {state.user?.roles?.some((r) => r.id == 7) && (
            <Dropdown
              menu={{
                items: Object.keys(lngs).map((lng) => ({
                  label: lngs[lng].nativeName,
                  key: lng,
                  className:
                    i18n.language === lng ? 'bg-lightGreen' : 'font-normal',
                })),
                onClick: ({ key }) => {
                  i18n.changeLanguage(key);
                },
              }}
              trigger={['click']}
            >
              <button className='hidden lg:flex  hover:opacity-80 ease-in-out duration-300'>
                <span>
                  {
                    lngs[Object.keys(lngs).find((lng) => i18n.language === lng)]
                      ?.nativeName
                  }
                </span>
              </button>
            </Dropdown>
          )}
        </div>
      </div>
    </div>
  );
}
