import React, { useMemo } from 'react';
import Card from './Card';
import CountUp from 'react-countup';
import { Statistic, Tooltip } from 'antd';
import { useGetCRMChart } from '../../api/charts';
import { BsArrowUp, BsArrowDown } from 'react-icons/bs';
import { t } from 'i18next';

export default function CRMChart() {
  const { data, isLoading } = useGetCRMChart();

  const insights = useMemo(() => {
    if (data?.results) {
      const this_month = data.results.reduce((acc, curr) => acc + curr.crm_actual, 0);
      const last_month = data.results.reduce((acc, curr) => acc + curr.crm_last_month, 0);
      const percentageDifference = ((this_month - last_month) / (last_month === 0 ? 1 : last_month)) * 100;
      return {
        diff: percentageDifference.toFixed(0) || 0,
        this_month,
        last_month,
      };
    }
    return {
      diff: 0,
      this_month: 0,
      last_month: 0,
    };
  }, [data]);

  const sortedResults = useMemo(() => {
    return data?.results
      .filter(item => item.crm_actual > 0)
      .sort((a, b) => b.crm_actual - a.crm_actual) || [];
  }, [data]);

  const topResults = sortedResults.slice(0, 10);
  const others = sortedResults.slice(9).reduce((acc, curr) => acc + curr.crm_actual, 0);

  return (
    <Card
      title={t('dashboard.crm_title')}
      insight={false}
      description_last_month={`${insights.last_month} al mismo momento el mes pasado`}
      percentage={insights?.diff}
      size='small'
      icon={''}
      description={data?.description?.description}
      TopInsight={
        data && !isLoading && (
          <div className='gap-3 flex-1 pt-3 flex flex-col justify-between'>
            <span
              className={`font-DrukWideMedium ${insights.diff > 0 ? 'text-primaryGreen' : insights.diff === 0 ? 'text-primaryYellow' : 'text-primaryRed'} flex items-center text-3xl`}
            >
              <Statistic
                value={insights?.this_month}
                formatter={() => (
                  <CountUp
                    start={0}
                    end={insights?.this_month}
                    className={`font-DrukWideMedium ${insights.diff > 0 ? 'text-primaryGreen' : insights.diff === 0 ? 'text-primaryYellow' : 'text-primaryRed'} flex items-center text-4xl`}
                    separator='.'
                  />
                )}
              />
              {insights?.this_month > 0 &&
                (insights.diff > 0 ? <BsArrowUp size={20} className='self-center' /> : <BsArrowDown size={20} className='self-center' />)}
            </span>
            <div className='pt-1'>
              {topResults.map((item, i) => (
                <Tooltip key={i} title={`${item.crm_last_month} al mismo momento el mes pasado`}>
                  <div className='flex flex-row gap-4 justify-between items-center'>
                    <span
                      className={`  text-lg ${item.crm_actual > item.crm_last_month ? 'text-primaryGreen' : item.crm_actual === item.crm_last_month ? 'text-primaryYellow' : 'text-primaryRed'}`}
                    >
                      {i === 9 ? 'Otros' : item.name}
                    </span>
                    <span
                      className={`text-lg ${item.crm_actual > item.crm_last_month ? 'text-primaryGreen' : item.crm_actual === item.crm_last_month ? 'text-primaryYellow' : 'text-primaryRed'}`}
                    >
                      {i === 9 ? others : item.crm_actual}
                    </span>
                  </div>
                </Tooltip>
              ))}
            </div>
          </div>
        )
      }
    />
  );
}