import {
  Select,
  InputNumber,
  Dropdown,
  Tooltip,
  ConfigProvider,
  DatePicker,
} from 'antd';
import es_ES from 'antd/locale/es_ES';
import React, { useRef } from 'react';
import { MdOutlineDiscount } from 'react-icons/md';

import { formatPrice } from '../services/utils/formatPrice';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import SelectPaymentMethod from './SelectPaymentMethod';
import { useGlobalContext } from '../context/GlobalState';
import {
  getCurrency,
  getLocationRegionConfig,
} from '../services/utils/getLocation';
import { canCreateFreeProduct } from '../services/can_user';
import { t } from 'i18next';
import { useMediaQuery } from 'react-responsive';
export default function SelectProduct({
  selectedProducts,
  sessions,
  selectedProduct,
  setSelectedProducts,
  products,
  isLoadingProducts,
  discounts,
  isLoadingDiscounts,
  consumables,
  isLoadingConsumables,
  handleChangeEndDate,
  index,
  owner_type,
  prospectConvert,
  customer_id,
  owner_user_id,

  paymentPlatform,
  openAddCreditCard,
  setopenAddCreditCard


}) {
  const productRef = useRef(null);
  const countProductsRef = useRef(null);
  const datepickerRef = useRef(null);
  const { state } = useGlobalContext();
  const canAddFree = canCreateFreeProduct();
  /**
   * Categorizes the products and consumables based on their type and formats them for display in the Select component.
   * @returns {Array} An array of objects containing the categorized products and consumables.
   */
  // console.log('products',products);
  const categorizeProducts = useMemo(() => {
    if (!products || !consumables) return [];
    let combinedProducts = prospectConvert
    ? products
    : owner_type === 'Prospect'
    ? [
        ...products.filter((p) => p.product.parent_product_id == 33),
        ...(consumables ? consumables : []),
        ...(sessions ? sessions : []),
      ]
    : [
        ...products.filter((p) => p.status_id == 1),
        ...(consumables ? consumables : []),
        ...(sessions ? sessions : []),
      ];
    const AvailableRegionConfig = getLocationRegionConfig(state.locations).map(
      (config) => config.id
    );
    
    let filterByRegionConfig = combinedProducts.filter(
      (product) =>
        AvailableRegionConfig.includes(
          product.product.available_region_config_type_id
        ) &&
        product.product.id !== 12 &&
        product.product.id !== 3
    ); //remove cdp and gympass product
    // console.log('product',filterByRegionConfig.filter((p)=>p.product_type_id==2));

    return Object.values(
      filterByRegionConfig.reduce((acc, product) => {
        const type = product.product_type_name_es;
        if (!acc[type]) {
          acc[type] = { label: type, options: [] };
        }
        acc[type].options.push({
          value: product.product_id,
          label:
            product.name +
            '-' +
            formatPrice(product.price) +
            (product.product_type_id == 2
              ? ` -${
                  product.product_location_stock
                    ? product.product_location_stock.units
                    : 'Sin'
                } Unidades`
              : ''),
          className: `${
            selectedProducts.some((p) => p.id == product.product_id)
              ? 'bg-lightGreen'
              : ''
          }`,
          title: product.name,
          key: product.product_id,
          disabled:
            (!canAddFree && product.product.parent_product_id == 29) ||
            (product.product_type_id == 2 &&
              (!product.product_location_stock ||
                product.product_location_stock.units == 0)), // can add free
        });
        return acc;
      }, {})
    );
  }, [consumables, products, selectedProducts, sessions]);
  
  const categorizeDiscounts = useMemo(() => {
    if (!discounts) return [];
    const AvailableRegionConfig = getLocationRegionConfig(state.locations).map(
      (config) => config.id
    );
    let filterByRegionConfig = discounts.filter(
      (d) => AvailableRegionConfig.includes(d.region_config_type_id) && d.active
    ); //remove cdp and gympass product
    return filterByRegionConfig.map((discount) => ({
      key: discount.id,
      label: discount.name + '-' + parseFloat(discount.amount) + '%',
      className:
        discount.id == selectedProduct?.discount?.id ? 'bg-lightGreen' : '',
    }));
  }, [discounts,selectedProduct]);
  const disabledDate = (current, product) => {
    // Can not select days before today and today
    if (current && current < dayjs().endOf('day')) {
      return true;
    }
    if (
      dayjs(product.started_at).add(product.days_amount, 'days') < current &&
      !product.ended_at
    ) {
      return true;
    }
  };
  const isMobile = useMediaQuery({ maxWidth: 767 }); // Definir el ancho máximo para dispositivos móviles

  return (
    <div className='pt-10 flex-col items-center' key={index}>
      <div className='gap-3  items-center'>
        {index == 0 && (
          <Select
            ref={productRef}
            loading={isLoadingProducts && isLoadingConsumables}
            showSearch
            placeholder={
              <div className='flex items-center z-10'>
                <span className='text-white'>
                  {t('sales.search_products', 'Buscar productos...')}
                </span>
              </div>
            }
            mode='tags'
            allowClear
            optionLabelProp='title'
            value={[]}
            // value={selectedProduct.product_id}
            optionFilterProp='children'
            onChange={(value) => {
              productRef.current.blur();
              let product = [
                ...(products ? products : []),
              ...(sessions ? sessions : []),
                 ...(consumables ? consumables : []),
              ...(sessions ? sessions : [])].find(
                (p) => p.product_id === value[0]
              );
              console.log('product',product);
              
              if (!product && selectedProduct) {
                return setSelectedProducts(
                  selectedProducts.filter(
                    (p) => p.product_id != selectedProduct.product_id
                  )
                );
              }
              if (!product) return;

              product.countProducts = 1;
              if (
                selectedProducts.some((p) => p.product_id == product.product_id)
              ) {
                setSelectedProducts(
                  selectedProducts.filter(
                    (p) => p.product_id != product.product_id
                  )
                );
                return;
              } else {
                if (product.product_type_id === 1) {
                  product.started_at = dayjs().format('YYYY-MM-DD');
                }
                product.selectedPaymentMethods = [
                  { paymentType: null, amount: product.price },
                ];

                setSelectedProducts([
                  ...selectedProducts.filter((p) => p != 'skeleton'),
                  product,
                ]);
              }
              // setSelectedProducts(product);
            }}
            variant='borderless'

            rootClassName=' text-white '
            className={`bg-primaryGrey text-white rounded-sm py-2
  
       `}
            style={{
              width: isMobile ? '85%':  '45%',
            }}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={isLoadingProducts ? [] : categorizeProducts}
          />
        )}
        {selectedProduct.id && (
          <>
            <div className='flex flex-row items-center gap-3 mt-6'>
              <Tooltip title={selectedProduct.description}>
                <div
                  onClick={() => {
                    setSelectedProducts(
                      selectedProducts.filter((p) => p.id != selectedProduct.id)
                    );
                  }}
                  className=' cursor-pointer  bg-primaryGrey border-[1px] border-lightGreen  h-auto lg:h-12 items-center flex rounded-sm px-4 text-lightGreen font-MessinaSansSemiBold text-md self-start '
                >
                  <span className='text-xs lg:text-md'>{selectedProduct.name}</span>
                </div>
              </Tooltip>
              {selectedProduct.product_type_id !== 1 && (
                <InputNumber
                  min={1}
                  ref={countProductsRef}
                  value={selectedProduct.countProducts}
                  max={10}
                  defaultValue={1}
                  onChange={(value) => {
                    countProductsRef.current.blur();
                    setSelectedProducts(
                      selectedProducts.map((p) =>
                        p.id == selectedProduct.id
                          ? { ...p, countProducts: value }
                          : p
                      )
                    );
                  }}
                  color='#eee'
                  style={{
                    color: 'red',
                    width: 45,
                  }}
                  className='bg-primaryGrey h-12 outline-none rounded-sm flex items-center justify-center  !text-white  border-none'
                />
              )}
              <div className=' bg-secondaryGrey h-12 flex items-center justify-center px-3 rounded-sm  text-black font-MessinaSansSemiBold text-lg'>
                {/* <p>
                  <span className='mr-1'>$</span>
                </p> */}
                {formatPrice(
                  selectedProduct.countProducts * selectedProduct.price,
                  getCurrency()
                )}
              </div>
              <div className='flex flex-row item-center gap-4'>
                <Dropdown
                  arrow={{
                    pointAtCenter: true,
                  }}
                  placement='bottom'
                  loading={isLoadingDiscounts}
                  menu={{
                    style: {
                      maxHeight: 350,
                      overflowY: 'scroll',
                    },
                    items: isLoadingDiscounts ? [] : categorizeDiscounts,
                    onClick: ({ key, label }) => {
                      if (key == selectedProduct.discount?.id) {
                        setSelectedProducts(
                          selectedProducts.map((p) =>
                            p.id == selectedProduct.id
                              ? { ...p, discount: null }
                              : p
                          )
                        );
                        return;
                      }
                      setSelectedProducts(
                        selectedProducts.map((p) =>
                          p.id == selectedProduct.id
                            ? {
                                ...p,
                                discount: discounts.find((d) => d.id == key),
                              }
                            : p
                        )
                      );
                    },

                    selectedKeys: [selectedProduct.discount?.id],
                  }}
                  trigger={['click']}
                >
                  <div
                    className={`bg-primaryGrey h-12 rounded-sm px-4 max-w-[12rem] flex items-center justify-center hover:cursor-pointer border-[1px] ${
                      selectedProduct.discount?.id
                        ? 'border-lightGreen '
                        : 'border-transparent'
                    }`}
                  >
                    {selectedProduct.discount?.id ? (
                      <p className='text-xs font-MessinaSansSemiBold truncate'>
                        {selectedProduct.discount?.name}
                      </p>
                    ) : (
                      <Tooltip
                        title={t('sales.apply_discount', 'Aplicar descuento')}
                      >
                        <MdOutlineDiscount />
                      </Tooltip>
                    )}
                  </div>
                </Dropdown>
                {selectedProduct.discount?.id && (
                  <div className='bg-secondaryGrey h-11 flex items-center justify-center rounded-sm px-4 text-black font-MessinaSansSemiBold text-lg'>
                    <span>
                      {formatPrice(
                        selectedProduct.countProducts *
                          selectedProduct.price *
                          (selectedProduct.discount?.id
                            ? 1 -
                              parseFloat(selectedProduct.discount.amount) / 100
                            : 1)
                      )}
                    </span>
                  </div>
                )}
                {selectedProduct.product_type_id === 1 && (
                  <div className='flex flex-row items-center gap-3 '>
                    <Tooltip title={t('product.days_left', 'Dias restantes')}>
                      <div>
                        <input
                          disabled
                          value={selectedProduct?.days_amount}
                          placeholder={t('product.days_left', 'Dias restantes')}
                          name={'started_at'}
                          className={`appearance-none text-sm  bg-primaryGrey outline-none rounded-sm px-5 h-12  w-16`}
                          type={'number'}
                        />
                      </div>
                    </Tooltip>
                    <Tooltip
                      title={t('product.sessions_left', 'Sesiones restantes')}
                    >
                      <div>
                        <input
                          disabled
                          value={selectedProduct?.sessions_amount}
                          placeholder={t(
                            'product.sessions_left',
                            'Sesiones restantes'
                          )}
                          name={'days_left'}
                          className={`appearance-none  text-sm  bg-primaryGrey outline-none rounded-sm px-5 h-12 w-16`}
                          type={'number'}
                        />
                      </div>
                    </Tooltip>
                    <Tooltip title={t('product.date_start', 'Fecha de Inicio')}>
                      <div>
                        <ConfigProvider locale={es_ES}>
                          <DatePicker
                            placeholder={t('start', 'Inicio')}
                            allowClear={false}
                            ref={datepickerRef}
                            disabledDate={(current) =>
                              current &&
                              current < dayjs().subtract(8, 'days').endOf('day')
                            }
                            value={dayjs(
                              selectedProduct.started_at,
                              'YYYY-MM-DD'
                            )}
                            onChange={(date, dateString) => {
                              datepickerRef.current.blur();

                              setSelectedProducts(
                                selectedProducts.map((p) =>
                                  p.product_id == selectedProduct.product_id
                                    ? {
                                        ...p,
                                        started_at: dateString,
                                      }
                                    : p
                                )
                              );
                            }}
                            name='started_at'
                            defaultValue={dayjs(
                              selectedProduct.started_at,
                              'YYYY-MM-DD'
                            )}
                            showToday
                            format={'YYYY-MM-DD'}
                            className=' bg-primaryGrey shadow-none outline-none border-none  h-12 rounded-sm w-32'
                          />
                        </ConfigProvider>
                      </div>
                    </Tooltip>
                    <Tooltip title={t('product.date_end', 'Fecha de Fin')}>
                      <div>
                        <ConfigProvider locale={es_ES}>
                          <DatePicker
                            placeholder={t('end', 'Fin')}
                            disabledDate={(date) =>
                              disabledDate(date, selectedProduct)
                            }
                            allowClear={false}
                            ref={datepickerRef}
                            onSelect={(date, dateString) => {
                              if (
                                dayjs(date).format('YYYY-MM-DD') ==
                                selectedProduct.ended_at
                              ) {
                                datepickerRef.current.blur();
                                return setSelectedProducts(
                                  selectedProducts.map((p) =>
                                    p.product_id == selectedProduct.product_id
                                      ? {
                                          ...p,
                                          ended_at: null,
                                        }
                                      : p
                                  )
                                );
                              }
                            }}
                            onChange={(date, dateString) => {
                              datepickerRef.current.blur();
                              handleChangeEndDate(dateString, selectedProduct);
                              if (dateString) {
                                setSelectedProducts(
                                  selectedProducts.map((p) =>
                                    p.product_id == selectedProduct.product_id
                                      ? {
                                          ...p,
                                          ended_at: dateString,
                                        }
                                      : p
                                  )
                                );
                              }
                            }}
                            name='ended_at'
                            defaultValue={false}
                            value={
                              selectedProduct.ended_at
                                ? dayjs(selectedProduct.ended_at, 'YYYY-MM-DD')
                                : false
                            }
                            showToday
                            format={'YYYY-MM-DD'}
                            className=' bg-primaryGrey shadow-none outline-none border-none  h-12 rounded-sm w-32'
                          />
                        </ConfigProvider>
                      </div>
                    </Tooltip>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
        <div>
          {selectedProduct.id && (
            <>
              {selectedProduct.ended_at && (
                <p className='text-lightGreen font-MessinaSansSemiBold mt-1'>
                  {t('sales.product_proportional', {
                    price: formatPrice(
                      selectedProduct.countProducts *
                        selectedProduct.price *
                        (selectedProduct.discount?.id
                          ? 1 -
                            parseFloat(selectedProduct.discount.amount) / 100
                          : 1)
                    ),
                    days: selectedProduct.days_amount,
                    sessions: selectedProduct.sessions_amount,
                  })}
                </p>
              )}
              <SelectPaymentMethod
              owner_user_id={owner_user_id}
              openAddCreditCard = {openAddCreditCard}
                 setopenAddCreditCard = {setopenAddCreditCard}
              paymentPlatform={paymentPlatform}
                setSelectedPaymentMethods={(selectedPaymentMethods) => {
                  setSelectedProducts(
                    selectedProducts.map((p) =>
                      p.id == selectedProduct.id
                        ? {
                            ...p,
                            selectedPaymentMethods: selectedPaymentMethods,
                          }
                        : p
                    )
                  );
                }}
                product_name={selectedProduct.name}
                selectedPaymentMethods={selectedProduct.selectedPaymentMethods}
                selectedProducts={selectedProducts}
                totalAmount={
                  selectedProduct.countProducts *
                  selectedProduct.price *
                  (selectedProduct.discount?.id
                    ? 1 - parseFloat(selectedProduct.discount.amount) / 100
                    : 1)
                }
                showPercentage={false}
                discount={selectedProduct.discount}
              />
            
            </>
          )}
        </div>
      </div>
    </div>
  );
}
