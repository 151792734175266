import React, { useState, useRef, useMemo } from 'react';
import { Skeleton, Tooltip, ConfigProvider, DatePicker, Select } from 'antd';
import dayjs from 'dayjs';
import { MdOutlineExitToApp } from 'react-icons/md';
import { formatPrice } from '../../services/utils/formatPrice';
import { useGetCreditNotes, useGetSales } from '../../api/sales';
import es_ES from 'antd/locale/es_ES';
import { BsChevronDown } from 'react-icons/bs';

import CardRounded from '../CardRounded';
import AddButton from '../helpers/AddButton';

import { useGetMemberSales } from '../../api/members';
import { useGetLocationPayment_methods } from '../../api/payments';
import { getLocationId } from '../../services/utils/getLocation';
import { useGlobalContext } from '../../context/GlobalState';
import { useQueryClient } from '@tanstack/react-query';
import { canCreateSale } from '../../services/can_user';
import { useAuth } from '../../context/AuthProvider';
import { t } from 'i18next';
import LineOperation from '../helpers/LineOperation';
import { useGetProducts_types } from '../../api/products';

const { RangePicker } = DatePicker;
const today = dayjs();
const first_day = dayjs();

export default function DailyOperations({
  styles,
  setmodalSale,
  setmodalTransaction,
  customer_id,
  showExtract,
  setModalMemberOpen,
  openNotification,
  customer_type,
  handleOpenReturn,
  paymentPlatform,
}) {
  const { user } = useAuth();
  const queryClient = useQueryClient();

  const { state } = useGlobalContext();
  const [date_selected, setDate_selected] = useState([first_day, today]);
  const [selectedPaymentId, setselectedPaymentId] = useState(null);
  const [selectedProductType, setselectedProductType] = useState(null);
  const datepickerRef = useRef(null);
  const filterPaymentRef = useRef(null);
  const product_typesRef = useRef(null);
  const location_id = getLocationId();
  const { data: payment_methods, isLoading: isLoadingPaymentTypes } =
    useGetLocationPayment_methods();
  const { data: product_types, isLoading: isLoadingProduct_types } =
    useGetProducts_types();
  const {
    data: sales,
    isLoading: isLoadingSales,
    isError,
  } = customer_id
    ? useGetMemberSales(customer_id, customer_type)
    : useGetSales(
        date_selected[0].format('YYYY-MM-DD'),
        date_selected[1].format('YYYY-MM-DD')
      );

  const { data: credit_notes, isLoading: isLoadingCreditNotes } = customer_id
    ? { data: [], isLoading: false }
    : useGetCreditNotes(
        false,
        date_selected[0].format('YYYY-MM-DD'),
        date_selected[1].format('YYYY-MM-DD')
      );

  const rangePresets = [
    {
      label: t('last_7_days', 'Últimos 7 Días'),
      value: [dayjs().add(-7, 'd'), dayjs()],
    },
    {
      label: t('last_15_days', 'Últimos 15 Días'),
      value: [dayjs().add(-15, 'd'), dayjs()],
    },
    {
      label: t('last_30_days', 'Últimos 30 Días'),
      value: [dayjs().add(-30, 'd'), dayjs()],
    },
  ];

  const categorizePayments = useMemo(() => {
    if (!payment_methods) return [];
    return payment_methods
      .filter((payment_m) => payment_m.location_id == location_id)
      .map((paymentType) => ({
        label: paymentType.name,
        value: paymentType.id,
        title: paymentType.name,
      }));
  }, [payment_methods, state.locations]);

  const handlePaymentChange = (value) => {
    filterPaymentRef.current.blur();
    setselectedPaymentId(value ? value : null);
  };
  const handleProductTypeChange = (value) => {
    product_typesRef.current.blur();
    setselectedProductType(value ? value : null);
  };

  const totalSales = useMemo(() => {
    return sales
      ?.filter(
        (sale) =>
          !selectedPaymentId ||
          sale.payments.some(
            (payment) => payment.payment_method_id === selectedPaymentId
          )
      )
      .reduce((acc, sale) => acc + parseFloat(sale.total), 0);
  }, [sales, selectedPaymentId]);

  const totalCreditNotes = useMemo(() => {
    return (
      credit_notes?.reduce((acc, sale) => acc + parseFloat(sale.amount), 0) || 0
    );
  }, [credit_notes]);

  const salesFiltered = () => {
    
    return sales?.filter((sale) =>
      selectedPaymentId
        ? sale.payments.some(
            (payment) => payment.payment_method_id == selectedPaymentId
          )
        :
        selectedProductType
        ? sale.product.product_type_id == selectedProductType
        :
        true &&
          (customer_id
            ? true
            : dayjs(sale.created_at)
                .tz(user.country.time_zone)
                .format('YYYY-MM-DD') >=
                date_selected[0].format('YYYY-MM-DD') &&
              dayjs(sale.created_at)
                .tz(user.country.time_zone)
                .format('YYYY-MM-DD') <= date_selected[1].format('YYYY-MM-DD'))
    );
  };
  return (
    <>
      {customer_id && (
        <div className='flex flex-row items-center justify-between mb-5'>
          <h2 className='text-white font-bold text-2xl font-MessinaSansSemiBold'>
            {t('sales.title', 'Ventas')}
          </h2>
          {customer_id && canCreateSale() && (
            <AddButton
              title={t('sales.add_sale', 'Cargar Operación')}
              greenColor
              onClick={() => setmodalSale(true)}
            />
          )}
        </div>
      )}
      <CardRounded styles={`px-5 pt-6 relative max-h-[55rem] ${styles}`}>
        <div className='flex flex-row justify-between items-center'>
          {!customer_id && (
            <h2 className='text-white font-bold text-2xl font-MessinaSansSemiBold'>
              {t('sales.title', 'Ventas')}
            </h2>
          )}
          <div className='flex items-center gap-4'>
            {!customer_id && canCreateSale() && (
              <AddButton
                title={t('sales.add_sale', 'Cargar Operación')}
                greenColor
                onClick={() => setmodalSale(true)}
              />
            )}
            {showExtract && (
              <Tooltip title={t('sales.movement_box', 'Movimiento de caja')}>
                <button onClick={() => setmodalTransaction(true)}>
                  <MdOutlineExitToApp
                    color='#ED4C5C'
                    size={42}
                    className='ease-in-out hover:opacity-90 hover:scale-105'
                  />
                </button>
              </Tooltip>
            )}
          </div>
        </div>
        <div
          className={`flex flex-row items-center ${
            customer_id ? 'mb-5' : 'mt-4 mb-3'
          } justify-between `}
        >
          {!customer_id && (
            <ConfigProvider locale={es_ES}>
              <RangePicker
                presets={rangePresets}
                ref={datepickerRef}
                allowClear={false}
                showToday
                value={date_selected}
                onChange={(dates, dateString) =>
                  setDate_selected([dayjs(dateString[0]), dayjs(dateString[1])])
                }
                defaultPickerValue={date_selected}
                defaultValue={date_selected}
                placeholder={t('date.title', 'Fecha')}
                format='YYYY-MM-DD'
                className='bg-primaryGrey hover:bg-primaryGrey focus:bg-primaryGrey focus-within:bg-primaryGrey outline-none border-none py-1 w-full'
              />
            </ConfigProvider>
          )}
<div className='flex flex-row  items-center gap-5 w-full'>
          <Select
            ref={filterPaymentRef}
            loading={isLoadingPaymentTypes}
            style={{
              backgroundColor: '#444444',
              borderRadius: 6,
              color: '#fff',
              width: customer_id ? '20%' : '50%',
            }}
            allowClear
            suffixIcon={<BsChevronDown color='#ADFF19' />}
            value={selectedPaymentId}
            optionLabelProp='title'
            options={isLoadingPaymentTypes ? [] : categorizePayments}
            bordered={false}
            placeholder={
              <div className='flex flex-row items-center gap-2'>
                {t('sales.payment_method', 'Método de pago')}
              </div>
            }
            className={`self-end ${!customer_id && 'ml-auto'}`}
            onChange={handlePaymentChange}
            filterOption={(input, option) =>
              option?.label.toLowerCase().includes(input.toLowerCase())
            }
          />
         {customer_id && <Select
            ref={product_typesRef}
            loading={isLoadingProduct_types}
            style={{
              backgroundColor: '#444444',
              borderRadius: 6,
              color: '#fff',
              width: customer_id ? '20%' : '33%',
            }}
            allowClear
            suffixIcon={<BsChevronDown color='#ADFF19' />}
            value={selectedProductType}
            optionLabelProp='title'
            options={
              isLoadingProduct_types
                ? []
                : product_types.map((item) => ({
                    label: t(item.name.toLowerCase()),
                    value: item.id,
                    title: t(item.name.toLowerCase()),
                  }))
            }
            bordered={false}
            placeholder={
              <div className='flex flex-row items-center gap-2'>
                {t('sales.type_product', 'Tipo de producto')}
              </div>
            }
            onChange={handleProductTypeChange}
            filterOption={(input, option) =>
              option?.label.toLowerCase().includes(input.toLowerCase())
            }
          />}
          </div>
        </div>

        {isLoadingSales ? (
          <Skeleton active />
        ) : (
          <>
            <div className={`${!customer_id && 'min-h-[41rem]'}`}>
              {customer_id &&
                salesFiltered().length == 0 &&
               ( selectedPaymentId || selectedProductType) && (
                  <p className='mb-7'>
                    {t('this', 'El')}{' '}
                    {customer_type == 'Member'
                      ? t('member', 'Socio')
                      : t('prospect', 'Prospecto')}{' '}
                      { selectedPaymentId ? `${t('no_registered_sales', 'no cuenta con ventas registradas con el metodo de pago' )} ${categorizePayments.find( (payment) => payment.value == selectedPaymentId )?.label}` :
                     `${t(
                        'no_typeregistered_sales',
                        'no cuenta con ventas registradas con el tipo de producto'
                      )} ${product_types.find(
                        (product) => product.id == selectedProductType
                      )?.name}`
                      }
                  </p>
                )}
                 
              {salesFiltered().map((sale, index) => (
                <LineOperation
                  key={sale.id || index}
                  customer_id={customer_id}
                  customer_type={customer_type}
                  sale={sale}
                  openNotification={openNotification}
                  setModalMemberOpen={setModalMemberOpen}
                  handleOpenReturn={handleOpenReturn}
                  paymentPlatform={paymentPlatform}
                />
              ))}
              {sales?.length == 0 && customer_id && (
                <p className='mb-7'>
                  {t('this', 'El')}{' '}
                  {customer_type == 'Member'
                    ? t('member', 'Socio')
                    : t('prospect', 'Prospecto')}{' '}
                  {t('no_registered_sales', 'no cuenta con ventas registradas')}
                </p>
              )}
            </div>
            {!isError && !customer_id && (
              <div className='sticky bottom-0 bg-primaryDark right-0 h-24 w-full flex items-end justify-end flex-col p-5 border-t-[1px] border-t-primaryGrey'>
                <p className='text-3xl  font-MessinaSansSemiBold'>
                  {t('total_singular', 'Total')}: {formatPrice(totalSales)}
                </p>
                {credit_notes?.length > 0 && (
                  <Tooltip
                    title={t(
                      'nc_dash_description',
                      'Total de N/C realizadas entre el ',
                      {
                        start: date_selected[0].format('DD/MM/YYYY'),
                        end: date_selected[1].format('DD/MM/YYYY'),
                      }
                    )}
                  >
                    <p className='text-xl  text-primaryRed font-MessinaSansSemiBold'>
                      {t('nc.total_singular', 'N/C total')}:{' '}
                      {formatPrice(totalCreditNotes)}
                    </p>
                  </Tooltip>
                )}
              </div>
            )}
          </>
        )}
      </CardRounded>
    </>
  );
}
